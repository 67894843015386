import { AiOutlineHome, AiOutlineUser } from "react-icons/ai";
import { BsDatabaseFillAdd } from "react-icons/bs";
import { BiTask } from "react-icons/bi";
import { GiByzantinTemple /* GiOpenBook */ } from "react-icons/gi";
import { MdTempleHindu, MdEditSquare, MdPrivacyTip } from "react-icons/md";
import { LiaHandshake } from "react-icons/lia";
import { GiPagoda } from "react-icons/gi";

import { FaFlag } from "react-icons/fa";
import { IoMdNotificationsOutline } from "react-icons/io";
import { ImProfile } from "react-icons/im";
import { MdOutlineEmojiTransportation } from "react-icons/md";
import { FaLocationArrow } from "react-icons/fa";
import { MdManageAccounts } from "react-icons/md";

import { ConfigItem } from "./LayoutTypes";

export const Config: ConfigItem[] = [
    {
        Name: "Roles and Permission",
        Icon: MdManageAccounts,
        Link: "/auth/Roles-management",
        isMultiple: false,
    },
    {
        Name: "Dashboard",
        Icon: AiOutlineHome,
        Link: "/auth/Dashboard",
        isMultiple: false,
    },
    {
        Name: "Banners",
        Icon: FaFlag,
        Link: "/auth/banners",
        isMultiple: false,
    },
    {
        Name: "Profile Management",
        Icon: ImProfile,
        Link: "/auth/profile-management",
        isMultiple: true,
        subMenu: [
            {
                Name: "Pandit",
                Link: "/auth/profile-management/pandit",
                Icon: AiOutlineUser,
            },
        ],
    },
    {
        Name: "Master Data",
        Icon: BsDatabaseFillAdd,
        Link: "/master-data",
        isMultiple: true,
        subMenu: [
            {
                Name: "Devalaya",
                Link: "/auth/master-data/devalaya",
                Icon: GiByzantinTemple,
            },
            {
                Name: "Deities",
                Link: "/auth/master-data/deities",
                Icon: GiPagoda,
            },
            {
                Name: "Facilities",
                Link: "/auth/master-data/facilities",
                Icon: LiaHandshake,
            },
            {
                Name: "Utsav",
                Link: "/auth/master-data/utsav",
                Icon: MdTempleHindu,
            },
            {
                Name: "Local Transport",
                Link: "/auth/master-data/local-transport",
                Icon: MdOutlineEmojiTransportation,
            },
            {
                Name: "Location",
                Link: "/auth/master-data/location",
                Icon: FaLocationArrow,
            },
        ],
    },
    {
        Name: "Devalaya",
        Link: "/auth/devalaya",
        Icon: GiByzantinTemple,
        isMultiple: false,
    },
    {
        Name: "Static Content",
        Icon: MdEditSquare,
        Link: "/auth/static-content",
        isMultiple: true,
        subMenu: [
            {
                Name: "Terms and Conditions",
                Link: "/auth/static-content/terms-conditions",
                Icon: BiTask,
            },
            {
                Name: "Privacy-policy",
                Link: "/auth/static-content/privacy-policy",
                Icon: MdPrivacyTip,
            },
        ],
    },
    {
        Name: "Notifications",
        Icon: IoMdNotificationsOutline,
        Link: "/auth/Notifications",
        isMultiple: false,
    },
];
/* {
        Name: "KYC Management",
        Icon: FaIdCard,
        Link: "/auth/kyc-management",
        isMultiple: true,
        subMenu: [
            {
                Name: "Pandit",
                Link: "/auth/kyc-management/pandit",
                Icon: AiOutlineUser,
            },
            {
                Name: "Users",
                Icon: FaPersonPraying,
                Link: "/auth/kyc-management/user-management",
            },
            {
                Name: "Child KYC",
                Icon: FaChildren,
                Link: "/auth/kyc-management/children-management",
            },
        ],
    }, */
