import axios, { AxiosInstance, AxiosResponse } from "axios";
import Swal from "sweetalert2";

const ApiService: AxiosInstance = axios.create({
    baseURL: process.env.REACT_APP_BASE_URL,
    // timeout: 0000,
});

// Set token if it exists in local storage
const token: string | null = localStorage.getItem("Token");
if (token) {
    ApiService.defaults.headers.common["Authorization"] = `Bearer ${token}`;
}

// Add response interceptor
// Handling Token Expiry and Navigate back to Login
export const addResponseInterceptor = (
    navigate: (path: string) => void
): void => {
    ApiService.interceptors.response.use(
        (response: AxiosResponse) => {
            // Do something with response data
            return response;
        },
        (error: any) => {
            if (
                (error.response &&
                    error.response.status === 401 &&
                    (error.response.data as any)?.message ===
                        "Unauthorized User") ||
                (error.response.data as any)?.error?.name === "Invalid Token" ||
                (error.response.data as any)?.error?.name === "Token Expired"
            ) {
                // Show a popup or notification indicating token expiration
                let timerInterval: NodeJS.Timeout;
                let remainingTime: number = 5;
                Swal.fire({
                    title: "Unauthorized",
                    icon: "error",
                    html: "Session Expired, will Navigate to Login Page in <b></b> sec.",
                    timer: remainingTime * 1000,
                    timerProgressBar: true,
                    allowEscapeKey: false,
                    allowOutsideClick: false,
                    didOpen: () => {
                        Swal.showLoading();
                        const timer = Swal.getPopup()?.querySelector("b");
                        timerInterval = setInterval(() => {
                            if (timer) {
                                timer.textContent = remainingTime.toString();
                                remainingTime--;
                            }
                        }, 1000);
                    },
                    willClose: () => {
                        clearInterval(timerInterval);
                    },
                }).then((result) => {
                    navigate("/");
                    localStorage.clear();
                    if (result.dismiss === Swal.DismissReason.timer) {
                    }
                });
                return Promise.reject(error);
            }

            // Do something with response error
            return Promise.reject(error);
        }
    );
};

export default ApiService;
