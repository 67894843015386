import { useState } from "react";
import Styles from "Styles/Styles.module.css";
import { FiPlus } from "react-icons/fi";
import { IoPencil } from "react-icons/io5";
import { IoIosEye, IoMdSearch } from "react-icons/io";
import { MdDelete } from "react-icons/md";
import { useNavigate } from "react-router";
import Img from "Assets/dummy.png";
import ManagementClasses from "./UserManagement.module.css";
import { MdOutlineFileDownload } from "react-icons/md";
import { useForm, SubmitHandler } from "react-hook-form";
import { HiMiniAdjustmentsHorizontal } from "react-icons/hi2";

type Inputs = {
    product: string;
    date: string;
};

function Banners() {
    const Navigate = useNavigate();
    const [Active, setActive] = useState("All Products");
    const {
        register,
        handleSubmit,
        formState: { errors },
    } = useForm<Inputs>();

    const Category = ["All Products", "Published", "Low stock", "Draft"];
    const onSubmit: SubmitHandler<Inputs> = (data) => {};

    const StatusCode = (code: number) => {
        switch (code) {
            case 1:
                return {
                    backgroundColor: "#FFF0EA",
                    color: "#F86624",
                    Name: "Low Stock",
                };
            case 2:
                return {
                    backgroundColor: "var(--secondaryColor",
                    color: "#1A9882",
                    Name: "Published",
                };
            case 3:
                return {
                    backgroundColor: "#F0F1F3",
                    color: "#667085",
                    Name: "Draft",
                };
            case 4:
                return {
                    backgroundColor: "#FEECEE",
                    color: "#EB3D4D",
                    Name: "Out of Stock",
                };
            default:
                return {
                    backgroundColor: "#F0F1F3",
                    color: "#667085",
                    Name: "Draft",
                };
        }
    };
    return (
        <div className={Styles["Container"]}>
            <header>
                <h3>Products</h3>
                <div className={ManagementClasses["header_action"]}>
                    <button>
                        <MdOutlineFileDownload size={22} />
                        Export
                    </button>
                    <button
                        className={Styles["Add_cat_btn"]}
                        onClick={() => Navigate("/auth/products/add")}>
                        <FiPlus />
                        Add Product
                    </button>
                </div>
            </header>
            <div className={ManagementClasses["product_filters"]}>
                <div className={ManagementClasses["All_Categories_wrapper"]}>
                    <div className={ManagementClasses["All_Categories"]}>
                        {Category.map((item, index) => (
                            <button
                                key={index}
                                className={
                                    item === Active
                                        ? ManagementClasses["active"]
                                        : ""
                                }
                                onClick={() => setActive(item)}>
                                {item}
                            </button>
                        ))}
                    </div>
                </div>
                <form
                    className={ManagementClasses["filter_right"]}
                    onSubmit={handleSubmit(onSubmit)}>
                    <div className={ManagementClasses["form_control"]}>
                        <input
                            className={
                                errors?.product &&
                                ManagementClasses["form_control_error"]
                            }
                            type="text"
                            placeholder="Search Products"
                            {...register("product", {
                                required: false,
                            })}
                        />
                        <IoMdSearch className={ManagementClasses["search"]} />
                    </div>

                    <button type="button">
                        <HiMiniAdjustmentsHorizontal />
                        Filter
                    </button>
                </form>
            </div>
            <div className={Styles["tableWrapper"]}>
                <table>
                    <thead>
                        <th>
                            <label className={Styles["category_head"]}>
                                <input type="checkbox" />
                                Product
                            </label>
                        </th>
                        <th>SKU</th>
                        <th>Category</th>
                        <th>Stock</th>
                        <th>Price</th>
                        <th>Status</th>
                        <th>Added</th>
                        <th>Actions</th>
                    </thead>
                    <tbody>
                        <tr>
                            <td>
                                <label className={Styles["category_content"]}>
                                    <input type="checkbox" />
                                    <img src={Img} alt="banner" />
                                    <div>
                                        <h3>Bag & Pouch</h3>
                                        <p>
                                            Great fashion, great selections,
                                            great prices.
                                        </p>
                                    </div>{" "}
                                </label>
                            </td>
                            <td>302100</td>
                            <td>Bags & Pouch</td>
                            <td>10</td>
                            <td>$ 120.00</td>
                            <td>
                                {" "}
                                <span
                                    style={{
                                        padding: "0.5rem",
                                        borderRadius: "10px",
                                        color: StatusCode(2).color,
                                        backgroundColor:
                                            StatusCode(2).backgroundColor,
                                    }}>
                                    {StatusCode(2).Name}
                                </span>
                            </td>
                            <td>29 Dec 2022</td>
                            <td>
                                <div className={Styles["Action_Tabs"]}>
                                    <button>
                                        <IoPencil size={22} />
                                    </button>
                                    <button>
                                        <IoIosEye size={20} />
                                    </button>
                                    <button>
                                        <MdDelete size={20} />
                                    </button>
                                </div>
                            </td>
                        </tr>
                        <tr>
                            <td>
                                <label className={Styles["category_content"]}>
                                    <input type="checkbox" />
                                    <img src={Img} alt="banner" />
                                    <div>
                                        <h3>Bag & Pouch</h3>
                                        <p>
                                            Great fashion, great selections,
                                            great prices.
                                        </p>
                                    </div>{" "}
                                </label>
                            </td>
                            <td>302100</td>
                            <td>Bags & Pouch</td>
                            <td>10</td>
                            <td>$ 120.00</td>
                            <td>
                                {" "}
                                <span
                                    style={{
                                        padding: "0.5rem",
                                        borderRadius: "10px",
                                        color: StatusCode(1).color,
                                        backgroundColor:
                                            StatusCode(1).backgroundColor,
                                    }}>
                                    {StatusCode(1).Name}
                                </span>
                            </td>
                            <td>29 Dec 2022</td>
                            <td>
                                <div className={Styles["Action_Tabs"]}>
                                    <button>
                                        <IoPencil size={22} />
                                    </button>
                                    <button>
                                        <IoIosEye size={20} />
                                    </button>
                                    <button>
                                        <MdDelete size={20} />
                                    </button>
                                </div>
                            </td>
                        </tr>
                        <tr>
                            <td>
                                <label className={Styles["category_content"]}>
                                    <input type="checkbox" />
                                    <img src={Img} alt="banner" />
                                    <div>
                                        <h3>Bag & Pouch</h3>
                                        <p>
                                            Great fashion, great selections,
                                            great prices.
                                        </p>
                                    </div>{" "}
                                </label>
                            </td>
                            <td>302100</td>
                            <td>Bags & Pouch</td>
                            <td>10</td>
                            <td>$ 120.00</td>
                            <td>
                                {" "}
                                <span
                                    style={{
                                        padding: "0.5rem",
                                        borderRadius: "10px",
                                        color: StatusCode(1).color,
                                        backgroundColor:
                                            StatusCode(1).backgroundColor,
                                    }}>
                                    {StatusCode(1).Name}
                                </span>
                            </td>
                            <td>29 Dec 2022</td>
                            <td>
                                <div className={Styles["Action_Tabs"]}>
                                    <button>
                                        <IoPencil size={22} />
                                    </button>
                                    <button>
                                        <IoIosEye size={20} />
                                    </button>
                                    <button>
                                        <MdDelete size={20} />
                                    </button>
                                </div>
                            </td>
                        </tr>
                    </tbody>
                </table>
            </div>
        </div>
    );
}

export default Banners;
