import React, { ReactNode, useEffect } from "react";
import ModalClasses from "./Modal.module.css";

interface ModalProps {
    children: ReactNode; // Accepts React children elements
    isModalOpen: boolean; // Controls whether the modal is open
    setisModalOpen: React.Dispatch<React.SetStateAction<boolean>>; // Setter function from useState
    width?: number;
    onClose?: () => void; // Optional  funtion Excuted on closing the modal
}

const Modal: React.FC<ModalProps> = ({
    children,
    isModalOpen,
    setisModalOpen,
    width = 400,
    onClose,
}) => {
    useEffect(() => {
        const handleKeyDown = (e: KeyboardEvent) => {
            if (e.key === "Escape") {
                setisModalOpen(false);
            }
        };

        if (isModalOpen) {
            window.addEventListener("keydown", handleKeyDown);
        }

        return () => {
            window.removeEventListener("keydown", handleKeyDown);
        };
        // eslint-disable-next-line
    }, [isModalOpen]);
    const cancelHandler = (e: React.MouseEvent<HTMLDivElement>) => {
        e.stopPropagation();
        setisModalOpen(false); // Close the modal
        if (onClose) {
            onClose(); // This will call the onClose function if it exists
        }
    };

    const openHandler = (e: React.MouseEvent<HTMLDivElement>) => {
        e.stopPropagation();
    };

    return (
        <div className={ModalClasses.modal} onClick={cancelHandler}>
            <div
                className={ModalClasses["modal-content"]}
                onClick={openHandler}
                style={{ width: `${width}px` }}>
                {children}
            </div>
        </div>
    );
};

export default Modal;
