import { useEffect, useState } from "react";
import Styles from "Styles/Styles.module.css";
import { useForm, SubmitHandler } from "react-hook-form";
import { FileUploader } from "react-drag-drop-files";
import { config } from "../../../s3config";
import ReactS3Client from "react-aws-s3-typescript";
import { MdDelete } from "react-icons/md";
import { add_Banners, update_Banners } from "Services/Requests";
import { Breadcrumb } from "antd";
import { NavLink } from "react-router-dom";
import Upload from "Components/UploadDesign/upload";
import { IoIosWarning } from "react-icons/io";
import { showErrorAlert, showWarningAlert, Toast } from "Util/Alerts";
import { Spin } from "antd";
import { useParams, useLocation, useNavigate } from "react-router-dom";
import moment from "moment";
import { BannerInputs, FormField } from "./BannerTypes";
import FormControl from "Components/FormControl";
import Imagepreview from "Components/ImagePreview";
import { validatePastDate } from "Validator/Validator";
window.Buffer = window.Buffer || require("buffer").Buffer;

function AddBanner() {
    const navigate = useNavigate();
    const fileTypes = ["JPG", "PNG", "GIF"];
    const { type } = useParams();
    const location = useLocation();
    const [loading, setLoading] = useState(false);
    const [isModalVisible, setIsModalVisible] = useState<boolean>(false);

    const [BannerImg, setBannerImg] = useState("");
    const [ErrorMsg, setErrorMsg] = useState<null | string>(null);

    const {
        register,
        handleSubmit,
        formState: { errors },
        setValue,
        resetField,
        reset,
        watch,
        setError,
    } = useForm<BannerInputs>();

    const handleChange = (files: any) => {
        setErrorMsg("");

        if (
            files.type === "image/png" ||
            files.type === "image/jpeg" ||
            files.type === "image/jpg"
        ) {
            let maxSize = 2 * 1024 * 1024;
            if (files.size > maxSize) {
                alert("File size cannot be greater than 2 MB");
                return;
            }

            const timestamp = Date.now();
            const fileName = `${timestamp}`;
            const s3 = new ReactS3Client({
                ...config,
                dirName: "banners",
            });
            setLoading(true);
            s3.uploadFile(files, fileName)
                .then((res: any) => {
                    setValue(
                        "banner_image",
                        `${String(res.key).split("/")[1]}`
                    );
                    setBannerImg(res.location);
                    setLoading(false);
                })
                .catch((err: any) => {
                    alert(err);
                    setLoading(false);
                });
        } else {
            alert(`${files.name} is neither PNG nor JPEG`);
            return false;
        }
        return true;
    };

    const onSubmit: SubmitHandler<BannerInputs> = async (data) => {
        if (data?.start_date > data?.end_date) {
            setError("end_date", { message: "Invalid Date Range" });
            return;
        }
        if (!BannerImg) {
            setErrorMsg("Please Upload File");
            return;
        }
        try {
            setLoading(true);
            let response;
            if (type === "edit") {
                response = await update_Banners(
                    data,
                    location.state?.banner_id
                );
            } else {
                response = await add_Banners(data);
            }
            if (response?.data?.Status === 1) {
                Toast(response?.data?.Message, "success");
                navigate(-1);
            } else {
                showWarningAlert(response?.data?.Message);
            }
        } catch (err: any) {
            showErrorAlert(
                err?.response?.data?.Message ||
                    err?.response?.data?.message ||
                    "Something went wrong, try again later"
            );
        } finally {
            setLoading(false);
        }
    };

    const watchData = watch();

    useEffect(() => {
        if (type === "edit") {
            let formData = location.state;
            formData = {
                ...formData,
                end_date: moment(formData?.end_date).format("YYYY-MM-DD"),
                start_date: moment(formData?.start_date).format("YYYY-MM-DD"),
                banner_image: String(formData.banner_image).split("/")[
                    String(formData.banner_image).split("/").length - 1
                ],
            };
            reset({ ...formData });
            setBannerImg(location.state?.banner_image);
        }
    }, [type, location.state, reset]);

    // Array of field configurations
    const formFields: FormField[] = [
        {
            label: "Banner Name",
            name: "banner_name",
            type: "text",
            required: "Banner Name is required",
        },
        {
            label: "Content",
            name: "banner_content",
            type: "textarea",
            required: "Content is required",
        },
        { label: "Link URL", name: "link_url", type: "text", required: "" },
        {
            label: "Target Audience",
            name: "target_audience",
            type: "select",
            options: [
                { label: "New Customers", value: 1 },
                { label: "Existing Customers", value: 2 },
            ],
            required: "Target Audience is required",
        },
        {
            label: "Geographical Scope",
            name: "geographical_scope",
            type: "select",
            options: [
                { label: "Local", value: "Local" },
                { label: "Regional", value: "Regional" },
                { label: "National", value: "National" },
                { label: "International", value: "International" },
                { label: "Global", value: "Global" },
            ],
            required: "Geographical Scope is required",
        },
        {
            label: "Language",
            name: "language",
            type: "select",
            options: [
                { label: "English", value: "English" },
                { label: "Hindi", value: "Hindi" },
                { label: "Sanskrit", value: "Sanskrit" },
            ],
            required: "Language is required",
        },
        {
            label: "Start Date",
            name: "start_date",
            type: "date",
            required: "Start Date is required",
            validate: validatePastDate,
        },
        {
            label: "End Date",
            name: "end_date",
            type: "date",
            required: "End Date is required",
            validate: validatePastDate,
        },
    ];

    return (
        <div className={Styles["Add_form_wrapper"]}>
            <Imagepreview
                handleCancel={() => setIsModalVisible(false)}
                selectedImage={BannerImg}
                isModalVisible={isModalVisible}
                title="banner"
            />
            <h3>
                <span style={{ textTransform: "capitalize" }}>{type}</span>{" "}
                Banner
            </h3>
            <Breadcrumb>
                <Breadcrumb.Item>
                    <NavLink to="/auth/banners">Pandit</NavLink>
                </Breadcrumb.Item>
                <Breadcrumb.Item>
                    <span style={{ textTransform: "capitalize" }}>
                        {type} Banner
                    </span>
                </Breadcrumb.Item>
            </Breadcrumb>
            <form onSubmit={handleSubmit(onSubmit)}>
                <div className={Styles["form"]}>
                    <h4>Banner</h4>

                    {/* Loop through the formFields array to render form controls */}
                    {formFields.map((field) => (
                        <FormControl
                            key={field.name}
                            label={field.label}
                            name={field.name}
                            register={register}
                            errors={errors[field.name]}
                            requiredMessage={field.required}
                            type={field.type}
                            options={field.options || []}
                            validate={field?.validate}
                        />
                    ))}

                    <div className={Styles["form_control"]}>
                        <label htmlFor="Photo">Photo</label>
                        <FileUploader
                            handleChange={handleChange}
                            name="file"
                            types={fileTypes}
                            children={Upload}
                        />
                        {BannerImg && (
                            <div className={Styles["remove_upload"]}>
                                <div onClick={() => setIsModalVisible(true)}>
                                    <img src={BannerImg} alt="banner" />
                                    <p>{watchData.banner_image}</p>
                                </div>
                                <button
                                    onClick={() => {
                                        resetField("banner_image");
                                        setBannerImg("");
                                    }}>
                                    <MdDelete />
                                </button>
                            </div>
                        )}
                    </div>
                    {ErrorMsg && (
                        <p className={Styles["Gen-erro_msg"]}>
                            <IoIosWarning /> {ErrorMsg}
                        </p>
                    )}
                </div>

                {loading ? (
                    <Spin size="default" />
                ) : (
                    <button>{type === "edit" ? "Update" : "Add"}</button>
                )}
            </form>
        </div>
    );
}

export default AddBanner;
