import React, { useState, useEffect } from "react";
import Modal from "Components/Modal/Modal";
import Styles from "Pages/Auth/MasterData/Devalaya/devalaya.module.css";
import { add_UtsavTypes, update_UtsavTypes } from "Services/Requests";
import { useForm, SubmitHandler } from "react-hook-form";
import { showErrorAlert, showWarningAlert, Toast } from "Util/Alerts";
import { Spin } from "antd";
import { utsavType, ModalProps } from "./Types";
import FormControl from "Components/FormControl";

window.Buffer = window.Buffer || require("buffer").Buffer;

const ADDModal: React.FC<ModalProps> = ({
    setisModalOpen,
    isModalOpen,
    setIsIedit,
    Edit,
    getUtsav,
}) => {
    const [loading, setLoading] = useState(false);

    const {
        register,
        handleSubmit,
        reset,
        formState: { errors },
    } = useForm<utsavType>();

    useEffect(() => {
        if (Edit) {
            reset({
                utsav_type_name: Edit?.utsav_type_name,
            });
        }
        // eslint-disable-next-line
    }, [Edit, isModalOpen]);
    const CancelHandler = () => {
        setisModalOpen(false);
        setIsIedit(null);
        reset();
    };

    const onSubmit: SubmitHandler<utsavType> = async (data) => {
        try {
            setLoading(true);
            let response;
            if (Edit) {
                response = await update_UtsavTypes(data, Edit.utsav_type_id);
            } else {
                response = await add_UtsavTypes(data);
            }
            if (response?.data?.Status === 1) {
                Toast(response?.data?.Message, "success");
                CancelHandler();
                getUtsav();
            } else {
                showWarningAlert(response?.data?.Message);
            }
        } catch (err: any) {
            showErrorAlert(
                err?.response?.data?.Message ||
                    err?.response?.data?.message ||
                    "Something went wrong try again later"
            );
        } finally {
            setLoading(false);
        }
    };

    return (
        <Modal
            isModalOpen
            setisModalOpen={setisModalOpen}
            onClose={CancelHandler}>
            <div className={Styles["Modal_Container"]}>
                <header>
                    <h3>{Edit ? "Update" : "Add"} Utsav Type</h3>
                </header>
                <form onSubmit={handleSubmit(onSubmit)}>
                    <FormControl
                        label={"Utasv Type"}
                        name={"utsav_type_name"}
                        register={register}
                        errors={errors["utsav_type_name"]}
                        requiredMessage={"Committe Type is required"}
                        type={"text"}
                    />

                    <div className={Styles["Action_btns"]}>
                        <button type="submit" disabled={loading}>
                            {loading ? <Spin /> : "Save"}
                        </button>
                        <button
                            type="button"
                            onClick={CancelHandler}
                            disabled={loading}>
                            Cancel
                        </button>
                    </div>
                </form>
            </div>
        </Modal>
    );
};

export default ADDModal;
