import { Routes, Route, useLocation, useNavigate } from "react-router-dom";
import { useLayoutEffect } from "react";

import { ToastContainer } from "react-toastify";
import { useStore } from "Store/Main";
import { addResponseInterceptor } from "Services/config";

/* -----------React Toastify--------------- */
import "react-toastify/dist/ReactToastify.css";

//--------------React Date Picker--------------------
import "react-date-range/dist/styles.css"; // main style file
import "react-date-range/dist/theme/default.css"; // theme css file

// -----------------React Quill------------------------
import "react-quill/dist/quill.snow.css";

// -----------Routes------------------
import CommonRoutes from "Routes/Routes";

//-----------Styles---------------
import "Styles/Common.css";

export default function App() {
    const location = useLocation();
    const { Theme } = useStore((item: any) => item);
    const navigate = useNavigate();
    //Setup response interceptor with navigation function
    addResponseInterceptor(navigate);

    useLayoutEffect(() => {
        window.scrollTo(0, 0);
    }, [location.pathname]);
    return (
        <div className={Theme !== "default" ? Theme : ""}>
            <ToastContainer />
            <Routes>
                <Route path="/*" element={<CommonRoutes />} />
            </Routes>
        </div>
    );
}
