import ApiService from "./config";

export const Login_service = (body: any) => {
    return ApiService.post("admin/adminLogin", body);
};

// -------------------------Banners------------------------

export const Get_Banners = () => {
    return ApiService.get("admin/getBanners");
};
export const add_Banners = (body: any) => {
    return ApiService.post("admin/createBanner", body);
};
export const update_Banners = (body: any, id: number) => {
    return ApiService.put(`admin/updateBanner/${id}`, body);
};

// -------------------Devalaya----------------

export const Get_Devalaya = (Status: number) => {
    return ApiService.get(`admin/getDevalayas?status=${Status}`);
};
export const add_Devalaya = (data: any) => {
    return ApiService.post("admin/createDevalaya", data);
};
export const update_Devalaya = (data: any, id: number) => {
    return ApiService.put(`admin/updateDevalaya/${id}`, data);
};
// --------------------Devalaya Type --------------
export const get_Devalaya_type = () => {
    return ApiService.get("admin/getDevalaya_types");
};
export const add_Devalaya_type = (data: any) => {
    return ApiService.post("admin/createDevalaya_types", data);
};
export const update_Devalaya_type = (data: any, id: number) => {
    return ApiService.put(`admin/updateDevalaya_type/${id}`, data);
};
export const get_DosDont = () => {
    return ApiService.get("admin/getDosDonts");
};
export const addDosDont = (data: any) => {
    return ApiService.post("admin/addDosDont", data);
};
export const updateDosDont = (data: any, id: number) => {
    return ApiService.put(`admin/updateDosDont/${id}`, data);
};

// ---------------------Devalaya Rules-------------
export const get_rules = () => {
    return ApiService.get("admin/getMasterRules");
};
export const add_rules = (data: any) => {
    return ApiService.post("admin/addMasterRule", data);
};
export const updateRules = (data: any, id: number) => {
    return ApiService.put(`admin/updateMasterRule/${id}`, data);
};
// ---------------------architecture Rules-------------

export const get_architectureTypes = () => {
    return ApiService.get("admin/getArchitectures");
};
export const add_architectureTypes = (data: any) => {
    return ApiService.post("admin/addArchitecture", data);
};
export const update_architectureTypes = (data: any, id: number) => {
    return ApiService.put(`admin/updateArchitecture/${id}`, data);
};

// ----------------------------Pandit-----------------

export const Add_Pandit = (body: any) => {
    return ApiService.post("admin/createPandit", body);
};
export const get_Pandit = () => {
    return ApiService.get("admin/getPandits");
};
export const update_Pandit = (body: any, panditId: number) => {
    return ApiService.put(`admin/updatePandit/${panditId}`, body);
};

// -----------------------------Facilities----------------

export const get_Facilities = () => {
    return ApiService.get("admin/getFacilities");
};
export const create_Facilities = (body: any) => {
    return ApiService.post("admin/createFacility", body);
};
export const update_Facilities = (body: any, id: number) => {
    return ApiService.put(`admin/updateFacility/${id}`, body);
};

// -------------------------------Get Committee-----------

export const get_Committee = () => {
    return ApiService.get("admin/getmaster_committeetypes");
};
export const create_Committee = (data: any) => {
    return ApiService.post("admin/createCommitteeType", data);
};
export const update_Committee = (data: any, id: number) => {
    return ApiService.put(`admin/updateCommitteeType/${id}`, data);
};

// ---------------------------Utsav-Karyakramanam--------------------

export const get_KaryakramamTypes = () => {
    return ApiService.get("admin/getKaryakramamTypes");
};
export const add_KaryakramamTypes = (data: any) => {
    return ApiService.post("admin/addKaryakramamType", data);
};
export const update_KaryakramamTypes = (data: any, id: any) => {
    return ApiService.put(`admin/updateKaryakramamType/${id}`, data);
};
// ---------------------------Utsav-Types--------------------

export const get_UtsavTypes = () => {
    return ApiService.get("admin/getUtsavTypes");
};
export const add_UtsavTypes = (data: any) => {
    return ApiService.post("admin/addUtsavType", data);
};
export const update_UtsavTypes = (data: any, id: any) => {
    return ApiService.put(`admin/updateUtsavType/${id}`, data);
};

// --------------------------------Terms and Conditions-----------

export const Terms_Condition_get = () => {
    return ApiService.get("admin/getTermsConditions");
};
export const Add_Terms_Condition = (data: any) => {
    return ApiService.post("admin/addTermsCondition", data);
};
export const update_Terms_condition = (data: any, id: any) => {
    return ApiService.put(`admin/updateTermsCondition/${id}`, data);
};

// --------------------Privacy Policy------------------
export const Privacy_policy_get = () => {
    return ApiService.get("admin/getPrivacyPolicy");
};
export const Add_Privacy_policy = (data: any) => {
    return ApiService.post("admin/addPrivacyPolicy", data);
};
export const update_Privacy_policy = (data: any, id: any) => {
    return ApiService.put(`admin/updatePrivacyPolicy/${id}`, data);
};

// ---------------------Notifications ---------------------

export const Notifications_get = () => {
    return ApiService.get("admin/getNotifications");
};
export const Notifications_Add = (data: any) => {
    return ApiService.post("admin/addNotification", data);
};

// ------------How to reach out -------------------
export const reachout_get = () => {
    return ApiService.get("admin/getLocalTransports");
};
export const reachout_Add = (data: any) => {
    return ApiService.post("admin/createLocalTransport", data);
};
export const reachout_update = (data: any, transport_id: string | number) => {
    return ApiService.put(`admin/updateLocalTransport/${transport_id}`, data);
};
// ------------kainkaryam -------------------
export const kainkaryam_get = () => {
    return ApiService.get("admin/getKainkaryams");
};
export const kainkaryam_Add = (data: any) => {
    return ApiService.post("admin/createKainkaryam", data);
};
export const kainkaryam_update = (
    data: any,
    kainkaryam_id: string | number
) => {
    return ApiService.put(`admin/updateKainkaryam/${kainkaryam_id}`, data);
};

// --------------------Deities------------

export const Deities_get = () => {
    return ApiService.get("admin/getdeities");
};
export const deities_Add = (data: any) => {
    return ApiService.post("admin/addDeity", data);
};
export const deities_update = (data: any, deity_id: string | number) => {
    return ApiService.put(`admin/updateDeity/${deity_id}`, data);
};

// --------------------------------Location-countries --------------------------

export const get_Coutries = () => {
    return ApiService.get("admin/getCountries");
};
export const Countries_Add = (data: any) => {
    return ApiService.post("admin/addCountry", data);
};
export const countries_update = (data: any, country_id: string | number) => {
    return ApiService.put(`admin/updateCountry/${country_id}`, data);
};

// --------------------------------Location-State --------------------------

export const get_State = () => {
    return ApiService.get("admin/getStates");
};
export const State_Add = (data: any) => {
    return ApiService.post("admin/addState", data);
};
export const State_update = (data: any, state_id: string | number) => {
    return ApiService.put(`admin/updateState/${state_id}`, data);
};
// --------------------------------Location-City --------------------------

export const get_city = () => {
    return ApiService.get("admin/getCities");
};
export const city_Add = (data: any) => {
    return ApiService.post("admin/addCity", data);
};
export const city_update = (data: any, city_id: string | number) => {
    return ApiService.put(`admin/updateCity/${city_id}`, data);
};
// --------------------------------Location-Zipcode --------------------------

export const get_zipcode = () => {
    return ApiService.get("admin/getZipCodes");
};
export const zipcode_Add = (data: any) => {
    return ApiService.post("admin/addZipCode", data);
};
export const zipcode_update = (data: any, city_id: string | number) => {
    return ApiService.put(`admin/updateZipCode/${city_id}`, data);
};

// --------------------------------Roles ---------------------------

export const get_Roles = () => {
    return ApiService.get("admin/getAdmins");
};
export const add_Roles = (body: any) => {
    return ApiService.post("admin/createAdmin", body);
};
export const update_Roles = (admin_id: number, body: any) => {
    return ApiService.put(`admin/updateAdmin/${admin_id}`, body);
};
