import Swal from "sweetalert2";
import { toast } from "react-toastify";
import Layoutclasses from "Components/Layout/Layout.module.css";
import swalAlert from "sweetalert";

export const showErrorAlert = (message: string) => {
    Swal.fire({
        icon: "error",
        title: "Error",
        text: message,
    });
};

export const showSuccessAlert = (message: string) => {
    Swal.fire({
        icon: "success",
        title: "Success",
        text: message,
    });
};

export const showWarningAlert = (message: string) => {
    Swal.fire({
        icon: "warning",
        title: "Warning",
        text: message,
    });
};

export const Toast = (
    message: string,
    Type: "success" | "warning" | "info" | "error"
) => {
    toast[Type](message, {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "colored",
    });
};

export const confirmAction = (
    title: string,
    message: string,
    onConfirm: () => void,
    buttonTxt: string
) => {
    swalAlert({
        title: title,
        text: message,
        icon: "warning",
        className: Layoutclasses["logout-popup"],
        dangerMode: true,
        buttons: {
            cancel: {
                text: "Cancel",
                value: false,
            },
            catch: {
                text: buttonTxt,
                value: buttonTxt,
                className: Layoutclasses["cancel"],
            },
        },
    }).then((value: any) => {
        if (value === buttonTxt) {
            onConfirm();
        }
    });
};
