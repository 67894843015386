import { useState, useEffect } from "react";
import { useNavigate } from "react-router";
import TableClasses from "Components/TableLoad";

import { FiPlus } from "react-icons/fi";
import { IoPencil } from "react-icons/io5";
import { MdDelete } from "react-icons/md";

import ImagePreview from "Components/ImagePreview";
import Moment from "react-moment";
import {
    showErrorAlert,
    showWarningAlert,
    confirmAction,
    Toast,
} from "Util/Alerts";

import { BannersType } from "./BannerTypes";

import { Get_Banners, update_Banners } from "Services/Requests";
// import {  } from "Util/Alerts";
import Styles from "Styles/Styles.module.css";

function BannersComp() {
    const Navigate = useNavigate();
    let TableHead = [
        "Banner",
        "Target Audience",
        "Language",
        "Link",
        "Start Date",
        "End Date",
        "Actions",
    ];
    const [Banners, setBanners] = useState<BannersType[]>([]);
    const [loading, setLoading] = useState<boolean>(true);
    // State to handle modal visibility and the selected image
    const [isModalVisible, setIsModalVisible] = useState<boolean>(false);
    const [selectedImage, setSelectedImage] = useState<string | null>(null);

    const getBanners = async () => {
        try {
            const response = await Get_Banners();
            if (response?.data?.Status === 1) {
                setBanners(response?.data?.banners);
            } else {
                showWarningAlert(response?.data?.Message);
            }
        } catch (err: any) {
            showErrorAlert(
                err?.response?.data?.Message ||
                    err?.response?.data?.message ||
                    "Something went wrong try again later"
            );
        } finally {
            setLoading(false);
        }
    };

    const DeleteBanner = async (id: any) => {
        setLoading(true);
        try {
            const response = await update_Banners({ status: 0 }, id);
            if (response?.data?.Status === 1) {
                Toast("Banner Deleted Successfully", "success");
                getBanners();
            } else {
                setLoading(false);
                showWarningAlert(response?.data?.Message);
            }
        } catch (err: any) {
            setLoading(false);
            showErrorAlert(
                err?.response?.data?.Message ||
                    err?.response?.data?.message ||
                    "Something went wrong try again later"
            );
        }
    };

    useEffect(() => {
        getBanners();
    }, []);

    // Function to handle image click and show the modal
    const handleImageClick = (imageUrl: string) => {
        setSelectedImage(imageUrl);
        setIsModalVisible(true);
    };

    // Function to close the modal
    const handleCancel = () => {
        setIsModalVisible(false);
        setSelectedImage(null);
    };

    const deleteHandler = (item: BannersType) => {
        confirmAction(
            "Warning",
            "Are you sure you want to Delete Banner?",
            () => DeleteBanner(item.banner_id),
            "Delete"
        );
    };
    return (
        <div className={Styles["Container"]}>
            <ImagePreview
                handleCancel={handleCancel}
                isModalVisible={isModalVisible}
                selectedImage={selectedImage}
                title="Banner"
            />
            <header>
                <h3>Banners</h3>
                <button
                    className={Styles["Add_cat_btn"]}
                    onClick={() => Navigate("/auth/banners/add")}>
                    <FiPlus />
                    Add Banner
                </button>
            </header>
            {loading ? (
                <TableClasses TableHead={TableHead} />
            ) : (
                <div className={Styles["tableWrapper"]}>
                    <table>
                        <thead>
                            {TableHead.map((item) => (
                                <th>{item}</th>
                            ))}
                        </thead>
                        <tbody>
                            {Banners.length > 0 ? (
                                Banners?.map((item) => (
                                    <tr key={item?.banner_id}>
                                        <td
                                            onClick={() =>
                                                handleImageClick(
                                                    item.banner_image
                                                )
                                            }>
                                            <div className={Styles["banner"]}>
                                                <img
                                                    src={item.banner_image}
                                                    alt="banner"
                                                />
                                                <div>
                                                    <p>{item.banner_name}</p>
                                                </div>
                                            </div>
                                        </td>
                                        <td>
                                            {item?.target_audience === 1
                                                ? "New Customers"
                                                : "Existing Customer"}
                                        </td>
                                        <td>{item?.language}</td>
                                        <td>
                                            <a
                                                href={item?.link_url}
                                                target="_blank"
                                                rel="noreferrer">
                                                Link
                                            </a>
                                        </td>
                                        {/*  <td>
                                        <span className={Styles["Active"]}>
                                            Active
                                        </span>
                                    </td> */}
                                        <td>
                                            <Moment format="DD-MM-YYYY" utc>
                                                {item.start_date}
                                            </Moment>
                                        </td>
                                        <td>
                                            <Moment format="DD-MM-YYYY" utc>
                                                {item.end_date}
                                            </Moment>
                                        </td>
                                        <td>
                                            <div
                                                className={
                                                    Styles["Action_Tabs"]
                                                }>
                                                <button
                                                    onClick={() =>
                                                        Navigate(
                                                            "/auth/banners/edit",
                                                            { state: item }
                                                        )
                                                    }>
                                                    <IoPencil size={24} />
                                                </button>

                                                <button
                                                    onClick={() =>
                                                        deleteHandler(item)
                                                    }>
                                                    <MdDelete size={22} />
                                                </button>
                                            </div>
                                        </td>
                                    </tr>
                                ))
                            ) : (
                                <tr>
                                    <td colSpan={TableHead.length}>
                                        No Banner Found
                                    </td>
                                </tr>
                            )}
                        </tbody>
                    </table>
                </div>
            )}
        </div>
    );
}

export default BannersComp;
