import { useState, useEffect } from "react";
import Styles from "Styles/Styles.module.css";
import DevalayaStyles from "Pages/Auth/MasterData/Devalaya/devalaya.module.css";

import { IoPencil } from "react-icons/io5";
import { IoMdSearch } from "react-icons/io";
import { MdDelete } from "react-icons/md";
import { MdOutlineFileDownload } from "react-icons/md";
import { reachout_get, reachout_update } from "Services/Requests";
import {
    showWarningAlert,
    showErrorAlert,
    confirmAction,
    Toast,
} from "Util/Alerts";
import Tableload from "Components/TableLoad";
import { FacilityTypes } from "./Types";
import AddType from "./Add";

function Facilities() {
    let tableHeaders = [
        "SNO",
        "Icon",
        "Transport Name",
        "Description",
        "Actions",
    ];
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [Edit, setIsIedit] = useState<null | FacilityTypes>(null);
    const [localTransports, setlocalTransports] = useState([]);
    const [searchlocalTransports, setserchlocalTransports] = useState([]);
    const [loading, setLoading] = useState(true);

    const getTransports = async () => {
        try {
            const response = await reachout_get();
            if (response?.data?.Status === 1) {
                setlocalTransports(response?.data?.localTransports);
                setserchlocalTransports(response?.data?.localTransports);
            } else {
                showWarningAlert(response?.data?.Message);
            }
        } catch (err: any) {
            showErrorAlert(
                err?.response?.data?.Message ||
                    err?.response?.data?.message ||
                    "Something went wrong try again later"
            );
        } finally {
            setLoading(false);
        }
    };
    const Delete = async (id: any) => {
        setLoading(true);
        try {
            const response = await reachout_update({ status: 0 }, id);
            if (response?.data?.Status === 1) {
                Toast("Transport Deleted Successfully", "success");
                getTransports();
            } else {
                setLoading(false);
                showWarningAlert(response?.data?.Message);
            }
        } catch (err: any) {
            setLoading(false);
            showErrorAlert(
                err?.response?.data?.Message ||
                    err?.response?.data?.message ||
                    "Something went wrong try again later"
            );
        }
    };
    useEffect(() => {
        getTransports();
    }, []);
    const deleteHandler = (item: FacilityTypes) => {
        confirmAction(
            "Warning",
            "Are you sure you want to Delete?",
            () => Delete(item.transport_id),
            "Delete"
        );
    };
    const searchHandler = (e: React.ChangeEvent<HTMLInputElement>) => {
        setlocalTransports(() =>
            searchlocalTransports.filter((item: any) =>
                String(item.transport_name)
                    .toLowerCase()
                    .includes(String(e.target.value).toLowerCase())
            )
        );
    };

    return (
        <div style={{ overflow: "auto" }}>
            <div className={Styles["Container"]}>
                <header>
                    <h3>How to Reach</h3>
                </header>
                {isModalOpen && (
                    <AddType
                        isModalOpen
                        setisModalOpen={setIsModalOpen}
                        setIsIedit={setIsIedit}
                        Edit={Edit}
                        getTransports={getTransports}
                    />
                )}
                <div className={DevalayaStyles["Master_data_filter"]}>
                    <div className={DevalayaStyles["filter_right"]}>
                        <div className={DevalayaStyles["form_control"]}>
                            <input
                                type="text"
                                onChange={searchHandler}
                                placeholder="Search Transport"
                            />
                            <IoMdSearch className={DevalayaStyles["search"]} />
                        </div>
                    </div>
                    <div className={DevalayaStyles["filter_btns"]}>
                        <button>
                            <MdOutlineFileDownload size={15} /> Export
                        </button>
                        <button onClick={() => setIsModalOpen(true)}>
                            + Add Transport
                        </button>
                    </div>
                </div>
                {loading ? (
                    <Tableload TableHead={tableHeaders} />
                ) : (
                    <div className={Styles["tableWrapper"]}>
                        <table>
                            <thead>
                                {tableHeaders.map((item) => (
                                    <th>{item}</th>
                                ))}
                            </thead>
                            <tbody>
                                {localTransports.length > 0 ? (
                                    localTransports.map(
                                        (item: FacilityTypes, index) => (
                                            <tr>
                                                <td>{index + 1}</td>
                                                <td>
                                                    <div
                                                        className={
                                                            Styles["banner"]
                                                        }>
                                                        <img
                                                            src={
                                                                item?.transport_icon
                                                            }
                                                            alt="Facilities"
                                                        />
                                                    </div>
                                                </td>
                                                <td>{item?.transport_name}</td>
                                                <td>{item?.sub_text}</td>

                                                <td>
                                                    <div
                                                        className={
                                                            Styles[
                                                                "Action_Tabs"
                                                            ]
                                                        }>
                                                        <button
                                                            onClick={() => {
                                                                setIsIedit(
                                                                    item
                                                                );
                                                                setIsModalOpen(
                                                                    true
                                                                );
                                                            }}>
                                                            <IoPencil
                                                                size={22}
                                                            />
                                                        </button>

                                                        <button
                                                            onClick={() =>
                                                                deleteHandler(
                                                                    item
                                                                )
                                                            }>
                                                            <MdDelete
                                                                size={20}
                                                            />
                                                        </button>
                                                    </div>
                                                </td>
                                            </tr>
                                        )
                                    )
                                ) : (
                                    <tr>
                                        <td colSpan={tableHeaders.length}>
                                            {" "}
                                            No Data Found
                                        </td>
                                    </tr>
                                )}
                            </tbody>
                        </table>
                    </div>
                )}
            </div>
        </div>
    );
}

export default Facilities;
