import { useState, useEffect } from "react";
import Styles from "Styles/Styles.module.css";
import DevalayaStyles from "../devalaya.module.css";
import { IoPencil } from "react-icons/io5";
import { IoMdSearch } from "react-icons/io";
import { MdDelete } from "react-icons/md";
import { MdOutlineFileDownload } from "react-icons/md";
import { get_DosDont, updateDosDont } from "Services/Requests";
import {
    showWarningAlert,
    showErrorAlert,
    Toast,
    confirmAction,
} from "Util/Alerts";
import Tableload from "Components/TableLoad";
import { DoesType } from "./Types";
import AddType from "./Add";

function CommitteeComp() {
    let tableHeaders = ["SNO", "Icon", "Name", "Description", "Actions"];
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [Edit, setIsIedit] = useState<null | DoesType>(null);
    const [Dos, setDos] = useState([]);
    const [searchCommittee, setSearchCommittee] = useState([]);
    const [loading, setLoading] = useState(true);

    const getCommittee = async () => {
        try {
            const response = await get_DosDont();
            if (response?.data?.Status === 1) {
                setDos(response?.data?.DosDonts);
                setSearchCommittee(response?.data?.DosDonts);
            } else {
                showWarningAlert(response?.data?.Message);
            }
        } catch (err: any) {
            showErrorAlert(
                err?.response?.data?.Message ||
                    err?.response?.data?.message ||
                    "Something went wrong try again later"
            );
        } finally {
            setLoading(false);
        }
    };
    const Delete = async (id: any) => {
        setLoading(true);
        try {
            const response = await updateDosDont({ status: 0 }, id);
            if (response?.data?.Status === 1) {
                Toast(" Deleted Successfully", "success");
                getCommittee();
            } else {
                setLoading(false);
                showWarningAlert(response?.data?.Message);
            }
        } catch (err: any) {
            setLoading(false);
            showErrorAlert(
                err?.response?.data?.Message ||
                    err?.response?.data?.message ||
                    "Something went wrong try again later"
            );
        }
    };
    useEffect(() => {
        getCommittee();
    }, []);

    const deleteHandler = (item: DoesType) => {
        confirmAction(
            "Warning",
            `Are you sure you want to Delete Committe (${item?.name}) ?`,
            () => Delete(item.dos_donts_id),
            "Delete"
        );
    };
    const searchHandler = (e: React.ChangeEvent<HTMLInputElement>) => {
        setDos(() =>
            searchCommittee.filter((item: any) =>
                String(item.name)
                    .toLowerCase()
                    .includes(String(e.target.value).toLowerCase())
            )
        );
    };

    return (
        <div style={{ overflow: "auto" }}>
            {isModalOpen && (
                <AddType
                    isModalOpen
                    setisModalOpen={setIsModalOpen}
                    setIsIedit={setIsIedit}
                    Edit={Edit}
                    DoesApi={getCommittee}
                />
            )}
            <div className={DevalayaStyles["Master_data_filter"]}>
                <div className={DevalayaStyles["filter_right"]}>
                    <div className={DevalayaStyles["form_control"]}>
                        <input
                            type="text"
                            onChange={searchHandler}
                            placeholder="Search Committee"
                        />
                        <IoMdSearch className={DevalayaStyles["search"]} />
                    </div>
                </div>
                <div className={DevalayaStyles["filter_btns"]}>
                    <button>
                        <MdOutlineFileDownload size={15} /> Export
                    </button>
                    <button onClick={() => setIsModalOpen(true)}>+ Add</button>
                </div>
            </div>
            {loading ? (
                <Tableload TableHead={tableHeaders} />
            ) : (
                <div className={Styles["tableWrapper"]}>
                    <table>
                        <thead>
                            {tableHeaders.map((item) => (
                                <th>{item}</th>
                            ))}
                        </thead>
                        <tbody>
                            {Dos.length > 0 ? (
                                Dos.map((item: DoesType, index) => (
                                    <tr>
                                        <td>{index + 1}</td>
                                        <td>
                                            <div className={Styles["banner"]}>
                                                <img
                                                    src={item?.icon}
                                                    alt="Icon"
                                                />
                                            </div>
                                        </td>
                                        <td>{item?.name}</td>
                                        <td>{item?.sub_text}</td>

                                        <td>
                                            <div
                                                className={
                                                    Styles["Action_Tabs"]
                                                }>
                                                <button
                                                    onClick={() => {
                                                        setIsIedit(item);
                                                        setIsModalOpen(true);
                                                    }}>
                                                    <IoPencil size={22} />
                                                </button>

                                                <button
                                                    onClick={() =>
                                                        deleteHandler(item)
                                                    }>
                                                    <MdDelete size={20} />
                                                </button>
                                            </div>
                                        </td>
                                    </tr>
                                ))
                            ) : (
                                <tr>
                                    <td colSpan={tableHeaders.length}>
                                        {" "}
                                        No Data Found
                                    </td>
                                </tr>
                            )}
                        </tbody>
                    </table>
                </div>
            )}
        </div>
    );
}

export default CommitteeComp;
