import { create } from "zustand";
import { PreviewStoreState } from "./Types";
import {getRolesFromLocalStorage} from 'Util/Common'
import {Role} from 'Util/Types'
export const useStore = create((set) => ({
    Collapsed: window.innerWidth >= 1000 ? false : true,
    setCollapsed: (collapsed: boolean) =>
        set((state: any) => ({ ...state, Collapsed: collapsed })),
    Theme: localStorage.getItem("theme")
        ? localStorage.getItem("theme")
        : "default",
    setTheme: (theme: string) =>
        set((state: any) => ({ ...state, Theme: theme })),
    userName: localStorage.getItem("userName") || "Admin",
    setUserName: (Admin: string) =>
        set((state: any) => ({ ...state, userName: Admin })),
    Permissions:getRolesFromLocalStorage(),
    setPermissions: (Admin: Role) =>
        set((state: any) => ({ ...state, Permissions: Admin })),
}));

/// Store to Handle Image Preview
export const usePreviewStore = create<PreviewStoreState>((set) => ({
    PreviewImage: "",
    setPreviewImage: (img: string) =>
        set((state) => ({ ...state, PreviewImage: img })),
    showPreview: false,
    setShowPreview: (preview: boolean) =>
        set((state) => ({ ...state, showPreview: preview })),
}));
