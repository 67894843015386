import { useState, useEffect } from "react";
import { useNavigate } from "react-router";
import TableClasses from "Components/TableLoad";

import { FiPlus } from "react-icons/fi";
import { IoPencil } from "react-icons/io5";
import { MdDelete } from "react-icons/md";

import ImagePreview from "Components/ImagePreview";
import Moment from "react-moment";
import {
    showErrorAlert,
    showWarningAlert,
    confirmAction,
    Toast,
} from "Util/Alerts";

import { RolesTypes } from "./RolesType";

import { get_Roles, update_Roles } from "Services/Requests";
// import {  } from "Util/Alerts";
import Styles from "Styles/Styles.module.css";

function UsersComp() {
    const Navigate = useNavigate();
    let TableHead = [
        "SNO",
        "User Name",
        "User Type",
        "Email",
        "Designation",
        "Actions",
    ];
    const [Users, setUsers] = useState<RolesTypes[]>([]);
    const [loading, setLoading] = useState<boolean>(false);

    const getUsers = async () => {
        try {
            const response = await get_Roles();
            if (response?.data?.Status === 1) {
                setUsers(response?.data?.admins);
            } else {
                showWarningAlert(response?.data?.Message);
            }
        } catch (err: any) {
            showErrorAlert(
                err?.response?.data?.Message ||
                    err?.response?.data?.message ||
                    "Something went wrong try again later"
            );
        } finally {
            setLoading(false);
        }
    };

    const DeleteUser = async (id: any) => {
        setLoading(true);
        try {
            const response = await update_Roles(id, { status: 0 });
            if (response?.data?.Status === 1) {
                Toast("User Deleted Successfully", "success");
                getUsers();
            } else {
                setLoading(false);
                showWarningAlert(response?.data?.Message);
            }
        } catch (err: any) {
            setLoading(false);
            showErrorAlert(
                err?.response?.data?.Message ||
                    err?.response?.data?.message ||
                    "Something went wrong try again later"
            );
        }
    };

    useEffect(() => {
        getUsers();
    }, []);

    const deleteHandler = (item: RolesTypes) => {
        confirmAction(
            "Warning",
            "Are you sure you want to Delete User?",
            () => DeleteUser(item.admin_id),
            "Delete"
        );
    };
    return (
        <div className={Styles["Container"]}>
            <header>
                <h3>Roles & Permissions</h3>
                <button
                    className={Styles["Add_cat_btn"]}
                    onClick={() => Navigate("/auth/Roles-management/add")}>
                    <FiPlus />
                    Add User
                </button>
            </header>
            {loading ? (
                <TableClasses TableHead={TableHead} />
            ) : (
                <div className={Styles["tableWrapper"]}>
                    <table>
                        <thead>
                            {TableHead.map((item) => (
                                <th>{item}</th>
                            ))}
                        </thead>
                        <tbody>
                            {Users.length > 0 ? (
                                Users?.map((item, index) => (
                                    <tr key={item?.role_id}>
                                        <td>{index + 1}</td>
                                        <td>{item?.name}</td>
                                        <td>
                                            {item?.role == 1
                                                ? "Admin"
                                                : "Sub-admin"}
                                        </td>
                                        <td>{item?.email}</td>
                                        <td>{item.designation}</td>
                                        {/*  <td>
                                        <span className={Styles["Active"]}>
                                            Active
                                        </span>
                                    </td> */}

                                        <td>
                                            <div
                                                className={
                                                    Styles["Action_Tabs"]
                                                }>
                                                <button
                                                    onClick={() =>
                                                        Navigate(
                                                            "/auth/Roles-management/edit",
                                                            { state: item }
                                                        )
                                                    }>
                                                    <IoPencil size={24} />
                                                </button>

                                                <button
                                                    onClick={() =>
                                                        deleteHandler(item)
                                                    }>
                                                    <MdDelete size={22} />
                                                </button>
                                            </div>
                                        </td>
                                    </tr>
                                ))
                            ) : (
                                <tr>
                                    <td colSpan={TableHead.length}>
                                        No User Found
                                    </td>
                                </tr>
                            )}
                        </tbody>
                    </table>
                </div>
            )}
        </div>
    );
}

export default UsersComp;
