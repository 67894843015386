import React, { useState } from "react";
import Styles from "../Devalaya/devalaya.module.css";
import TabsComponent from "Components/Tabs";
import Karyakramanam from "./Karyakramanam/Karyakramanam";
import UtsavType from "./UtsavTypes/Utsav";
function Index() {
    const [ActiveTab, setActiveTab] = useState("Utsav Type");
    const Tabs = [
        /* 
        { label: "Utsav Facilities", value: "Utsav Facilities" },
        { label: "Utsav Attractions", value: "Utsav Attractions" }, */
        { label: "Utsav Type", value: "Utsav Type" },
        { label: "Karyakramanam Type", value: "Karyakramanam Type" },
    ];

    return (
        <div className={Styles["Container"]}>
            <h3>Utsav</h3>
            <header>
                <TabsComponent
                    Tabs={Tabs}
                    active={ActiveTab}
                    setActive={setActiveTab}
                />
            </header>
            {ActiveTab === "Utsav Type" && <UtsavType />}
            {ActiveTab === "Karyakramanam Type" && <Karyakramanam />}
        </div>
    );
}

export default Index;
