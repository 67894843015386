import React, { useState, useEffect } from "react";
import Modal from "Components/Modal/Modal";
import Styles from "Pages/Auth/MasterData/Devalaya/devalaya.module.css";
import CommonStyles from "Styles/Styles.module.css";
import { reachout_Add, reachout_update } from "Services/Requests";
import { useForm, SubmitHandler } from "react-hook-form";
import { showErrorAlert, showWarningAlert, Toast } from "Util/Alerts";
import { Spin } from "antd";
import { FacilityTypes, ModalProps } from "./Types";
import FormControl from "Components/FormControl";
import { FileUploader } from "react-drag-drop-files";
import ReactS3Client from "react-aws-s3-typescript";
import ModalLoad from "Components/Modal_load/load";
import { config } from "../../../../s3config";
import ImagePreview from "Components/ImagePreview";
import Upload from "Components/UploadDesign/upload";
import { MdDelete } from "react-icons/md";
import { IoIosWarning } from "react-icons/io";

window.Buffer = window.Buffer || require("buffer").Buffer;

const ADDModal: React.FC<ModalProps> = ({
    setisModalOpen,
    isModalOpen,
    setIsIedit,
    Edit,
    getTransports,
}) => {
    const [loading, setLoading] = useState(false);
    const [Icon, setIcon] = useState("");
    const [ErrorMsg, setErrorMsg] = useState("");
    const [imageLoad, setImageLoad] = useState(false);
    const [isModalVisible, setIsModalVisible] = useState(false);
    const {
        register,
        handleSubmit,
        reset,
        setValue,
        watch,
        resetField,
        formState: { errors },
    } = useForm<FacilityTypes>();
    const watchData = watch();
    // const formData =watch()

    useEffect(() => {
        if (Edit) {
            reset({
                transport_name: Edit.transport_name,
                sub_text: Edit?.sub_text,
                transport_icon: String(Edit.transport_icon).split("/")[
                    String(Edit.transport_icon).split("/").length - 1
                ],
            });
            setIcon(Edit.transport_icon);
        }
        // eslint-disable-next-line
    }, [Edit, isModalOpen]);
    const CancelHandler = () => {
        setisModalOpen(false);
        setIsIedit(null);
        reset();
    };

    const handleChange = (files: any, type: string) => {
        if (
            files.type === "image/png" ||
            files.type === "image/jpeg" ||
            files.type === "image/jpg"
        ) {
            let maxSize = 2 * 1024 * 1024;
            if (files.size > maxSize) {
                alert("file size cannot be greater than 2 mb");
                return;
            }

            const timestamp = Date.now();
            let fileName = `${timestamp}`;

            setImageLoad(true);
            const s3 = new ReactS3Client({
                ...config,
                dirName: "icons",
            });
            // setLoading(true);
            s3.uploadFile(files, fileName)
                .then((res: any) => {
                    setImageLoad(false);
                    setValue(
                        "transport_icon",
                        `${String(res.key).split("/")[1]}`
                    );
                    setIcon(res.location);

                    setLoading(false);
                })
                .catch((err: any) => {
                    alert(err);
                    setLoading(false);
                    setImageLoad(false);
                });
        } else {
            alert(`${files.name} is neither PNG Nor JPEG`);
            return false;
        }
        return true;
    };
    const onSubmit: SubmitHandler<FacilityTypes> = async (data) => {
        if (!Icon) {
            setErrorMsg("Icon is required");
            return;
        }
        try {
            setLoading(true);
            let response;
            if (Edit) {
                response = await reachout_update(data, Edit.transport_id);
            } else {
                response = await reachout_Add(data);
            }
            if (response?.data?.Status === 1) {
                Toast(response?.data?.Message, "success");
                getTransports();
                CancelHandler();
            } else {
                showWarningAlert(response?.data?.Message);
            }
        } catch (err: any) {
            showErrorAlert(
                err?.response?.data?.Message ||
                    err?.response?.data?.message ||
                    "Something went wrong try again later"
            );
        } finally {
            setLoading(false);
        }
    };

    return (
        <Modal
            isModalOpen
            setisModalOpen={setisModalOpen}
            onClose={CancelHandler}>
            {imageLoad && <ModalLoad />}

            <ImagePreview
                handleCancel={() => setIsModalVisible(false)}
                selectedImage={Icon}
                isModalVisible={isModalVisible}
                title="Transport Icon"
            />
            <div className={Styles["Modal_Container"]}>
                <header>
                    <h3>{Edit ? "Update" : "Add"} Transport</h3>
                </header>
                <form onSubmit={handleSubmit(onSubmit)}>
                    <FormControl
                        label={"Transport Name"}
                        name={"transport_name"}
                        register={register}
                        errors={errors["transport_name"]}
                        requiredMessage={"Name is required"}
                        type={"text"}
                    />
                    <FormControl
                        label={"Description"}
                        name={"sub_text"}
                        register={register}
                        errors={errors["sub_text"]}
                        requiredMessage={"Description is required"}
                        type={"text"}
                    />
                    <div
                        className={`${CommonStyles["form_control"]} ${Styles["form_control_upload"]}`}>
                        <label htmlFor="Photo">Icon</label>
                        <FileUploader
                            handleChange={(file: any) =>
                                handleChange(file, "profile_photo")
                            }
                            name="file"
                            children={Upload}
                        />
                        {Icon && (
                            <div className={CommonStyles["remove_upload"]}>
                                <div
                                    onClick={() => {
                                        setIsModalVisible(true);
                                    }}>
                                    <img src={Icon} alt="profile" />
                                    <p>{watchData.transport_icon}</p>
                                </div>
                                <button
                                    onClick={() => {
                                        resetField("transport_icon");
                                        setIcon("");
                                    }}>
                                    <MdDelete />
                                </button>
                            </div>
                        )}
                    </div>
                    {ErrorMsg && (
                        <p className={CommonStyles["Gen-erro_msg"]}>
                            {" "}
                            <IoIosWarning /> {ErrorMsg}
                        </p>
                    )}
                    <div className={Styles["Action_btns"]}>
                        <button type="submit" disabled={loading}>
                            {loading ? <Spin /> : "Save"}
                        </button>
                        <button
                            type="button"
                            onClick={CancelHandler}
                            disabled={loading}>
                            Cancel
                        </button>
                    </div>
                </form>
            </div>
        </Modal>
    );
};

export default ADDModal;
