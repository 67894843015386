import { notification } from "antd";

// Utility function to show success notification
export const showSuccessNotification = (
    message: string,
    description?: string
): void => {
    notification.success({
        message: message || "Success!",
        description: description || "Operation completed successfully.",
        duration: 3, // Duration of notification in seconds
        placement: "topRight", // Placement of notification
    });
};

// Utility function to show error notification
export const showErrorNotification = (
    message: string,
    description?: string
): void => {
    notification.error({
        message: message || "Error!",
        description: description || "Something went wrong. Please try again.",
        duration: 3,
        placement: "topRight",
    });
};

// Utility function to show warning notification
export const showWarningNotification = (
    message: string,
    description?: string
): void => {
    notification.warning({
        message: message || "Warning!",
        description: description || "Be careful. Something might be wrong.",
        duration: 3,
        placement: "topRight",
    });
};

// Utility function to show info notification
export const showInfoNotification = (
    message: string,
    description?: string
): void => {
    notification.info({
        message: message || "Information",
        description: description || "Here is some important info.",
        duration: 3,
        placement: "topRight",
    });
};
