import Styles from "Styles/Styles.module.css";
type Category = {
    label: string;
    value: number | string;
    count?: number;
};

type TabsProps = {
    Tabs: Category[];
    active: number | string;
    setActive: React.Dispatch<React.SetStateAction<any>>;
};

const Tabs: React.FC<TabsProps> = ({ Tabs, active, setActive }) => {
    return (
        <div className={Styles["Tabs"]}>
            {Tabs.map((item) => (
                <button
                    key={item.value}
                    className={
                        item.value === active ? Styles["active_tab"] : ""
                    }
                    onClick={() => setActive(item.value)}>
                    {item.label} {item.count !== undefined && `(${item.count})`}
                </button>
            ))}
        </div>
    );
};

export default Tabs;
