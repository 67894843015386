import React, { useState, useEffect } from "react";
import Modal from "Components/Modal/Modal";
import Styles from "../../Devalaya/devalaya.module.css";
import CommonStyles from "Styles/Styles.module.css";
import {
    add_KaryakramamTypes,
    update_KaryakramamTypes,
} from "Services/Requests";
import { useForm, SubmitHandler } from "react-hook-form";
import { showErrorAlert, showWarningAlert, Toast } from "Util/Alerts";
import { Spin } from "antd";
import { karyakramam_type, ModalProps } from "./Types";
import FormControl from "Components/FormControl";
import { FileUploader } from "react-drag-drop-files";
import ReactS3Client from "react-aws-s3-typescript";
import ModalLoad from "Components/Modal_load/load";
import { config } from "../../../../../s3config";
import ImagePreview from "Components/ImagePreview";
import Upload from "Components/UploadDesign/upload";
import { MdDelete } from "react-icons/md";

window.Buffer = window.Buffer || require("buffer").Buffer;

const ADDModal: React.FC<ModalProps> = ({
    setisModalOpen,
    isModalOpen,
    setIsIedit,
    Edit,
    getKaryakramamTypes,
}) => {
    const [loading, setLoading] = useState(false);
    const [Icon, setIcon] = useState("");
    const [imageLoad, setImageLoad] = useState(false);
    const [isModalVisible, setIsModalVisible] = useState(false);
    const {
        register,
        handleSubmit,
        reset,
        setValue,
        watch,
        resetField,
        formState: { errors },
    } = useForm<karyakramam_type>();
    const watchData = watch();
    // const formData =watch()

    useEffect(() => {
        if (Edit) {
            reset({
                karyakramam_type: Edit.karyakramam_type,
                icon: String(Edit.icon).split("/")[
                    String(Edit.icon).split("/").length - 1
                ],
            });
            setIcon(Edit.icon);
        }
        // eslint-disable-next-line
    }, [Edit, isModalOpen]);
    const CancelHandler = () => {
        setisModalOpen(false);
        setIsIedit(null);
        reset();
    };

    const handleChange = (files: any, type: string) => {
        if (
            files.type === "image/png" ||
            files.type === "image/jpeg" ||
            files.type === "image/jpg"
        ) {
            let maxSize = 2 * 1024 * 1024;
            if (files.size > maxSize) {
                alert("file size cannot be greater than 2 mb");
                return;
            }

            const timestamp = Date.now();
            let fileName = `${timestamp}`;

            setImageLoad(true);
            const s3 = new ReactS3Client({
                ...config,
                dirName: "icons",
            });
            // setLoading(true);
            s3.uploadFile(files, fileName)
                .then((res: any) => {
                    setImageLoad(false);
                    setValue("icon", `${String(res.key).split("/")[1]}`);
                    setIcon(res.location);

                    setLoading(false);
                })
                .catch((err: any) => {
                    alert(err);
                    setLoading(false);
                    setImageLoad(false);
                });
        } else {
            alert(`${files.name} is neither PNG Nor JPEG`);
            return false;
        }
        return true;
    };
    const onSubmit: SubmitHandler<karyakramam_type> = async (data) => {
        if (!Icon) {
            showWarningAlert("Icon is required");
            return;
        }
        try {
            setLoading(true);
            let response;
            if (Edit) {
                response = await update_KaryakramamTypes(
                    data,
                    Edit.karyakramam_type_id
                );
            } else {
                response = await add_KaryakramamTypes(data);
            }
            if (response?.data?.Status === 1) {
                Toast(response?.data?.Message, "success");
                CancelHandler();
                getKaryakramamTypes();
            } else {
                showWarningAlert(response?.data?.Message);
            }
        } catch (err: any) {
            showErrorAlert(
                err?.response?.data?.Message ||
                    err?.response?.data?.message ||
                    "Something went wrong try again later"
            );
        } finally {
            setLoading(false);
        }
    };

    return (
        <Modal
            isModalOpen
            setisModalOpen={setisModalOpen}
            onClose={CancelHandler}>
            {imageLoad && <ModalLoad />}

            <ImagePreview
                handleCancel={() => setIsModalVisible(false)}
                selectedImage={Icon}
                isModalVisible={isModalVisible}
                title="Facility Icon"
            />
            <div className={Styles["Modal_Container"]}>
                <header>
                    <h3>{Edit ? "Update" : "Add"} karyakramam</h3>
                </header>
                <form onSubmit={handleSubmit(onSubmit)}>
                    <FormControl
                        label={"karyakramam Type"}
                        name={"karyakramam_type"}
                        register={register}
                        errors={errors["karyakramam_type"]}
                        requiredMessage={"Name is required"}
                        type={"text"}
                    />

                    <div
                        className={`${CommonStyles["form_control"]} ${Styles["form_control_upload"]}`}>
                        <label htmlFor="Photo">Icon</label>
                        <FileUploader
                            handleChange={(file: any) =>
                                handleChange(file, "profile_photo")
                            }
                            name="file"
                            children={Upload}
                        />
                        {Icon && (
                            <div className={CommonStyles["remove_upload"]}>
                                <div
                                    onClick={() => {
                                        setIsModalVisible(true);
                                    }}>
                                    <img src={Icon} alt="profile" />
                                    <p>{watchData.icon}</p>
                                </div>
                                <button
                                    onClick={() => {
                                        resetField("icon");
                                        setIcon("");
                                    }}>
                                    <MdDelete />
                                </button>
                            </div>
                        )}
                    </div>
                    <div className={Styles["Action_btns"]}>
                        <button type="submit" disabled={loading}>
                            {loading ? <Spin /> : "Save"}
                        </button>
                        <button
                            type="button"
                            onClick={CancelHandler}
                            disabled={loading}>
                            Cancel
                        </button>
                    </div>
                </form>
            </div>
        </Modal>
    );
};

export default ADDModal;
