import { useEffect, useState, useRef } from "react";
import Styles from "Styles/Styles.module.css";
import { useForm, SubmitHandler } from "react-hook-form";
import { FileUploader } from "react-drag-drop-files";
import Upload from "Components/UploadDesign/upload";
import { IoIosWarning } from "react-icons/io";
import DevalayaClasses from "Pages/Auth/MasterData/Devalaya/devalaya.module.css";
import { useNavigate } from "react-router";
import { Spin } from "antd";
import { showErrorAlert, showWarningAlert, Toast } from "Util/Alerts";
import {
    Input,
    DevalayaTypes,
    FormField,
    errorTypes,
    gopuramPhoto,
} from "./Devalaya";

import { useParams } from "react-router";
import { config } from "../../../s3config";
import ReactS3Client from "react-aws-s3-typescript";
import { useLocation } from "react-router-dom";
import { MdDelete } from "react-icons/md";
import ImagePreview from "Components/ImagePreview";
import ModalLoad from "Components/Modal_load/load";
import {
    add_Devalaya,
    get_Devalaya_type,
    update_Devalaya,
} from "Services/Requests";
import FormControl from "Components/FormControl";
import { v4 as uuidv4 } from "uuid";

window.Buffer = window.Buffer || require("buffer").Buffer;

function AddBanner() {
    const navigate = useNavigate();
    const location = useLocation();
    const { type } = useParams(); // Get the dynamic parameter "id"
    const [isModalVisible, setIsModalVisible] = useState(false);
    const fileTypes = ["JPG", "PNG", "GIF"];
    const [Devalayatypes, setDevalayaTypes] = useState([]);
    const [ErrorMsg, setErrorMsg] = useState<errorTypes>({
        profileError: "",
        gopuramError: "",
        common: "",
    });
    const [loading, setLoading] = useState(false);
    const [imageLoad, setImageLoad] = useState(false);
    const [profilePic, setProfilePic] = useState("");
    const [gopuramPic, setgopuramPic] = useState<gopuramPhoto[]>([]);
    const [selectedPic, setSelectedPic] = useState("");
    const {
        register,
        handleSubmit,
        setValue,
        reset,
        resetField,
        watch,
        formState: { errors },
    } = useForm<Input>();
    const watchData = watch();
    const targetDivRef = useRef<HTMLDivElement>(null);
    useEffect(() => {
        const getDevalaya = async () => {
            try {
                const response = await get_Devalaya_type();
                if (response?.data?.Status === 1) {
                    setDevalayaTypes(
                        response?.data?.devalaya_types?.map(
                            (item: DevalayaTypes) => ({
                                value: item.devalayatype_id,
                                label: item.devalaya_type,
                            })
                        )
                    );
                    if (type === "edit") {
                        let formData = location.state;
                        console.log(formData);
                        formData = {
                            ...formData,

                            profile_photo: String(formData.profile_photo).split(
                                "/"
                            )[
                                String(formData.profile_photo).split("/")
                                    .length - 1
                            ],
                            gopuram_photo: String(formData.gopuram_photo).split(
                                "/"
                            )[
                                String(formData.gopuram_photo).split("/")
                                    .length - 1
                            ],
                        };
                        reset({
                            ...formData,
                            state: formData.address?.state,
                            pincode: formData?.address?.pincode,
                            longitude: formData?.address?.longitude,
                            latitude: formData?.address?.latitude,
                            country: formData?.address?.country,
                            city: formData?.address?.city,
                            address_2: formData?.address?.address_2,
                            address_1: formData?.address?.address_1,
                        });
                        setProfilePic(location.state?.profile_photo);

                        setgopuramPic(
                            location?.state?.gopuram_photo?.map(
                                (item: any) => ({
                                    id: uuidv4(),
                                    location: item,
                                    gopuram_photo: `${
                                        String(item).split("/")[
                                            String(item).split("/").length - 1
                                        ]
                                    }`,
                                })
                            )
                        );
                    }
                } else {
                    showWarningAlert(response?.data?.Message);
                }
            } catch (err: any) {
                console.log(err);
                showErrorAlert(
                    err?.response?.data?.Message ||
                        err?.response?.data?.message ||
                        "Something went wrong try again later"
                );
            } finally {
                setLoading(false);
            }
        };
        getDevalaya();
        // eslint-disable-next-line
    }, [type, location.state]);
    console.log(gopuramPic);
    const handleChange = (files: any, type: string) => {
        setErrorMsg({ profileError: "", gopuramError: "", common: "" });
        if (
            files.type === "image/png" ||
            files.type === "image/jpeg" ||
            files.type === "image/jpg"
        ) {
            let maxSize = 2 * 1024 * 1024;
            if (files.size > maxSize) {
                alert("file size cannot be greater than 2 mb");
                return;
            }

            const timestamp = Date.now();
            let fileName = `${timestamp}`;

            setImageLoad(true);
            const s3 = new ReactS3Client({
                ...config,
                dirName: "devalayas",
            });
            // setLoading(true);
            s3.uploadFile(files, fileName)
                .then((res: any) => {
                    setImageLoad(false);
                    if (type === "profile_photo") {
                        setValue(
                            "profile_photo",
                            `${String(res.key).split("/")[1]}`
                        );
                        setProfilePic(res.location);
                    } else {
                        /* setValue(
                            "gopuram_photo",
                            
                        ); */
                        setgopuramPic((prev) => [
                            ...prev,
                            {
                                id: uuidv4(),
                                gopuram_photo: `${
                                    String(res.key).split("/")[1]
                                }`,
                                location: res.location,
                            },
                        ]);
                    }

                    setLoading(false);
                })
                .catch((err: any) => {
                    alert(err);
                    setLoading(false);
                    setImageLoad(false);
                });
        } else {
            alert(`${files.name} is neither PNG Nor JPEG`);
            return false;
        }
        return true;
    };
    const onSubmit: SubmitHandler<Input> = async (data) => {
        if (!profilePic) {
            setErrorMsg({
                profileError: "Please Upload Profile Photo",
                gopuramError: "",
                common: "",
            });
            handleScrollToDiv();
            return;
        }
        if (gopuramPic.length === 0) {
            setErrorMsg({
                profileError: "",
                gopuramError: "",
                common: "Please Upload Gopuram Photo",
            });
            return;
        }

        try {
            setLoading(true);
            let response;
            if (type === "edit") {
                response = await update_Devalaya(
                    {
                        devalaya_name: data.devalaya_name,
                        profile_photo: data?.profile_photo,
                        gopuram_photo: gopuramPic.map(
                            (item) => item.gopuram_photo
                        ),
                        devalaya_description: data?.devalaya_description,
                        devalaya_type: data?.devalaya_type,
                        address: {
                            state: data.state,
                            pincode: data.pincode,
                            longitude: Number(data.longitude),
                            latitude: Number(data.latitude),
                            country: data.country,
                            city: data.city,
                            address_2: data.address_2,
                            address_1: data.address_1,
                        },
                    },
                    location.state?.devalaya_id
                );
            } else {
                response = await add_Devalaya({
                    devalaya_name: data.devalaya_name,
                    profile_photo: data?.profile_photo,
                    gopuram_photo: gopuramPic.map((item) => item.gopuram_photo),
                    devalaya_description: data?.devalaya_description,
                    devalaya_type: data?.devalaya_type,
                    address: {
                        state: data.state,
                        pincode: data.pincode,
                        longitude: Number(data.longitude),
                        latitude: Number(data.latitude),
                        country: data.country,
                        city: data.city,
                        address_2: data.address_2,
                        address_1: data.address_1,
                    },
                });
            }
            if (response?.data?.Status === 1) {
                Toast(response?.data?.Message, "success");
                navigate(-1);
            } else {
                showWarningAlert(response?.data?.Message);
            }
        } catch (err: any) {
            showErrorAlert(
                err?.response?.data?.Message ||
                    err?.response?.data?.message ||
                    "Something went wrong try again later"
            );
        } finally {
            setLoading(false);
        }
    };
    const fields: FormField[] = [
        {
            label: "Devalaya Name",
            name: "devalaya_name",
            type: "text",
            required: "Devalaya Name is required",
        },
        {
            label: "Description",
            name: "devalaya_description",
            type: "text",
            required: "Description is required",
        },
        {
            label: "Devalaya Type",
            name: "devalaya_type",
            type: "select",
            options: Devalayatypes,
            required: "Devalaya Type is required",
        },
        {
            label: "Address Line 1",
            name: "address_1",
            type: "text",
            required: "Address Line 1 is required",
        },
        {
            label: "Address Line 2",
            name: "address_2",
            type: "text",
            required: "Address Line 2 is required",
        },
        {
            label: "City",
            name: "city",
            required: "City is required",
            type: "text",
        },
        {
            label: "State",
            name: "state",
            required: "State is required",
            type: "text",
        },
        {
            label: "Country",
            name: "country",
            type: "text",
            required: "Country is required",
        },
        {
            label: "Pincode",
            name: "pincode",
            type: "text",
            required: "Pincode is required",
        },
        {
            label: "Latitude",
            name: "latitude",
            type: "number",
            required: "Latitude is required",
        },
        {
            label: "Longitude",
            name: "longitude",
            type: "number",
            required: "Longitude is required",
        },
    ];
    const handleScrollToDiv = () => {
        // Step 2: Scroll to the div using scrollIntoView
        if (targetDivRef.current) {
            targetDivRef.current.scrollIntoView({
                behavior: "smooth", // Optional: smooth scroll animation
                block: "start", // Optional: aligns the div to the top of the viewport
            });
        }
    };
    const removeGopuramHaandler = (id: string) => {
        setgopuramPic((prev) => prev.filter((item) => item.id != id));
    };
    return (
        <div className={Styles["Add_form_wrapper"]}>
            {imageLoad && <ModalLoad />}
            <ImagePreview
                handleCancel={() => setIsModalVisible(false)}
                selectedImage={selectedPic}
                isModalVisible={isModalVisible}
                title="Devalaya"
            />
            <h3 ref={targetDivRef}>
                {type === "edit" ? "Update" : "Add"} Devalaya
            </h3>
            <form onSubmit={handleSubmit(onSubmit)}>
                <div
                    className={`${Styles["form"]} ${DevalayaClasses["form_add_devalaya"]}`}>
                    <h4 style={{ gridColumn: "1/-1" }}>Devalaya</h4>
                    <div
                        className={`${Styles["form_control"]} ${DevalayaClasses["form_control_upload"]}`}>
                        <label htmlFor="Photo">Profile Photo</label>
                        <FileUploader
                            handleChange={(file: any) =>
                                handleChange(file, "profile_photo")
                            }
                            name="file"
                            types={fileTypes}
                            children={Upload}
                        />{" "}
                        {ErrorMsg?.profileError && (
                            <p className={Styles["Gen-erro_msg"]}>
                                <IoIosWarning />
                                {ErrorMsg?.profileError}
                            </p>
                        )}
                        {profilePic && (
                            <div className={Styles["remove_upload"]}>
                                <div
                                    onClick={() => {
                                        setIsModalVisible(true);
                                        setSelectedPic(profilePic);
                                    }}>
                                    <img src={profilePic} alt="profile" />
                                    <p>{watchData.profile_photo}</p>
                                </div>
                                <button
                                    onClick={() => {
                                        resetField("profile_photo");
                                        setProfilePic("");
                                    }}>
                                    <MdDelete />
                                </button>
                            </div>
                        )}
                    </div>
                    <fieldset className={DevalayaClasses["fieldset"]}>
                        <legend> Basic Info</legend>
                        {fields.map((field) => (
                            <FormControl
                                key={field.name}
                                label={field.label}
                                name={field.name}
                                type={field.type}
                                register={register}
                                errors={errors?.[field.name]}
                                options={field?.options}
                                requiredMessage={field.required}
                            />
                        ))}
                    </fieldset>
                    <div
                        className={`${Styles["form_control"]} ${DevalayaClasses["form_control_upload"]}`}>
                        <label htmlFor="Photo">Gopuram Photo</label>
                        <FileUploader
                            handleChange={(file: any) =>
                                handleChange(file, "gopuram_photo")
                            }
                            name="file"
                            types={fileTypes}
                            children={Upload}
                        />
                        {gopuramPic.length > 0 && (
                            <div className={Styles["photo_wrapper"]}>
                                {gopuramPic.map((item) => (
                                    <div className={Styles["remove_upload"]}>
                                        <div
                                            onClick={() => {
                                                setIsModalVisible(true);
                                                setSelectedPic(item?.location);
                                            }}>
                                            <img
                                                src={item.location}
                                                alt="gopuram"
                                            />
                                            <p>{item.gopuram_photo}</p>
                                        </div>
                                        <button
                                            onClick={() => {
                                                removeGopuramHaandler(item.id);
                                            }}>
                                            <MdDelete />
                                        </button>
                                    </div>
                                ))}
                            </div>
                        )}
                    </div>
                    {ErrorMsg.common && (
                        <p className={Styles["Gen-erro_msg"]}>
                            {" "}
                            <IoIosWarning /> {ErrorMsg.common}
                        </p>
                    )}
                </div>

                {loading ? (
                    <Spin size="default" />
                ) : (
                    <button>{type === "edit" ? "Update" : "Add"}</button>
                )}
            </form>
        </div>
    );
}

export default AddBanner;
