import React, { useEffect, ReactNode, useState, useRef } from "react";
import { Sidebar, Menu, MenuItem, SubMenu } from "react-pro-sidebar";
import Logo from "Assets/logo.png";
import LayoutClasses from "./Layout.module.css";
import { FiBell } from "react-icons/fi";
import { useLocation, NavLink, useNavigate } from "react-router-dom";
import avatar from "Assets/male.jpg";
import { Config } from "./Config";
import { TbLogout2 } from "react-icons/tb";
import { HiOutlineDotsVertical } from "react-icons/hi";
import {checkPermission} from 'Util/Common'
import swal from "sweetalert";
import {ConfigItem as Type,SubMenuItem} from 'Pages/Auth/Roles/RolesType'
import { useStore } from "Store/Main";
interface LayoutProps {
    children: ReactNode;
}

const Layout: React.FC<LayoutProps> = ({ children }) => {
    const Navigate = useNavigate();
    const { Collapsed, setCollapsed, userName, setTheme ,Permissions} = useStore(
        (state: any) => state
    );

    
    const [showModal, setShowModal] = useState(false);
    const modalRef = useRef<HTMLDivElement | null>(null);

    const rightSideRef = useRef<HTMLDivElement>(null);
    const Colors = ["blue", "pink", "violet", "teal", "navy", "lavender"];
    const location = useLocation();
    const [isScrolled, setIsScrolled] = useState(false);
    useEffect(() => {
        const handleResize = () => {
            if (window.innerWidth >= 1000) {
                setCollapsed(false);
            } else {
                setCollapsed(true);
            }
        };

        // Set initial state
        handleResize();

        window.addEventListener("resize", handleResize);

        // Clean up event listener on component unmount
        return () => {
            window.removeEventListener("resize", handleResize);
        }; // eslint-disable-next-line
    }, []);
    useEffect(() => {
        const handleScroll = () => {
            if (window.scrollY > 80) {
                setIsScrolled(true);
            } else {
                setIsScrolled(false);
            }
        };

        window.addEventListener("scroll", handleScroll);

        return () => {
            window.removeEventListener("scroll", handleScroll);
        };
    }, []);
    const handleClickOutside = (event: MouseEvent) => {
        if (
            modalRef.current &&
            !modalRef.current.contains(event.target as Node)
        ) {
            setShowModal(false);
        }
    };

    useEffect(() => {
        document.addEventListener("mousedown", handleClickOutside);
        return () => {
            document.removeEventListener("mousedown", handleClickOutside);
        }; // eslint-disable-next-line
    }, []);
    useEffect(() => {
        if (rightSideRef.current) {
            rightSideRef.current.scrollTo(0, 0);
        } // eslint-disable-next-line
    }, [location]);

    function checkPathExists(subMenu: any, pathname: any) {
        for (let i = 0; i < subMenu.length; i++) {
            if (subMenu[i].Link === pathname) {
                return true;
            }
        }
        return false;
    }

    const commonStyles = {
        padding: "5px",
        height: "40px",
        borderRadius: "10px",
        fontSize: "14px",
        fontWeight: "450",
        [`&.active`]: {
            backgroundColor: "var(--secondaryColor)",
            color: "var(--PrimaryColor)",
        },
        [`&:hover`]: {
            // color: "#6b7280",

            backgroundColor: "var(--secondaryColor)",
            color: "var(--PrimaryColor)",
        },
    };
    const logoutHandler = () => {
        swal({
            title: "Are You sure you want to Sign out?",
            icon: "warning",
            className: LayoutClasses["logout-popup"],
            dangerMode: true,
            buttons: {
                cancel: {
                    text: "Cancel",
                    value: false,
                },
                catch: {
                    text: "Logout",
                    value: "Logout",
                    className: LayoutClasses["cancel"],
                },
            },
        }).then((value: any) => {
            switch (value) {
                case "Logout":
                    Navigate("/");
                    localStorage.clear();
                    break;
            }
        });
    };
   
    
    return (
        <div className={LayoutClasses["Container"]}>
            <div className={LayoutClasses["SideNavWrapper"]}>
                <img
                    src={Logo}
                    alt="Logo"
                    style={{ width: Collapsed ? "3rem" : "5rem" }}
                />

                <Sidebar
                    backgroundColor="white"
                    collapsed={Collapsed}
                    collapsedWidth={"73px"}
                    width={"250px"}>
                    <Menu
                        closeOnClick={true}
                        rootStyles={{
                            margin: "5px 10px",
                        }}
                        menuItemStyles={{
                            button: ({ level, open }) => {
                                if (level === 1) {
                                    return {
                                        ...commonStyles,
                                        // borderRadius: "0.5rem",
                                        margin: !Collapsed
                                            ? "0.2rem 0"
                                            : " 0.2rem",
                                    };
                                }

                                return commonStyles;
                            },
                            icon: {
                                minWidth: Collapsed ? "40px" : "35px",
                                marginRight: "5px",
                            },
                            subMenuContent: {
                                // padding: "10px 40px",
                                background: "white",
                                margin: "0 20px",
                            },
                        }}>
                        {Config.map((item: any, index: any) => {
                            if (item.isMultiple) {
                                const IsMultipleExist= item?.subMenu?.some((item:any)=>Permissions.some((element:any) =>element.Name === item.Name && element.permissions.read === 1))
                              if(IsMultipleExist){
                                return (
                                    <SubMenu
                                        key={item.Name}
                                        rootStyles={{
                                            margin: "5px 0",
                                            color: "#323D57",
                                            backgroundcolor: "#6b7280",
                                            [`&.active`]: {
                                                backgroundColor: "#4b49ac",
                                                color: "#6b7280",
                                            },
                                        }}
                                        label={item.Name}
                                        icon={
                                            <item.Icon
                                                size={20}
                                                className={
                                                    LayoutClasses["icon"]
                                                }
                                            />
                                        }
                                        active={checkPathExists(
                                            item.subMenu,
                                            location.pathname
                                        )}>
                                        {item.subMenu.filter((sub:any)=>checkPermission(sub.Name,'read')).map(
                                            (dropdown: any, index: any) => (
                                                <MenuItem
                                                    key={index}
                                                    rootStyles={{
                                                        marginTop: Collapsed
                                                            ? 0
                                                            : "0.2rem",
                                                        color: "#323D57",
                                                        padding: 0,
                                                        backgroundcolor:
                                                            "white",
                                                        height: "40px",

                                                        // padding: "5px",
                                                        [`&.active`]: {
                                                            color: "#6b7280",
                                                        },
                                                    }}
                                                    active={location.pathname.includes(
                                                        dropdown.Link
                                                    )}
                                                    icon={
                                                        <dropdown.Icon
                                                            color={
                                                                dropdown.color
                                                            }
                                                            size={18}
                                                            className={
                                                                LayoutClasses[
                                                                    "icon"
                                                                ]
                                                            }
                                                        />
                                                    }
                                                    component={
                                                        <NavLink
                                                            to={dropdown.Link}
                                                        />
                                                    }>
                                                    {dropdown.Name}
                                                </MenuItem>
                                            )
                                        )}
                                    </SubMenu>
                                );
                              }
                            } else  if (checkPermission(item.Name,'read')){
                                return (
                                    <MenuItem
                                        key={index}
                                        rootStyles={{
                                            color: "#323D57",
                                            marginBottom: "5px",
                                            backgroundcolor: "white",
                                            [`&.active`]: {
                                                backgroundColor: "white",
                                                color: "#6b7280",
                                            },
                                        }}
                                        active={location.pathname.includes(
                                            item.Link
                                        )}
                                        icon={
                                            <item.Icon
                                                size={20}
                                                // color={item.color}
                                                className={
                                                    LayoutClasses["icon"]
                                                }
                                            />
                                        }
                                        component={<NavLink to={item.Link} />}>
                                        {" "}
                                        {item.Name}
                                    </MenuItem>
                                );
                            }
                        })}

                        <MenuItem
                            rootStyles={{
                                color: "#323D57",
                                backgroundcolor: "#6b7280",
                                // minWidth: "40px",
                                [`&.active`]: {
                                    backgroundColor: "#4b49ac",
                                    color: "white",
                                },
                                [`&:hover`]: {
                                    backgroundColor: "white",
                                    // color: "#6b7280",
                                },
                            }}
                            onClick={logoutHandler}
                            icon={<TbLogout2 color="#616161" size={20} />}>
                            Logout
                        </MenuItem>
                    </Menu>
                </Sidebar>
            </div>
            <div
                ref={rightSideRef}
                className={LayoutClasses["RightSide"]}
                /* style={{
                    width: `calc(100vw - ${Collapsed ? "80px" : "270px"}`,
                }} */
            >
                <div
                    className={`${LayoutClasses["TopNav"]} ${
                        isScrolled ? LayoutClasses["TopNav_scrolled"] : ""
                    }`}>
                    {/*    <div className={`${LayoutClasses["Input-wrapper"]}`}>
                        <input type="text" placeholder="Search..." />
                        <IoSearchSharp className={LayoutClasses["icon"]} />
                    </div> */}
                    <div></div>
                    <div className={`${LayoutClasses["top-nav-right"]}`}>
                        <div className={`${LayoutClasses["bell-icon"]}`}>
                            <FiBell size={20} />
                        </div>
                        <div className={LayoutClasses["user-img"]}>
                            <img src={avatar} alt="avatar" />
                            <p>{userName}</p>
                        </div>
                        <div className={"colors_theme_wrapper"}>
                            <div
                                className="selec"
                                /* style={{
                                    backgroundColor:
                                        Theme != "default" ? Theme : "#12715b",
                                }} */
                                onClick={() => setShowModal(true)}>
                                <HiOutlineDotsVertical />
                            </div>
                            {showModal && (
                                <div className={"colors_theme"} ref={modalRef}>
                                    <div
                                        className="each_theme"
                                        onClick={() => {
                                            setTheme("default");
                                            localStorage.setItem(
                                                "theme",
                                                "default"
                                            );
                                            setShowModal(false);
                                        }}>
                                        <span
                                            style={{
                                                backgroundColor: "#12715b",
                                            }}></span>
                                        default
                                    </div>
                                    {Colors.map((item) => (
                                        <div
                                            className="each_theme"
                                            onClick={() => {
                                                setTheme(item);
                                                localStorage.setItem(
                                                    "theme",
                                                    item
                                                );
                                                setShowModal(false);
                                            }}>
                                            <span
                                                style={{
                                                    backgroundColor: item,
                                                }}></span>{" "}
                                            {item}
                                        </div>
                                    ))}
                                </div>
                            )}
                        </div>
                    </div>
                </div>
                {children}
            </div>
        </div>
    );
};

export default Layout;
