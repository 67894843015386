import {Permission,Role} from './Types'

// Generate a random color
export const getRandomBackgroundColor = (): string => {
    const r = Math.floor(Math.random() * 256);
    const g = Math.floor(Math.random() * 256);
    const b = Math.floor(Math.random() * 256);

    // Calculate luminance to ensure the color is not too bright or too dark
    const luminance = 0.2126 * r + 0.7152 * g + 0.0722 * b;

    // If luminance is too low (dark), regenerate the color
    if (luminance < 128) {
        return getRandomBackgroundColor(); // Recursively find a brighter color
    }

    return `rgb(${r}, ${g}, ${b})`;
};



// Function to get roles from localStorage
export const getRolesFromLocalStorage = (): Role[] => {
    const rolesData = localStorage.getItem('teamPermissions');
    return rolesData ? JSON.parse(rolesData) : [];
  };


// Check if User Has Permissio to requested Page
export const checkPermission = (name: string, permissionType: keyof Permission): boolean => {
    const rolesArray = getRolesFromLocalStorage();
    
    const role = rolesArray.find((role) => role.Name === name);
    
    if (role && role.permissions.hasOwnProperty(permissionType)) {
      return role.permissions[permissionType] === 1; // true if permission is 1
    }
  
    return false; // false if role or permission type is not found
  };

