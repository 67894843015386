import React, { useState, useEffect } from "react";
import Modal from "Components/Modal/Modal";
import Styles from "Pages/Auth/MasterData/Devalaya/devalaya.module.css";
import { city_update, city_Add, get_State } from "Services/Requests";
import { useForm, SubmitHandler } from "react-hook-form";
import { showErrorAlert, showWarningAlert, Toast } from "Util/Alerts";
import { Spin } from "antd";
import { cityType, ModalProps } from "./Types";
import FormControl from "Components/FormControl";
import { StateType } from "../State/Types";

window.Buffer = window.Buffer || require("buffer").Buffer;

const ADDModal: React.FC<ModalProps> = ({
    setisModalOpen,
    isModalOpen,
    setIsIedit,
    Edit,
    getCity,
}) => {
    const [loading, setLoading] = useState(false);
    const [State, setState] = useState([]);

    const {
        register,
        handleSubmit,
        reset,
        formState: { errors },
    } = useForm<cityType>();

    const getCountry = async () => {
        try {
            const response = await get_State();
            if (response?.data?.Status === 1) {
                setState(
                    response?.data?.states?.map((item: StateType) => ({
                        label: item.state_name,
                        value: item.state_id,
                    }))
                );
                if (Edit) {
                    reset({
                        latitude: Edit?.latitude,
                        longitude: Edit?.longitude,
                        state_id: Edit?.state_id,
                        city: Edit?.city,
                    });
                }
            } else {
                showWarningAlert(response?.data?.Message);
            }
        } catch (err: any) {
            showErrorAlert(
                err?.response?.data?.Message ||
                    err?.response?.data?.message ||
                    "Something went wrong try again later"
            );
        } finally {
            setLoading(false);
        }
    };

    useEffect(() => {
        getCountry();
        // eslint-disable-next-line
    }, [Edit, isModalOpen]);
    const CancelHandler = () => {
        setisModalOpen(false);
        setIsIedit(null);
        reset();
    };

    const onSubmit: SubmitHandler<cityType> = async (data) => {
        try {
            setLoading(true);
            let response;
            if (Edit) {
                response = await city_update(data, Edit.city_id);
            } else {
                response = await city_Add(data);
            }
            if (response?.data?.Status === 1) {
                Toast(response?.data?.Message, "success");
                CancelHandler();
                getCity();
            } else {
                showWarningAlert(response?.data?.Message);
            }
        } catch (err: any) {
            showErrorAlert(
                err?.response?.data?.Message ||
                    err?.response?.data?.message ||
                    "Something went wrong try again later"
            );
        } finally {
            setLoading(false);
        }
    };

    return (
        <Modal
            isModalOpen
            setisModalOpen={setisModalOpen}
            onClose={CancelHandler}>
            <div className={Styles["Modal_Container"]}>
                <header>
                    <h3>{Edit ? "Update" : "Add"} city</h3>
                </header>
                <form onSubmit={handleSubmit(onSubmit)}>
                    <FormControl
                        label={"city"}
                        name={"city"}
                        register={register}
                        errors={errors["city"]}
                        requiredMessage={"city  is required"}
                        type={"text"}
                    />
                    <FormControl
                        label={"Latitude"}
                        name={"latitude"}
                        register={register}
                        errors={errors["latitude"]}
                        requiredMessage={"Latitude is required"}
                        type={"number"}
                    />{" "}
                    <FormControl
                        label={"Longitude"}
                        name={"longitude"}
                        register={register}
                        errors={errors["longitude"]}
                        requiredMessage={"Longitude is required"}
                        type={"number"}
                    />
                    <FormControl
                        label={"State"}
                        name={"state_id"}
                        register={register}
                        options={State}
                        errors={errors["state_id"]}
                        requiredMessage={"State is required"}
                        type={"select"}
                    />
                    <div className={Styles["Action_btns"]}>
                        <button type="submit" disabled={loading}>
                            {loading ? <Spin /> : "Save"}
                        </button>
                        <button
                            type="button"
                            onClick={CancelHandler}
                            disabled={loading}>
                            Cancel
                        </button>
                    </div>
                </form>
            </div>
        </Modal>
    );
};

export default ADDModal;
