import React from "react";
import Skeleton from "react-loading-skeleton";
import loadClasses from "Styles/Styles.module.css";
import "react-loading-skeleton/dist/skeleton.css";

// Define the types for the component props
interface LoadProps {
    TableHead: string[]; // The TableHead is an array of strings
}

const Load: React.FC<LoadProps> = ({ TableHead }) => {
    return (
        <div className={loadClasses["tableWrapper"]}>
            <table>
                <thead>
                    <tr>
                        {TableHead.map((item, index) => (
                            <th key={index}>{item}</th> // Add key for each <th>
                        ))}
                    </tr>
                </thead>
                <tbody>
                    {Array(3)
                        .fill(null)
                        .map((_, rowIndex) => (
                            <tr key={rowIndex}>
                                {Array(TableHead.length)
                                    .fill(null)
                                    .map((_, colIndex) => (
                                        <td key={colIndex}>
                                            <Skeleton height={"1rem"} />
                                        </td>
                                    ))}
                            </tr>
                        ))}
                </tbody>
            </table>
        </div>
    );
};

export default Load;
