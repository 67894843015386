import React, { useState, useEffect } from "react";
import Modal from "Components/Modal/Modal";
import Styles from "../devalaya.module.css";
import {
    update_architectureTypes,
    add_architectureTypes,
} from "Services/Requests";
import { useForm, SubmitHandler } from "react-hook-form";
import { showErrorAlert, showWarningAlert, Toast } from "Util/Alerts";
import { Spin } from "antd";
import { ArchitectureType, ModalProps } from "./Types";
import FormControl from "Components/FormControl";

window.Buffer = window.Buffer || require("buffer").Buffer;

const ADDModal: React.FC<ModalProps> = ({
    setisModalOpen,
    isModalOpen,
    setIsIedit,
    Edit,
    getArchitecture,
}) => {
    const [loading, setLoading] = useState(false);

    const {
        register,
        handleSubmit,
        reset,
        formState: { errors },
    } = useForm<ArchitectureType>();

    useEffect(() => {
        if (Edit) {
            reset({
                architecture_type: Edit?.architecture_type,
            });
        }
        // eslint-disable-next-line
    }, [Edit, isModalOpen]);
    const CancelHandler = () => {
        setisModalOpen(false);
        setIsIedit(null);
        reset();
    };

    const onSubmit: SubmitHandler<ArchitectureType> = async (data) => {
        try {
            setLoading(true);
            let response;
            if (Edit) {
                response = await update_architectureTypes(
                    data,
                    Edit.architecture_id
                );
            } else {
                response = await add_architectureTypes(data);
            }
            if (response?.data?.Status === 1) {
                Toast(response?.data?.Message, "success");
                CancelHandler();
                getArchitecture();
            } else {
                showWarningAlert(response?.data?.Message);
            }
        } catch (err: any) {
            showErrorAlert(
                err?.response?.data?.Message ||
                    err?.response?.data?.message ||
                    "Something went wrong try again later"
            );
        } finally {
            setLoading(false);
        }
    };

    return (
        <Modal
            isModalOpen
            setisModalOpen={setisModalOpen}
            onClose={CancelHandler}>
            <div className={Styles["Modal_Container"]}>
                <header>
                    <h3>{Edit ? "Update" : "Add"} Architecture</h3>
                </header>
                <form onSubmit={handleSubmit(onSubmit)}>
                    <FormControl
                        label={"Architecture Type"}
                        name={"architecture_type"}
                        register={register}
                        errors={errors["architecture_type"]}
                        requiredMessage={"Architecutre Type is required"}
                        type={"text"}
                    />

                    <div className={Styles["Action_btns"]}>
                        <button type="submit" disabled={loading}>
                            {loading ? <Spin /> : "Save"}
                        </button>
                        <button
                            type="button"
                            onClick={CancelHandler}
                            disabled={loading}>
                            Cancel
                        </button>
                    </div>
                </form>
            </div>
        </Modal>
    );
};

export default ADDModal;
