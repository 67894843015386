import { useState, useEffect } from "react";
import Styles from "Styles/Styles.module.css";
import DevalayaStyles from "Pages/Auth/MasterData/Devalaya/devalaya.module.css";
import { IoPencil } from "react-icons/io5";
import { IoMdSearch } from "react-icons/io";
import { MdDelete } from "react-icons/md";
import { useNavigate } from "react-router";
import { MdOutlineFileDownload } from "react-icons/md";
import { Get_Devalaya, update_Devalaya } from "Services/Requests";
import { FaEye } from "react-icons/fa";

import {
    showWarningAlert,
    showErrorAlert,
    confirmAction,
    Toast,
} from "Util/Alerts";
import Tableload from "Components/TableLoad";
import ImagePreview from "Components/ImagePreview";
import { devalaya } from "./Devalaya";
import { CSVLink } from "react-csv";
import TabsComponent from "Components/Tabs";

function Banners() {
    const tableHeaders = [
        "S.NO",
        "Devalaya Name",
        "Type",
        "Address",
        "Actions",
    ];
    const Navigate = useNavigate();
    const [loading, setLoading] = useState(true);
    const [Active, setActive] = useState(2);
    const [Devalaya, setDevalaya] = useState<devalaya[]>([]);
    const [searchDevalaya, setSearchDevalaya] = useState<devalaya[]>([]);
    const [CSVData, setCsvData] = useState<devalaya[]>([]);
    // State to handle modal visibility and the selected image
    const [isModalVisible, setIsModalVisible] = useState<boolean>(false);
    const [selectedImage, setSelectedImage] = useState<string | null>(null);
    const Tabs = [
        { label: "Pending", value: 2 },
        { label: "Approved", value: 1 },
        { label: "Rejected", value: 3 },
    ];
    const getDevalaya = async (Active: number) => {
        setLoading(true);
        try {
            const response = await Get_Devalaya(Active);
            if (response?.data?.Status === 1) {
                setCsvData([
                    ["Devalaya Name", "Type", "Address"],
                    ...response?.data?.devalayas?.map(
                        (item: devalaya, index: number) => [
                            item.devalaya_name,
                            item?.devalaya_type_name,
                            `${item?.address?.address_1} ${item?.address?.address_2} ${item?.address?.city} ${item?.address?.state}  ${item.address?.country}`,
                        ]
                    ),
                ]);

                setDevalaya(response?.data?.devalayas);
                setSearchDevalaya(response?.data?.devalayas);
            } else {
                showWarningAlert(response?.data?.Message);
            }
        } catch (err: any) {
            showErrorAlert(
                err?.response?.data?.Message ||
                    err?.response?.data?.message ||
                    "Something went wrong try again later"
            );
        } finally {
            setTimeout(() => {
                setLoading(false);
            }, 400);
        }
    };
    const update = async (id: any, Status: number, Message: string) => {
        setLoading(true);
        try {
            const response = await update_Devalaya({ status: Status }, id);
            if (response?.data?.Status === 1) {
                Toast(`Devalaya ${Message} Successfully`, "success");
                getDevalaya(Active);
            } else {
                setLoading(false);
                showWarningAlert(response?.data?.Message);
            }
        } catch (err: any) {
            setLoading(false);
            showErrorAlert(
                err?.response?.data?.Message ||
                    err?.response?.data?.message ||
                    "Something went wrong try again later"
            );
        }
    };
    useEffect(() => {
        getDevalaya(Active); // eslint-disable-next-line
    }, [Active]);
    // Function to handle image click and show the modal
    const handleImageClick = (imageUrl: string) => {
        setSelectedImage(imageUrl);
        setIsModalVisible(true);
    };

    // Function to close the modal
    const handleCancel = () => {
        setIsModalVisible(false);
        setSelectedImage(null);
    };
    const searchHandler = (e: React.ChangeEvent<HTMLInputElement>) => {
        setDevalaya(() =>
            searchDevalaya.filter((item) =>
                String(item.devalaya_name)
                    .toLowerCase()
                    .includes(String(e.target.value).toLowerCase())
            )
        );
    };

    const updateHandler = (
        Id: number | undefined,
        Status: number,
        Message: string
    ) => {
        confirmAction(
            "Warning",
            `Are you sure you want to ${Message} Devalaya?`,
            () => update(Id, Status, Message),
            Message
        );
    };
    return (
        <div className={Styles["Container"]}>
            <h3>Devalaya</h3>
            <div style={{ overflow: "auto" }}>
                <ImagePreview
                    title="Devalaya"
                    handleCancel={handleCancel}
                    isModalVisible={isModalVisible}
                    selectedImage={selectedImage}
                />
                <div className={DevalayaStyles["Master_data_filter"]}>
                    <div className={DevalayaStyles["filter_right"]}>
                        <div className={DevalayaStyles["form_control"]}>
                            <input
                                type="text"
                                onChange={searchHandler}
                                placeholder="Search Devalaya"
                            />
                            <IoMdSearch className={DevalayaStyles["search"]} />
                        </div>
                    </div>
                    <div className={DevalayaStyles["filter_btns"]}>
                        <CSVLink
                            filename="Devalayas"
                            data={CSVData}
                            className={DevalayaStyles["download"]}>
                            {/* <button>
                            </button> */}
                            <MdOutlineFileDownload size={15} /> Export
                        </CSVLink>

                        <button onClick={() => Navigate("/auth/devalaya/add")}>
                            + Add Devalaya
                        </button>
                    </div>
                </div>{" "}
                <div style={{ margin: "1rem 0" }}>
                    <TabsComponent
                        Tabs={Tabs}
                        active={Active}
                        setActive={setActive}
                    />
                </div>
                {loading ? (
                    <Tableload TableHead={tableHeaders} />
                ) : (
                    <div className={Styles["tableWrapper"]}>
                        <table>
                            <thead>
                                {tableHeaders.map((item) => (
                                    <th key={item}>{item}</th>
                                ))}
                            </thead>
                            <tbody>
                                {Devalaya.length > 0 ? (
                                    Devalaya.map((item, index) => (
                                        <tr>
                                            <td>{index + 1}</td>
                                            <td
                                                onClick={() =>
                                                    handleImageClick(
                                                        item.profile_photo
                                                    )
                                                }>
                                                <div
                                                    className={
                                                        Styles["banner"]
                                                    }>
                                                    <img
                                                        src={item.profile_photo}
                                                        alt="banner"
                                                    />
                                                    <div>
                                                        <p>
                                                            {item.devalaya_name}
                                                        </p>
                                                    </div>
                                                </div>
                                            </td>

                                            <td>{item?.devalaya_type_name}</td>
                                            <td
                                                style={{
                                                    maxWidth: "200px",
                                                    wordBreak: "break-word",
                                                }}>
                                                {item?.address?.address_1}
                                                {item?.address?.address_2 &&
                                                    `, ${item?.address?.address_2}`}
                                                {item?.address?.city &&
                                                    `, ${item?.address.city}`}
                                                {item?.address?.state &&
                                                    `, ${item.address?.state}`}
                                                {item.address?.country &&
                                                    `, ${item.address?.country}`}
                                            </td>
                                            {Devalaya.length > 0 &&
                                            Active != 2 ? (
                                                <td>
                                                    <div
                                                        className={
                                                            Styles[
                                                                "Action_Tabs"
                                                            ]
                                                        }>
                                                        <button
                                                            onClick={() =>
                                                                Navigate(
                                                                    "/auth/devalaya/edit",
                                                                    {
                                                                        state: item,
                                                                    }
                                                                )
                                                            }>
                                                            <IoPencil
                                                                size={22}
                                                            />
                                                        </button>

                                                        <button
                                                            onClick={() =>
                                                                updateHandler(
                                                                    item.devalaya_id,
                                                                    4,
                                                                    "Delete"
                                                                )
                                                            }>
                                                            <MdDelete
                                                                size={20}
                                                            />
                                                        </button>
                                                    </div>
                                                </td>
                                            ) : (
                                                <td>
                                                    <div
                                                        className={
                                                            Styles[
                                                                "Action_approve_reject"
                                                            ]
                                                        }>
                                                        <button>
                                                            <FaEye size={18} />
                                                        </button>
                                                        <button
                                                            onClick={() =>
                                                                updateHandler(
                                                                    item.devalaya_id,
                                                                    1,
                                                                    "Approve"
                                                                )
                                                            }>
                                                            Approve
                                                        </button>
                                                        <button
                                                            onClick={() =>
                                                                updateHandler(
                                                                    item.devalaya_id,
                                                                    3,
                                                                    "Reject"
                                                                )
                                                            }>
                                                            Reject
                                                        </button>
                                                    </div>
                                                </td>
                                            )}
                                        </tr>
                                    ))
                                ) : (
                                    <tr>
                                        {" "}
                                        <td colSpan={tableHeaders.length}>
                                            No Data Found
                                        </td>
                                    </tr>
                                )}
                            </tbody>
                        </table>
                    </div>
                )}
            </div>
        </div>
    );
}

export default Banners;
