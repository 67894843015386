import React from "react";
import { Modal } from "antd";

interface MyModalProps {
    isModalVisible: boolean;
    onOk: () => void;
    onCancel: () => void;

    content: any;
}

const MyModal: React.FC<MyModalProps> = ({
    isModalVisible,
    onOk,
    onCancel,
    content,
}) => {
    return (
        <Modal
            open={isModalVisible}
            onOk={onOk}
            onCancel={onCancel}
            width={800}
            style={{ whiteSpace: "pre-line" }}>
            <div className={"title_wrapper"}>
                <h3>{content?.title}</h3>
                <div dangerouslySetInnerHTML={{ __html: content.content }} />
            </div>
        </Modal>
    );
};

export default MyModal;
