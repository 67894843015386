import { useState, useEffect } from "react";
import Styles from "Styles/Styles.module.css";
import { IoPencil } from "react-icons/io5";
import { IoIosEye, IoMdSearch } from "react-icons/io";
import { MdDelete } from "react-icons/md";
import { get_Pandit, update_Pandit } from "Services/Requests";
import TableLoad from "Components/TableLoad";
import Panditclass from "./Pandits.module.css";
import { MdOutlineFileDownload } from "react-icons/md";
import { FiPlus } from "react-icons/fi";
import { useNavigate } from "react-router";
import {
    showErrorAlert,
    showWarningAlert,
    Toast,
    confirmAction,
} from "Util/Alerts";
import Moment from "react-moment";
import { Pandits } from "./Pandit";
function Banners() {
    const Navigate = useNavigate();
    // const [Active, setActive] = useState(1);
    const [loading, setLoading] = useState<boolean>(true);
    const [Pandits, setPandits] = useState<Pandits[]>([]);
    const [SearchPandits, setSearchPandits] = useState<Pandits[]>([]);

    /*  const Category = [
        { label: "All Pandits", value: 1, count: 10 },
        { label: "Pending Verification", value: 2, count: 3000 },
        { label: "KYC Completed", value: 3, count: 120 },
    ];

    const StatusCode = (code: number) => {
        switch (code) {
            case 1:
                return {
                    backgroundColor: "#FFFBEA",
                    color: "#F0A500",
                    Name: "Pending",
                };
            case 2:
                return {
                    backgroundColor: "#E6F9F1",
                    color: "#198754",
                    Name: "Verified",
                };
            default:
                return {
                    backgroundColor: "#F0F1F3",
                    color: "#667085",
                    Name: "Draft",
                };
        }
    }; */
    const getPandits = async () => {
        try {
            const response = await get_Pandit();
            if (response?.data?.Status === 1) {
                setPandits(response?.data?.pandits);
                setSearchPandits(response?.data?.pandits);
            } else {
                showWarningAlert(response?.data?.Message);
            }
        } catch (err: any) {
            showErrorAlert(
                err?.response?.data?.Message ||
                    err?.response?.data?.message ||
                    "Something went wrong try again later"
            );
        } finally {
            setLoading(false);
        }
    };
    const DeletePandit = async (id: any) => {
        setLoading(true);
        try {
            const response = await update_Pandit({ user_status: 0 }, id);
            if (response?.data?.Status === 1) {
                Toast("Pandit Deleted Successfully", "success");
                getPandits();
            } else {
                setLoading(false);
                showWarningAlert(response?.data?.Message);
            }
        } catch (err: any) {
            setLoading(false);
            showErrorAlert(
                err?.response?.data?.Message ||
                    err?.response?.data?.message ||
                    "Something went wrong try again later"
            );
        }
    };
    useEffect(() => {
        getPandits();
    }, []);
    const TableHead = [
        "S NO",
        "User Name",
        "Mobile Number",
        "DOB",
        "Gender",
        "Pronoun",
        "Actions",
    ];

    const searchHandler = (e: React.ChangeEvent<HTMLInputElement>) => {
        setPandits(
            SearchPandits.filter((item) =>
                `${item.first_name} ${item.last_name}`
                    .toLowerCase()
                    .includes(String(e.target.value).toLowerCase())
            )
        );
    };
    const deleteHandler = (item: Pandits) => {
        confirmAction(
            "Warning",
            `Are you sure you want to Delete Pandit (${item.first_name} ${item.last_name}) ?`,
            () => DeletePandit(item.user_id),
            "Delete"
        );
    };
    return (
        <div className={Styles["Container"]}>
            <header>
                <h3>Pandit Management</h3>
                <div className={Panditclass["header_action"]}>
                    <button>
                        <MdOutlineFileDownload size={22} />
                        Export
                    </button>
                    <button
                        className={Styles["Add_cat_btn"]}
                        onClick={() =>
                            Navigate("/auth/profile-management/pandit/add")
                        }>
                        <FiPlus />
                        Add Pandit
                    </button>
                </div>
            </header>
            <div className={Panditclass["product_filters"]}>
                {/*  <div className={Panditclass["All_Categories_wrapper"]}>
                    <div className={Panditclass["All_Categories"]}>
                        {Category.map((item, index) => (
                            <button
                                key={index}
                                className={
                                    item.value === Active
                                        ? Panditclass["active"]
                                        : ""
                                }
                                onClick={() => setActive(item.value)}>
                                {item.label} ({item?.count})
                            </button>
                        ))}
                    </div>
                </div> */}
                <div className={Panditclass["filter_right"]}>
                    <div className={Panditclass["form_control"]}>
                        <input
                            onChange={searchHandler}
                            type="text"
                            placeholder="Search Pandit"
                        />
                        <IoMdSearch className={Panditclass["search"]} />
                    </div>
                </div>
            </div>
            {loading ? (
                <TableLoad TableHead={TableHead} />
            ) : (
                <div className={Styles["tableWrapper"]}>
                    <table>
                        <thead>
                            {TableHead.map((item, index) => (
                                <th key={index}>{item}</th>
                            ))}
                        </thead>
                        <tbody>
                            {Pandits.length > 0 ? (
                                Pandits.map((item, index) => (
                                    <tr>
                                        <td>{index + 1}</td>
                                        <td>
                                            {item?.first_name} {item?.last_name}
                                        </td>
                                        <td>{item?.mobile_number}</td>
                                        <td>
                                            <Moment format="DD-MM-YYYY">
                                                {item?.dob}
                                            </Moment>
                                        </td>{" "}
                                        <td>
                                            {item?.gender === 1
                                                ? "Male"
                                                : item.gender === 2
                                                ? "Female"
                                                : "Prefer Not to say"}
                                        </td>
                                        <td>{item?.gender_pronoun}</td>
                                        {/* <td>
                                        {" "}
                                        <span
                                            style={{
                                                padding: "0.5rem",
                                                borderRadius: "10px",
                                                color: StatusCode(2).color,
                                                backgroundColor:
                                                    StatusCode(2)
                                                        .backgroundColor,
                                            }}>
                                            {StatusCode(2).Name}
                                        </span>
                                    </td> */}
                                        <td>
                                            <div
                                                className={
                                                    Styles["Action_Tabs"]
                                                }>
                                                <button
                                                    onClick={() =>
                                                        Navigate(
                                                            "/auth/profile-management/pandit/edit",
                                                            { state: item }
                                                        )
                                                    }>
                                                    <IoPencil size={22} />
                                                </button>
                                                <button>
                                                    <IoIosEye size={20} />
                                                </button>
                                                <button
                                                    onClick={() =>
                                                        deleteHandler(item)
                                                    }>
                                                    <MdDelete size={20} />
                                                </button>
                                            </div>
                                        </td>
                                    </tr>
                                ))
                            ) : (
                                <tr>
                                    <td colSpan={TableHead.length}>
                                        No Pandit Found
                                    </td>
                                </tr>
                            )}
                        </tbody>
                    </table>
                </div>
            )}
        </div>
    );
}

export default Banners;
