import { useState, useEffect } from "react";
import Styles from "Styles/Styles.module.css";
import DevalayaStyles from "Pages/Auth/MasterData/Devalaya/devalaya.module.css";
import { IoPencil } from "react-icons/io5";
import { IoMdSearch } from "react-icons/io";
import { MdDelete } from "react-icons/md";
import { MdOutlineFileDownload } from "react-icons/md";
import { get_UtsavTypes, update_UtsavTypes } from "Services/Requests";
import {
    showWarningAlert,
    showErrorAlert,
    Toast,
    confirmAction,
} from "Util/Alerts";
import Tableload from "Components/TableLoad";
import { utsavType } from "./Types";
import AddType from "./Add";

function TypeComp() {
    let tableHeaders = ["SNO", "Utsav Type", "Actions"];
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [Edit, setIsIedit] = useState<null | utsavType>(null);
    const [Utsav, setUtsav] = useState([]);
    const [searchUtsav, setSearchUtsav] = useState([]);
    const [loading, setLoading] = useState(true);

    const getUtsav = async () => {
        try {
            const response = await get_UtsavTypes();
            if (response?.data?.Status === 1) {
                setUtsav(response?.data?.utsavTypes);
                setSearchUtsav(response?.data?.utsavTypes);
            } else {
                showWarningAlert(response?.data?.Message);
            }
        } catch (err: any) {
            showErrorAlert(
                err?.response?.data?.Message ||
                    err?.response?.data?.message ||
                    "Something went wrong try again later"
            );
        } finally {
            setLoading(false);
        }
    };

    const DeleteCommitte = async (id: any) => {
        setLoading(true);
        try {
            const response = await update_UtsavTypes({ status: 0 }, id);
            if (response?.data?.Status === 1) {
                Toast("Utsav Deleted Successfully", "success");
                getUtsav();
            } else {
                setLoading(false);
                showWarningAlert(response?.data?.Message);
            }
        } catch (err: any) {
            setLoading(false);
            showErrorAlert(
                err?.response?.data?.Message ||
                    err?.response?.data?.message ||
                    "Something went wrong try again later"
            );
        }
    };
    useEffect(() => {
        getUtsav();
    }, []);

    const searchHandler = (e: React.ChangeEvent<HTMLInputElement>) => {
        setUtsav(() =>
            searchUtsav.filter((item: any) =>
                String(item.utsav_type_name)
                    .toLowerCase()
                    .includes(String(e.target.value).toLowerCase())
            )
        );
    };
    const deleteHandler = (item: utsavType) => {
        confirmAction(
            "Warning",
            `Are you sure you want to Delete Type (${item.utsav_type_name}) ?`,
            () => DeleteCommitte(item.utsav_type_id),
            "Delete"
        );
    };
    return (
        <div style={{ overflow: "auto" }}>
            {isModalOpen && (
                <AddType
                    isModalOpen
                    setisModalOpen={setIsModalOpen}
                    setIsIedit={setIsIedit}
                    Edit={Edit}
                    getUtsav={getUtsav}
                />
            )}
            <div className={DevalayaStyles["Master_data_filter"]}>
                <div className={DevalayaStyles["filter_right"]}>
                    <div className={DevalayaStyles["form_control"]}>
                        <input
                            type="text"
                            onChange={searchHandler}
                            placeholder="Search Utsav"
                        />
                        <IoMdSearch className={DevalayaStyles["search"]} />
                    </div>
                </div>
                <div className={DevalayaStyles["filter_btns"]}>
                    <button>
                        <MdOutlineFileDownload size={15} /> Export
                    </button>
                    <button onClick={() => setIsModalOpen(true)}>
                        + Add Utsav
                    </button>
                </div>
            </div>
            {loading ? (
                <Tableload TableHead={tableHeaders} />
            ) : (
                <div className={Styles["tableWrapper"]}>
                    <table>
                        <thead>
                            {tableHeaders.map((item) => (
                                <th>{item}</th>
                            ))}
                        </thead>
                        <tbody>
                            {Utsav.length > 0 ? (
                                Utsav.map((item: utsavType, index) => (
                                    <tr>
                                        <td>{index + 1}</td>

                                        <td>{item?.utsav_type_name}</td>

                                        <td>
                                            <div
                                                className={
                                                    Styles["Action_Tabs"]
                                                }>
                                                <button
                                                    onClick={() => {
                                                        setIsIedit(item);
                                                        setIsModalOpen(true);
                                                    }}>
                                                    <IoPencil size={22} />
                                                </button>

                                                <button
                                                    onClick={() =>
                                                        deleteHandler(item)
                                                    }>
                                                    <MdDelete size={20} />
                                                </button>
                                            </div>
                                        </td>
                                    </tr>
                                ))
                            ) : (
                                <tr>
                                    <td colSpan={tableHeaders.length}>
                                        {" "}
                                        No Data Found
                                    </td>
                                </tr>
                            )}
                        </tbody>
                    </table>
                </div>
            )}
        </div>
    );
}

export default TypeComp;
