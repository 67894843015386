// Restrict Past Days
export const validatePastDate = (value: string) => {
    const selectedDate = new Date(value);

    // Ensure the date is not in the past
    const today = new Date();
    today.setHours(0, 0, 0, 0); // Set time to midnight

    if (selectedDate < today) {
        return "The date cannot be in the past";
    }

    return true;
};
