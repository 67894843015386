import React from "react";
import {
    UseFormRegister,
    FieldError,
    Path,
    FieldValues,
} from "react-hook-form";
import { IoIosWarning } from "react-icons/io";
import Styles from "Styles/Styles.module.css";

interface FormControlProps<T extends FieldValues> {
    label: string;
    name: Path<T>;
    register: UseFormRegister<T>;
    errors: FieldError | undefined;
    requiredMessage?: string;
    type?:
        | "text"
        | "textarea"
        | "select"
        | "date"
        | "number"
        | "password"
        | "email"; // Added date and textarea
    options?: { label: string; value: number | string }[];
    placeholder?: string; // Placeholder for input fields
    autoFocus?: boolean;
    validate?: (value: string) => boolean | string;
}

function FormControl<T extends FieldValues>({
    label,
    name,
    register,
    errors,
    requiredMessage,
    type = "text",
    options = [],
    placeholder,
    autoFocus = false,
    validate,
}: FormControlProps<T>) {
    return (
        <div className={Styles["form_control"]}>
            <label htmlFor={name as string}>
                {label}{" "}
                {requiredMessage && <span style={{ color: "red" }}>*</span>}
            </label>

            {/* Conditional rendering based on the type prop */}
            {type === "textarea" ? (
                <textarea
                    id={name as string}
                    className={errors ? Styles["form_control_error"] : ""}
                    rows={8}
                    placeholder={placeholder}
                    {...register(name, { required: requiredMessage, validate })}
                    autoFocus={autoFocus}
                />
            ) : type === "select" ? (
                <select
                    id={name as string}
                    className={errors ? Styles["form_control_error"] : ""}
                    {...register(name, { required: requiredMessage, validate })}
                    defaultValue="">
                    <option value="" disabled>
                        Select {label}
                    </option>
                    {options.map((option, index) => (
                        <option key={index} value={option.value}>
                            {option.label}
                        </option>
                    ))}
                </select>
            ) : type === "date" ? (
                <input
                    id={name as string}
                    className={errors ? Styles["form_control_error"] : ""}
                    type="date"
                    placeholder={placeholder}
                    {...register(name, {
                        required: requiredMessage,
                        validate: validate,
                    })}
                    autoFocus={autoFocus}
                />
            ) : type === "number" ? (
                <input
                    id={name as string}
                    className={errors ? Styles["form_control_error"] : ""}
                    type="number"
                    step={0.00000000001}
                    placeholder={placeholder}
                    {...register(name, {
                        required: requiredMessage,
                        validate: validate,
                    })}
                    autoFocus={autoFocus}
                />
            ) : type === "password" ? (
                <input
                    id={name as string}
                    className={errors ? Styles["form_control_error"] : ""}
                    type="password"
                    placeholder={placeholder}
                    {...register(name, { required: requiredMessage, validate })}
                    autoFocus={autoFocus}
                />
            ) : type == "email" ? (
                <input
                    id={name as string}
                    className={errors ? Styles["form_control_error"] : ""}
                    type="email"
                    placeholder={placeholder}
                    {...register(name, { required: requiredMessage, validate })}
                    autoFocus={autoFocus}
                />
            ) : (
                <input
                    id={name as string}
                    className={errors ? Styles["form_control_error"] : ""}
                    type="text"
                    placeholder={placeholder}
                    {...register(name, { required: requiredMessage, validate })}
                    autoFocus={autoFocus}
                />
            )}

            {errors && (
                <p className={Styles["Gen-erro_msg"]}>
                    <IoIosWarning />
                    {errors?.message}
                </p>
            )}
        </div>
    );
}

export default FormControl;
