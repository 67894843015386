import { useState, useEffect } from "react";
import Styles from "Styles/Styles.module.css";
import DevalayaStyles from "Pages/Auth/MasterData/Devalaya/devalaya.module.css";
import { IoPencil } from "react-icons/io5";
import { IoMdSearch } from "react-icons/io";
import { MdDelete } from "react-icons/md";
import { MdOutlineFileDownload } from "react-icons/md";
import { Deities_get, deities_update } from "Services/Requests";
import {
    showWarningAlert,
    showErrorAlert,
    Toast,
    confirmAction,
} from "Util/Alerts";
import Tableload from "Components/TableLoad";
import { DeitiesTypes } from "./Types";
import AddType from "./Add";

function Deities() {
    let tableHeaders = ["SNO", "Icon", "Deity Name", "Slogan", "Actions"];
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [Edit, setIsIedit] = useState<null | DeitiesTypes>(null);
    const [Deities, setDeities] = useState([]);
    const [searchDeities, setSearchDeities] = useState([]);
    const [loading, setLoading] = useState(true);

    const getDeities = async () => {
        try {
            const response = await Deities_get();
            if (response?.data?.Status === 1) {
                setDeities(response?.data?.deities);
                setSearchDeities(response?.data?.deities);
            } else {
                showWarningAlert(response?.data?.Message);
            }
        } catch (err: any) {
            showErrorAlert(
                err?.response?.data?.Message ||
                    err?.response?.data?.message ||
                    "Something went wrong try again later"
            );
        } finally {
            setLoading(false);
        }
    };
    const Delete = async (id: any) => {
        setLoading(true);
        try {
            const response = await deities_update({ status: 0 }, id);
            if (response?.data?.Status === 1) {
                Toast("Deity Deleted Successfully", "success");
                getDeities();
            } else {
                setLoading(false);
                showWarningAlert(response?.data?.Message);
            }
        } catch (err: any) {
            setLoading(false);
            showErrorAlert(
                err?.response?.data?.Message ||
                    err?.response?.data?.message ||
                    "Something went wrong try again later"
            );
        }
    };

    useEffect(() => {
        getDeities();
    }, []);

    const searchHandler = (e: React.ChangeEvent<HTMLInputElement>) => {
        setDeities(() =>
            searchDeities.filter((item: any) =>
                String(item.facility_name)
                    .toLowerCase()
                    .includes(String(e.target.value).toLowerCase())
            )
        );
    };

    const deleteHandler = (item: DeitiesTypes) => {
        confirmAction(
            "Warning",
            "Are you sure you want to Delete Deity",
            () => Delete(item.deity_id),
            "Delete"
        );
    };
    return (
        <div style={{ overflow: "auto" }}>
            <div className={Styles["Container"]}>
                <header>
                    <h3>Deities</h3>
                </header>
                {isModalOpen && (
                    <AddType
                        isModalOpen
                        setisModalOpen={setIsModalOpen}
                        setIsIedit={setIsIedit}
                        Edit={Edit}
                        getDeities={getDeities}
                    />
                )}
                <div className={DevalayaStyles["Master_data_filter"]}>
                    <div className={DevalayaStyles["filter_right"]}>
                        <div className={DevalayaStyles["form_control"]}>
                            <input
                                type="text"
                                onChange={searchHandler}
                                placeholder="Search Deities"
                            />
                            <IoMdSearch className={DevalayaStyles["search"]} />
                        </div>
                    </div>
                    <div className={DevalayaStyles["filter_btns"]}>
                        <button>
                            <MdOutlineFileDownload size={15} /> Export
                        </button>
                        <button onClick={() => setIsModalOpen(true)}>
                            + Add Deity
                        </button>
                    </div>
                </div>
                {loading ? (
                    <Tableload TableHead={tableHeaders} />
                ) : (
                    <div className={Styles["tableWrapper"]}>
                        <table>
                            <thead>
                                {tableHeaders.map((item) => (
                                    <th>{item}</th>
                                ))}
                            </thead>
                            <tbody>
                                {Deities.length > 0 ? (
                                    Deities.map((item: DeitiesTypes, index) => (
                                        <tr>
                                            <td>{index + 1}</td>
                                            <td>
                                                <div
                                                    className={
                                                        Styles["banner"]
                                                    }>
                                                    <img
                                                        src={item?.deity_image}
                                                        alt="Deities"
                                                    />
                                                </div>
                                            </td>
                                            <td>{item?.deity_name}</td>
                                            <td>{item?.deity_slogan}</td>

                                            <td>
                                                <div
                                                    className={
                                                        Styles["Action_Tabs"]
                                                    }>
                                                    <button
                                                        onClick={() => {
                                                            setIsIedit(item);
                                                            setIsModalOpen(
                                                                true
                                                            );
                                                        }}>
                                                        <IoPencil size={22} />
                                                    </button>

                                                    <button
                                                        onClick={() =>
                                                            deleteHandler(item)
                                                        }>
                                                        <MdDelete size={20} />
                                                    </button>
                                                </div>
                                            </td>
                                        </tr>
                                    ))
                                ) : (
                                    <tr>
                                        <td colSpan={tableHeaders.length}>
                                            {" "}
                                            No Data Found
                                        </td>
                                    </tr>
                                )}
                            </tbody>
                        </table>
                    </div>
                )}
            </div>
        </div>
    );
}

export default Deities;
