import { useState, useEffect } from "react";
import Styles from "Styles/Styles.module.css";
import { useForm, SubmitHandler, Controller } from "react-hook-form";
import { IoIosWarning } from "react-icons/io";
import { showSuccessNotification } from "Util/Notification";
import { useNavigate } from "react-router";
import { Breadcrumb } from "antd";
import { NavLink } from "react-router-dom";
import { useParams } from "react-router";
import faqClasses from "../Faq.module.css";
type Inputs = {
    question: string;
    answer: string;
    categoryId: number;
    tags: string[];
};

function AddBanner() {
    const navigate = useNavigate();
    const { type } = useParams();
    /*   const [ErrorMsg, setErrorMsg] = useState<null | String>(null); */
    const [tags, setTags] = useState<string[]>([]);
    const [inputValue, setInputValue] = useState("");

    const {
        register,
        handleSubmit,
        control,
        setValue,
        trigger,
        formState: { errors },
    } = useForm<Inputs>({
        defaultValues: { tags: [] }, // Default value for the tags field
    });

    useEffect(() => {
        // Update form value when tags change
        setValue("tags", tags);
        if (tags.length > 0) {
            trigger("tags");
        } // eslint-disable-next-line
    }, [tags, setValue]);

    const handleKeyDown = (e: any) => {
        if (e.key === "Enter" && inputValue.trim() !== "") {
            e.preventDefault();
            // Add tag to the array when Enter is pressed
            let isTagExist = tags.find((item) => item === inputValue.trim());
            if (!isTagExist) {
                setTags((prevTags) => [...prevTags, inputValue.trim()]);
            }
            setInputValue("");
        }
    };

    const onSubmit: SubmitHandler<Inputs> = (data) => {
        showSuccessNotification("success", "Banner Added Successfully");
      
        navigate(-1);
    };
    const removeHandler = (item: string) => {
        setTags((prev) => prev.filter((tag) => tag !== item));
    };

    return (
        <div className={`${Styles["Add_form_wrapper"]}`}>
            <h3>Add FAQ</h3>
            <Breadcrumb>
                <Breadcrumb.Item>
                    <NavLink to="/auth/static-content/faq">FAQ</NavLink>
                </Breadcrumb.Item>
                <Breadcrumb.Item>
                    <span style={{ textTransform: "capitalize" }}>
                        {type} FAQ
                    </span>
                </Breadcrumb.Item>
            </Breadcrumb>

            <form onSubmit={handleSubmit(onSubmit)}>
                <div
                    className={`${Styles["form"]} ${Styles["form_secondary"]}`}>
                    <h3 style={{ gridColumn: "1/-1" }}>General Information</h3>
                    <div className={Styles["form_control"]}>
                        <label htmlFor="Banner Name"> Question</label>
                        <input
                            className={
                                errors?.question && Styles["form_control_error"]
                            }
                            placeholder="Enter Question"
                            {...register("question", {
                                required: "Question is required",
                            })}
                        />
                        {errors?.question && (
                            <p>
                                <IoIosWarning />
                                {errors?.question?.message}
                            </p>
                        )}
                    </div>

                    <div className={Styles["form_control"]}>
                        <label htmlFor="Banner Name">Answer</label>
                        <input
                            className={
                                errors?.answer && Styles["form_control_error"]
                            }
                            type="text"
                            {...register("answer", {
                                required: "Answer is required",
                            })}
                        />
                        {errors?.answer && (
                            <p>
                                <IoIosWarning />
                                {errors?.answer?.message}
                            </p>
                        )}
                    </div>

                    <div className={Styles["form_control"]}>
                        <label htmlFor="Banner Name">Category</label>
                        <select
                            className={
                                errors?.categoryId &&
                                Styles["form_control_error"]
                            }
                            {...register("categoryId", {
                                required: "Category is required",
                            })}>
                            <option value="" disabled>
                                Select Category
                            </option>
                        </select>
                        {errors?.categoryId && (
                            <p>
                                <IoIosWarning />
                                {errors?.categoryId?.message}
                            </p>
                        )}
                    </div>

                    <div className={Styles["form_control"]}>
                        <label htmlFor="Banner Name">Tags</label>
                        <Controller
                            name="tags"
                            control={control}
                            defaultValue={tags}
                            rules={{ required: "Tags are required" }}
                            render={({ field }) => (
                                <div>
                                    <input
                                        type="text"
                                        value={inputValue}
                                        onChange={(e) =>
                                            setInputValue(e.target.value)
                                        }
                                        onKeyDown={handleKeyDown}
                                        placeholder="Press Enter to add a tag"
                                    />
                                    <div className={faqClasses["all_Tags"]}>
                                        {tags.map((tag, index) => (
                                            <span
                                                key={index}
                                                style={{
                                                    marginRight: 5,
                                                }}
                                                onDoubleClick={() =>
                                                    removeHandler(tag)
                                                }>
                                                {tag}
                                            </span>
                                        ))}
                                    </div>
                                </div>
                            )}
                        />
                        {errors?.tags && (
                            <p>
                                <IoIosWarning />
                                {errors?.tags?.message}
                            </p>
                        )}
                    </div>
                </div>

                <button type="submit">Submit</button>
            </form>
        </div>
    );
}

export default AddBanner;
