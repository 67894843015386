import { useEffect, useState } from "react";
import Styles from "Styles/Styles.module.css";
import { useForm, SubmitHandler } from "react-hook-form";
import { IoIosWarning } from "react-icons/io";
import { useNavigate } from "react-router";
import { Breadcrumb } from "antd";
import { NavLink, useLocation } from "react-router-dom";
import { useParams } from "react-router";
import { Add_Terms_Condition, update_Terms_condition } from "Services/Requests";
import { showErrorAlert, showWarningAlert, Toast } from "Util/Alerts";
import { Spin } from "antd";

import ReactQuill from "react-quill";
import { Terms } from "./Types";
function AddBanner() {
    const navigate = useNavigate();
    const { type } = useParams();
    const [ErrorMsg, setErrorMsg] = useState("");

    const [quillData, setQuillData] = useState("");
    const [loading, setLoading] = useState(false);
    // const [ErrorMsg, setErrorMsg] = useState<null | String>(null);
    const location = useLocation();

    const {
        register,
        handleSubmit,
        reset,
        formState: { errors },
    } = useForm<Terms>();

    useEffect(() => {
        if (type === "edit") {
            reset({ ...location.state });
            setQuillData(location.state?.section_content);
        } // eslint-disable-next-line
    }, []);
    const onSubmit: SubmitHandler<Terms> = async (data) => {
        try {
            if (quillData.length === 0) {
                setErrorMsg("Content is required");
                return;
            }
            setLoading(true);
            let response;
            if (type === "edit") {
                response = await update_Terms_condition(
                    { ...data, section_content: quillData },
                    location.state.terms_condition_id
                );
            } else {
                response = await Add_Terms_Condition({
                    ...data,
                    section_content: quillData,
                });
            }
            if (response?.data?.Status === 1) {
                Toast(response?.data?.Message, "success");
                navigate(-1);
            } else {
                showWarningAlert(response?.data?.Message);
            }
        } catch (err: any) {
            showErrorAlert(
                err?.response?.data?.Message ||
                    err?.response?.data?.message ||
                    "Something went wrong try again later"
            );
        } finally {
            setLoading(false);
        }
    };
    const toolbarOptions = [
        [{ font: [] }], // Font family
        [{ size: [] }], // Font size
        ["bold", "italic", "underline", "strike"], // Text formatting
        [{ align: [] }], // Alignment
        [{ list: "ordered" }, { list: "bullet" }], // Lists

        [{ color: [] }, { background: [] }], // Text color and background color
    ];

    // Modules configuration
    const modules = {
        toolbar: {
            container: toolbarOptions,
            handlers: {
                // Custom handlers if needed
            },
        },
    };
    return (
        <div className={`${Styles["Add_form_wrapper"]}`}>
            <h3>Add Terms and Conditions</h3>
            <Breadcrumb>
                <Breadcrumb.Item>
                    <NavLink to="/auth/static-content/terms-conditions">
                        Terms and conditions
                    </NavLink>
                </Breadcrumb.Item>
                <Breadcrumb.Item>
                    <span style={{ textTransform: "capitalize" }}>
                        {type} Section
                    </span>
                </Breadcrumb.Item>
            </Breadcrumb>

            <form onSubmit={handleSubmit(onSubmit)}>
                <div
                    className={`${Styles["form"]} ${Styles["form_secondary"]}`}>
                    <h3 style={{ gridColumn: "1/-1" }}>General Information</h3>
                    <div className={Styles["form_control"]}>
                        <label htmlFor="Banner Name"> Section</label>
                        <input
                            className={
                                errors?.section && Styles["form_control_error"]
                            }
                            placeholder="Enter Section"
                            {...register("section", {
                                required: "Section is required",
                            })}
                        />
                        {errors?.section && (
                            <p>
                                <IoIosWarning />
                                {errors?.section?.message}
                            </p>
                        )}
                    </div>

                    <div className={Styles["form_control"]}>
                        <label htmlFor="Banner Name">Language</label>
                        <input
                            className={
                                errors?.language && Styles["form_control_error"]
                            }
                            type="text"
                            {...register("language", {
                                required: "language is required",
                            })}
                        />
                        {errors?.language && (
                            <p>
                                <IoIosWarning />
                                {errors?.language?.message}
                            </p>
                        )}
                    </div>
                    <div
                        className={`${Styles["form_control_quill"]} quill_custom`}
                        style={{ gridColumn: "1/-1" }}>
                        {/*   <label htmlFor="Banner Name">Content</label> */}
                        <ReactQuill
                            theme="snow"
                            value={quillData}
                            onChange={setQuillData}
                            modules={modules}
                            // theme="snow" // Use 'snow' theme
                        />
                        {ErrorMsg.length > 0 && (
                            <p
                                className={Styles["Gen-erro_msg"]}
                                style={{ marginTop: "0.5rem" }}>
                                <IoIosWarning />
                                {ErrorMsg}
                            </p>
                        )}
                    </div>
                </div>

                <button type="submit" disabled={loading}>
                    {loading ? <Spin /> : "Save"}
                </button>
            </form>
        </div>
    );
}

export default AddBanner;
