import React from "react";
import { Navigate } from "react-router-dom";

// Mock function to check if the user is authenticated
const isAuthenticated = (): boolean => {
    // Replace this with your actual authentication logic
    return localStorage.getItem("Token") !== null;
};

interface AuthWrapperProps {
    children: React.ReactNode;
}

const AuthWrapper: React.FC<AuthWrapperProps> = ({ children }) => {
    if (!isAuthenticated()) {
        return <Navigate to="/" />;
    }

    return <>{children}</>;
};

export default AuthWrapper;
