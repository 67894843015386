import { useState, useEffect } from "react";
import Styles from "Styles/Styles.module.css";
import { useForm, SubmitHandler } from "react-hook-form";
import { useLocation } from "react-router";
import { IoIosWarning } from "react-icons/io";
import { showErrorAlert, showWarningAlert, Toast } from "Util/Alerts";
import { Spin } from "antd";
import { useParams } from "react-router";
import { useNavigate } from "react-router";
import { NavLink } from "react-router-dom";
import { Breadcrumb } from "antd";
import { Country } from "country-state-city";
import ReactFlagsSelect from "react-flags-select";
import PanditClasses from "./Pandits.module.css";
import { Add_Pandit, update_Pandit } from "Services/Requests";
import moment from "moment";
window.Buffer = window.Buffer || require("buffer").Buffer;

type Inputs = {
    first_name: string;
    last_name: string;
    mobile_number: number;
    gender: string;
    gender_pronoun: string;
    dob: string;
    country_code: number;
};

function AddBanner() {
    const navigate = useNavigate();
    const { type } = useParams(); // Get the dynamic parameter "id"
    const Location = useLocation();
    const [loading, setLoading] = useState(false);
    const [countryCode, setCountryCode] = useState<any>({
        countryCode: "IN",
        Code: "91",
    });

    const {
        register,
        handleSubmit,
        formState: { errors },
        watch,
        reset,
    } = useForm<Inputs>();
    let Countries = Country.getAllCountries();

    let customLabels = {};

    Countries.forEach((item) => {
        customLabels = {
            ...customLabels,
            [item.isoCode]: `+${item.phonecode}`,
        };
    });

    // SET COUNTRY CODE MOBILE NUMBER
    const Set_Country_CODE = (event: any) => {
        // console.log(event);
        // eslint-disable-next-line
        const data = Countries.filter((item) => item.isoCode == event);
        if (data.length > 0) {
            setCountryCode({
                countryCode: data[0].isoCode,
                Code: data[0].phonecode,
            });
        }
    };
    const onSubmit: SubmitHandler<Inputs> = async (data) => {
        let body = { ...data, country_code: countryCode.Code };
        if (data.gender === "1") {
            body = { ...data, gender_pronoun: "He" };
        }
        if (data.gender === "2") {
            body = { ...data, gender_pronoun: "She" };
        }
        try {
            setLoading(true);
            let response;
            if (type === "edit") {
                response = await update_Pandit(body, Location.state?.user_id);
            } else {
                response = await Add_Pandit(body);
            }
            if (response?.data?.Status === 1) {
                Toast(response?.data?.Message, "success");
                navigate(-1);
            } else {
                showWarningAlert(response?.data?.Message);
            }
        } catch (err: any) {
            showErrorAlert(
                err?.response?.data?.Message ||
                    err?.response?.data?.message ||
                    "Something went wrong try again later"
            );
        } finally {
            setLoading(false);
        }
        // Toastify("Banner Added Successfully", "success");
    };
    const watchData = watch();

    useEffect(() => {
        if (type === "edit") {
            let formData = Location.state;
            formData = {
                ...formData,
                dob: moment(formData?.dob).format("YYYY-MM-DD"),
            };
            if (formData.country_code !== "NA") {
                let selectedCountry = Countries.filter(
                    (item) => item.phonecode === String(formData?.country_code)
                );
                setCountryCode({
                    countryCode: selectedCountry[0].isoCode,
                    Code: selectedCountry[0].phonecode,
                });
            } else {
                setCountryCode({ countryCode: "IN", Code: "IN" });
            }
            reset({ ...formData, gender: formData?.gender?.toString() });
        } // eslint-disable-next-line
    }, [type, Location.state]);
    return (
        <div className={Styles["Add_form_wrapper"]}>
            <h3>
                <span style={{ textTransform: "capitalize" }}>{type}</span>{" "}
                Pandit
            </h3>
            <Breadcrumb>
                <Breadcrumb.Item>
                    <NavLink to="/auth/profile-management/pandit">
                        Pandit
                    </NavLink>
                </Breadcrumb.Item>
                <Breadcrumb.Item>
                    <span style={{ textTransform: "capitalize" }}>
                        {type} Pandit
                    </span>
                </Breadcrumb.Item>
            </Breadcrumb>

            <form onSubmit={handleSubmit(onSubmit)}>
                <h4>Pandit</h4>
                <div
                    className={`${Styles["form"]} ${Styles["form_secondary"]}`}>
                    {/* <FormControl errors={errors['fir']}/> */}
                    <div className={Styles["form_control"]}>
                        <label htmlFor="Banner Name">
                            First Name <span>*</span>
                        </label>
                        <input
                            className={
                                errors?.first_name &&
                                Styles["form_control_error"]
                            }
                            placeholder="Enter First Name"
                            {...register("first_name", {
                                required: "First Name is required",
                            })}
                        />
                        {errors?.first_name && (
                            <p>
                                <IoIosWarning />
                                {errors?.first_name?.message}
                            </p>
                        )}
                    </div>
                    <div className={Styles["form_control"]}>
                        <label htmlFor="Banner Name">
                            Last Name <span>*</span>
                        </label>
                        <input
                            className={
                                errors?.first_name &&
                                Styles["form_control_error"]
                            }
                            placeholder="Enter Last Name"
                            {...register("last_name", {
                                required: "Last Name is required",
                            })}
                        />
                        {errors?.last_name && (
                            <p>
                                <IoIosWarning />
                                {errors?.last_name?.message}
                            </p>
                        )}
                    </div>
                    <div className={`${Styles["form_control"]} `}>
                        <label htmlFor="Banner Name">
                            Mobile Number <span>*</span>
                        </label>
                        <div className={PanditClasses["reactSelect_custom"]}>
                            <input
                                className={
                                    errors.mobile_number
                                        ? Styles["form_control_error"]
                                        : ""
                                }
                                type="number"
                                autoComplete="off"
                                placeholder="Mobile number"
                                {...register("mobile_number", {
                                    required: "Mobile number is Required",
                                })}
                            />
                            {errors?.mobile_number && (
                                <p className={Styles["Gen-erro_msg"]}>
                                    <IoIosWarning />
                                    {errors?.mobile_number?.message}
                                </p>
                            )}
                            <div className={PanditClasses["flag_select"]}>
                                <ReactFlagsSelect
                                    searchable
                                    selected={countryCode.countryCode}
                                    showSelectedLabel={false}
                                    onSelect={Set_Country_CODE}
                                    fullWidth={false}
                                    // customLabels={customLabels}
                                    placeholder=""
                                    selectButtonClassName={
                                        PanditClasses["select_btn_option"]
                                    }
                                    className={PanditClasses["select-btn"]}
                                    searchPlaceholder="Search countries"
                                />
                            </div>
                        </div>
                    </div>
                    <div className={Styles["form_control"]}>
                        <label htmlFor="Banner Name">
                            DOB <span>*</span>
                        </label>
                        <input
                            className={
                                errors?.first_name &&
                                Styles["form_control_error"]
                            }
                            type="date"
                            placeholder="Enter DOB"
                            {...register("dob", {
                                required: "DOB is required",
                            })}
                        />
                        {errors?.dob && (
                            <p>
                                <IoIosWarning />
                                {errors?.dob?.message}
                            </p>
                        )}
                    </div>
                    <div className={Styles["form_control"]}>
                        <label htmlFor="Banner Name">
                            Gender <span>*</span>
                        </label>
                        <select
                            className={
                                errors?.gender && Styles["form_control_error"]
                            }
                            {...register("gender", {
                                required: "Gender is required",
                            })}>
                            <option value={""} disabled>
                                Select Gender
                            </option>
                            <option value={1}>Male</option>
                            <option value={2}>Female</option>
                            <option value={3}>Prefer not to say</option>
                        </select>
                        {errors?.gender && (
                            <p>
                                <IoIosWarning />
                                {errors?.gender?.message}
                            </p>
                        )}
                    </div>
                    {watchData.gender === "3" && (
                        <div className={Styles["form_control"]}>
                            <label htmlFor="Banner Name">
                                Pronoun <span>*</span>
                            </label>
                            <select
                                className={
                                    errors?.gender_pronoun &&
                                    Styles["form_control_error"]
                                }
                                {...register("gender_pronoun", {
                                    required: "Pronoun is required",
                                })}>
                                <option value={""} disabled>
                                    Select Your Pronoun
                                </option>
                                <option value={"She"}>
                                    She ("Wish her a happy birthday")
                                </option>
                                <option value={"He"}>
                                    He ("Wish him a happy birthday")
                                </option>
                                <option value={"They"}>
                                    They ("Wish Them a happy birthday")
                                </option>
                            </select>
                            {errors?.gender_pronoun && (
                                <p>
                                    <IoIosWarning />
                                    {errors?.gender_pronoun?.message}
                                </p>
                            )}
                        </div>
                    )}
                    {/* {ErrorMsg && (
                        <p className={Styles["Gen-erro_msg"]}>
                            {" "}
                            <IoIosWarning /> {ErrorMsg}
                        </p>
                    )} */}
                </div>

                {loading ? (
                    <Spin size="default" />
                ) : (
                    <button>{type === "edit" ? "Edit" : "Add"}</button>
                )}
            </form>
        </div>
    );
}

export default AddBanner;
