import React, { useState, useEffect } from "react";
import Modal from "Components/Modal/Modal";
import Styles from "Pages/Auth/MasterData/Devalaya/devalaya.module.css";
import {
    zipcode_update,
    zipcode_Add,
    get_State,
    get_city,
} from "Services/Requests";
import { useForm, SubmitHandler } from "react-hook-form";
import { showErrorAlert, showWarningAlert, Toast } from "Util/Alerts";
import { Spin } from "antd";
import { zipcodeType, ModalProps } from "./Types";
import FormControl from "Components/FormControl";
import { StateType } from "../State/Types";
import { cityType } from "../City/Types";

window.Buffer = window.Buffer || require("buffer").Buffer;

const ADDModal: React.FC<ModalProps> = ({
    setisModalOpen,
    isModalOpen,
    setIsIedit,
    Edit,
    getState,
}) => {
    const [loading, setLoading] = useState(false);
    const [State, setState] = useState([]);
    const [city, setCity] = useState([]);
    const [AllCity, setAllCity] = useState([]);
    const {
        register,
        handleSubmit,
        reset,
        watch,
        formState: { errors },
    } = useForm<zipcodeType>();

    const formData = watch();
    useEffect(() => {
        if (formData?.state_id) {
            let cities: any = AllCity.filter(
                (item: cityType) => item.state_id == formData?.state_id
            ).map((data: cityType) => ({
                value: data.city_id,
                label: data?.city,
            }));
            setCity(cities);
        }
    }, [formData?.state_id]);
    const fetchCountryStateAndCity = async () => {
        setLoading(true); // Optional: set loading before API calls
        try {
            const [stateResponse, cityResponse] = await Promise.all([
                get_State(),
                get_city(),
            ]);

            // Handling the response for getState
            if (
                stateResponse?.data?.Status === 1 &&
                cityResponse?.data?.Status === 1
            ) {
                setState(
                    stateResponse?.data?.states?.map((item: StateType) => ({
                        label: item.state_name,
                        value: item.state_id,
                    }))
                );
                setAllCity(cityResponse?.data?.cities);
                setCity(
                    cityResponse?.data?.cities.map((item: cityType) => ({
                        label: item.city,
                        value: item.city_id,
                    }))
                );
            } else {
                showWarningAlert(cityResponse?.data?.Message);
            }
            if (Edit) {
                reset({
                    zipcode: Edit?.zipcode,
                    city_id: Edit?.city_id,
                    state_id: Edit?.state_id,
                });
            }
        } catch (err: any) {
            showErrorAlert(
                err?.response?.data?.Message ||
                    err?.response?.data?.message ||
                    "Something went wrong, try again later"
            );
        } finally {
            setLoading(false); // Stop loading once both calls are done
        }
    };
    useEffect(() => {
        fetchCountryStateAndCity();
        // eslint-disable-next-line
    }, [Edit, isModalOpen]);
    const CancelHandler = () => {
        setisModalOpen(false);
        setIsIedit(null);
        reset();
    };

    const onSubmit: SubmitHandler<zipcodeType> = async (data) => {
        try {
            setLoading(true);
            let response;
            if (Edit) {
                response = await zipcode_update(data, Edit.zipcode_id);
            } else {
                response = await zipcode_Add(data);
            }
            if (response?.data?.Status === 1) {
                Toast(response?.data?.Message, "success");
                CancelHandler();
                getState();
            } else {
                showWarningAlert(response?.data?.Message);
            }
        } catch (err: any) {
            showErrorAlert(
                err?.response?.data?.Message ||
                    err?.response?.data?.message ||
                    "Something went wrong try again later"
            );
        } finally {
            setLoading(false);
        }
    };

    return (
        <Modal
            isModalOpen
            setisModalOpen={setisModalOpen}
            onClose={CancelHandler}>
            <div className={Styles["Modal_Container"]}>
                <header>
                    <h3>{Edit ? "Update" : "Add"} Zipcode</h3>
                </header>
                <form onSubmit={handleSubmit(onSubmit)}>
                    <FormControl
                        label={"Zipcode"}
                        name={"zipcode"}
                        register={register}
                        errors={errors["zipcode"]}
                        requiredMessage={"Zipcode is required"}
                        type={"text"}
                    />

                    <FormControl
                        label={"State"}
                        name={"state_id"}
                        register={register}
                        options={State}
                        errors={errors["state_id"]}
                        requiredMessage={"State is required"}
                        type={"select"}
                    />
                    <FormControl
                        label={"City"}
                        name={"city_id"}
                        register={register}
                        options={city}
                        errors={errors["city_id"]}
                        requiredMessage={"City is required"}
                        type={"select"}
                    />
                    <div className={Styles["Action_btns"]}>
                        <button type="submit" disabled={loading}>
                            {loading ? <Spin /> : "Save"}
                        </button>
                        <button
                            type="button"
                            onClick={CancelHandler}
                            disabled={loading}>
                            Cancel
                        </button>
                    </div>
                </form>
            </div>
        </Modal>
    );
};

export default ADDModal;
