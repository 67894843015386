import { useState } from "react";
import LoginStyles from "./Login.module.css";
import { useForm, SubmitHandler } from "react-hook-form";
import { IoEyeOffOutline, IoEyeOutline } from "react-icons/io5";
import { useNavigate } from "react-router";
import Logo from "Assets/logo.png";
import ApiService from "Services/config";
import { showWarningAlert } from "Util/Alerts";
import { Login_service } from "Services/Requests";
import { useStore } from "Store/Main";
import {Config} from 'Components/Layout/Config'
import { Spin } from "antd";
import {SubMenuItem} from 'Pages/Auth/Roles/RolesType'
type Inputs = {
    email: string;
    password: string;
};

function Login() {
    const { setUserName ,setPermissions} = useStore((item: any) => item);
    const navigate = useNavigate();
    const [Loading, setLoading] = useState(false);

    const [showPassword, setShowPassword] = useState(false);
    const {
        register,
        handleSubmit,
        formState: { errors },
    } = useForm<Inputs>();

   
    const findFirstLinkWithPermission = (permissions: SubMenuItem[]): string | null => {
        for (let configItem of Config) {
            // Check if the config item has submenus
            if (configItem.isMultiple && configItem.subMenu) {
                // Loop through subMenu and check for permissions
                const matchingSubMenu = configItem.subMenu.find(subItem => {
                    const subItemPermission = permissions.find(p => p.Name === subItem.Name);
                    return subItemPermission && subItemPermission.permissions.read === 1;
                });
    
                // If we found a matching submenu, return the Link of the matching submenu
                if (matchingSubMenu) {
                    return matchingSubMenu.Link;  // Return the Link of the matching submenu
                }
            } else {
                // For items without submenus, just check the main permission
                const permission = permissions.find(p => p.Name === configItem.Name);
                if (permission && permission.permissions.read === 1) {
                    return configItem.Link;  // Return the Link of the configItem
                }
            }
        }
    
        return null;  // Return null if no match is found
    };
    const onSubmit: SubmitHandler<Inputs> = async (data) => {
        try {
            setLoading(true);
            const response = await Login_service(data);
            setLoading(false);

            if (response?.data?.Status === 1) {
                // setError("");
                ApiService.defaults.headers.common[
                    "Authorization"
                ] = `Bearer ${response?.data?.Token}`;
                localStorage.setItem("Token", response?.data?.Token);
                localStorage.setItem(
                    "teamPermissions",
                    JSON.stringify(response?.data?.teamPermissions)
                );
                setPermissions(response?.data?.teamPermissions)
                setUserName(response?.data?.userName);
                localStorage.setItem("userName", response?.data?.userName);
                let Link =findFirstLinkWithPermission(response?.data?.teamPermissions)
                if(Link){

                    navigate(Link);
                }
            } else {
                showWarningAlert(response?.data?.Message);
            }
        } catch (err: any) {
            setLoading(false);

            showWarningAlert(
                err?.response?.data?.Message ||
                    err?.response?.data?.message ||
                    "Something went wrong try again later"
            );
        }
    };
    return (
        <div className={LoginStyles["Container"]}>
            <div className={LoginStyles["Container_left"]}>
                <form onSubmit={handleSubmit(onSubmit)}>
                    <div className={LoginStyles["Logo_wrap"]}>
                        <img src={Logo} alt="" />
                        <h3>TEJ ADMIN</h3>
                        <p>Where Management Meets simplicity</p>
                    </div>

                    {/*   <header>
                        <h3>Sign In</h3>
                        <p>Please login with your credentials</p>
                    </header> */}
                    {/* register your input into the hook by invoking the "register" function */}
                    <div className={LoginStyles["form_control"]}>
                        <label htmlFor="Email">Email Address</label>
                        <input
                            style={{
                                borderColor: errors?.email ? "red" : "#e9edf6",
                            }}
                            {...register("email", {
                                required: "Email address is required",
                            })}
                        />
                        {errors?.email && <span>{errors?.email?.message}</span>}
                    </div>
                    <div className={LoginStyles["form_control"]}>
                        <label htmlFor="Email">Password</label>
                        <div
                            className={LoginStyles["input_wrapper"]}
                            style={{
                                borderRadius: "0.35rem",
                                border: errors?.password
                                    ? "1px solid red"
                                    : "1px solid #e9edf6",
                            }}>
                            <input
                                style={{ border: "none" }}
                                type={showPassword ? "text" : "password"}
                                {...register("password", {
                                    required: "Password is required",
                                })}
                            />
                            <button
                                type="button"
                                className={LoginStyles["show"]}
                                onClick={() =>
                                    setShowPassword((prev) => !prev)
                                }>
                                {!showPassword ? (
                                    <IoEyeOffOutline />
                                ) : (
                                    <IoEyeOutline />
                                )}
                            </button>
                        </div>
                        {errors?.password && (
                            <span>{errors?.password?.message}</span>
                        )}
                    </div>
                    {Loading ? <Spin size="default" /> : <button>Login</button>}
                </form>
            </div>
            <div className={LoginStyles["Container_right"]}>
                <div className={LoginStyles["rightbox"]}>
                    <div className={LoginStyles["image_wrapper"]}>
                        <img src={Logo} alt="Logo" />
                    </div>
                    <p>Designed and Developed By</p>
                    <h3>_ </h3>
                    <p>Unlock Tomorrow's Technology Today</p>
                </div>
            </div>
        </div>
    );
}

export default Login;
