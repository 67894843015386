import { useState, useEffect } from "react";
import Styles from "Styles/Styles.module.css";
import DevalayaStyles from "Pages/Auth/MasterData/Devalaya/devalaya.module.css";
import { IoPencil } from "react-icons/io5";
import { IoMdSearch } from "react-icons/io";
import { MdDelete } from "react-icons/md";
import { MdOutlineFileDownload } from "react-icons/md";
import { get_city, city_update } from "Services/Requests";
import {
    showWarningAlert,
    showErrorAlert,
    Toast,
    confirmAction,
} from "Util/Alerts";
import Tableload from "Components/TableLoad";
import { cityType } from "./Types";
import AddType from "./Add";

function TypeComp() {
    let tableHeaders = [
        "SNO",
        "City",
        "State",
        "Longitude",
        "Latitude",
        "Actions",
    ];
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [Edit, setIsIedit] = useState<null | cityType>(null);
    const [city, setCity] = useState([]);
    const [searchCity, setSearchCity] = useState([]);
    const [loading, setLoading] = useState(true);

    const getCity = async () => {
        try {
            const response = await get_city();
            if (response?.data?.Status === 1) {
                setCity(response?.data?.cities);
                setSearchCity(response?.data?.cities);
            } else {
                showWarningAlert(response?.data?.Message);
            }
        } catch (err: any) {
            showErrorAlert(
                err?.response?.data?.Message ||
                    err?.response?.data?.message ||
                    "Something went wrong try again later"
            );
        } finally {
            setLoading(false);
        }
    };

    const Delete = async (id: any) => {
        setLoading(true);
        try {
            const response = await city_update({ status: 0 }, id);
            if (response?.data?.Status === 1) {
                Toast("city Deleted Successfully", "success");
                getCity();
            } else {
                setLoading(false);
                showWarningAlert(response?.data?.Message);
            }
        } catch (err: any) {
            setLoading(false);
            showErrorAlert(
                err?.response?.data?.Message ||
                    err?.response?.data?.message ||
                    "Something went wrong try again later"
            );
        }
    };
    useEffect(() => {
        getCity();
    }, []);

    const searchHandler = (e: React.ChangeEvent<HTMLInputElement>) => {
        setCity(() =>
            searchCity.filter((item: any) =>
                String(item.State_name)
                    .toLowerCase()
                    .includes(String(e.target.value).toLowerCase())
            )
        );
    };
    const deleteHandler = (item: cityType) => {
        confirmAction(
            "Warning",
            `Are you sure you want to Delete Type (${item.city}) ?`,
            () => Delete(item.city_id),
            "Delete"
        );
    };
    return (
        <div style={{ overflow: "auto" }}>
            {isModalOpen && (
                <AddType
                    isModalOpen
                    setisModalOpen={setIsModalOpen}
                    setIsIedit={setIsIedit}
                    Edit={Edit}
                    getCity={getCity}
                />
            )}
            <div className={DevalayaStyles["Master_data_filter"]}>
                <div className={DevalayaStyles["filter_right"]}>
                    <div className={DevalayaStyles["form_control"]}>
                        <input
                            type="text"
                            onChange={searchHandler}
                            placeholder="Search city"
                        />
                        <IoMdSearch className={DevalayaStyles["search"]} />
                    </div>
                </div>
                <div className={DevalayaStyles["filter_btns"]}>
                    <button>
                        <MdOutlineFileDownload size={15} /> Export
                    </button>
                    <button onClick={() => setIsModalOpen(true)}>
                        + Add city
                    </button>
                </div>
            </div>
            {loading ? (
                <Tableload TableHead={tableHeaders} />
            ) : (
                <div className={Styles["tableWrapper"]}>
                    <table>
                        <thead>
                            {tableHeaders.map((item) => (
                                <th>{item}</th>
                            ))}
                        </thead>
                        <tbody>
                            {city.length > 0 ? (
                                city.map((item: cityType, index) => (
                                    <tr>
                                        <td>{index + 1}</td>

                                        <td>{item?.city}</td>
                                        <td>{item?.state_name}</td>
                                        <td>{item?.latitude}</td>
                                        <td>{item?.latitude}</td>

                                        <td>
                                            <div
                                                className={
                                                    Styles["Action_Tabs"]
                                                }>
                                                <button
                                                    onClick={() => {
                                                        setIsIedit(item);
                                                        setIsModalOpen(true);
                                                    }}>
                                                    <IoPencil size={22} />
                                                </button>

                                                <button
                                                    onClick={() =>
                                                        deleteHandler(item)
                                                    }>
                                                    <MdDelete size={20} />
                                                </button>
                                            </div>
                                        </td>
                                    </tr>
                                ))
                            ) : (
                                <tr>
                                    <td colSpan={tableHeaders.length}>
                                        {" "}
                                        No Data Found
                                    </td>
                                </tr>
                            )}
                        </tbody>
                    </table>
                </div>
            )}
        </div>
    );
}

export default TypeComp;
