import { Config } from "Components/Layout/Config"; // Import your existing Config data
import { Permissions, SubMenuItem, ConfigItem, FormField } from "./RolesType"; // Import types

const defaultPermissions: Permissions = { read: 0, write: 0, edit: 0 };

// Transforming the config into the desired structure
export const transformedConfig: ConfigItem[] = Config.map((item) => {
    // Check if the item has multiple subMenu entries
    if (item.isMultiple) {
        // Transform subMenu items
        const submenu: SubMenuItem[] =
            item.subMenu?.map((subItem) => ({
                Name: subItem.Name,
                permissions: defaultPermissions,
            })) || [];

        return { Name: item.Name, isMultiple: true, subMenu: submenu };
    }

    // For single items without subMenu
    return {
        Name: item.Name,
        permissions: defaultPermissions,
        isMultiple: false,
    };
});
export let formInputs: FormField[] = [
    {
        label: "Name",
        name: "name",
        type: "text",
        required: "Name is required",
    },
    {
        label: "Email",
        name: "email",
        type: "email",
        required: "Email is required",
    },
    {
        label: "Password",
        name: "password",
        type: "password",
        required: "Password is required",
    },
    {
        label: "confirm Password",
        name: "confirm_password",
        type: "text",
        required: "Confirm Password is required",
    },
    {
        label: "User Type",
        name: "role",
        type: "select",
        options: [
            { label: "Admin", value: "1" },
            { label: "Sub Admin", value: "2" },
        ],
        required: "User Type is required",
    },
    {
        label: "Designation",
        name: "designation",
        type: "text",

        required: "Designation is required",
    },
];
