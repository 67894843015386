import React, { useState } from "react";
import Styles from "Styles/Styles.module.css";
import { useForm, SubmitHandler } from "react-hook-form";
import { FileUploader } from "react-drag-drop-files";
import Upload from "Components/UploadDesign/upload";
import { IoIosWarning } from "react-icons/io";
import { useNavigate } from "react-router";
import FormControl from "Components/FormControl";
import { NotificationTypes } from "./Types";
import { config } from "../../../s3config";
import ReactS3Client from "react-aws-s3-typescript";

import { Notifications_Add } from "Services/Requests";
import { showErrorAlert, showWarningAlert, Toast } from "Util/Alerts";
import { Spin } from "antd";
import Imagepreview from "Components/ImagePreview";
import ModalLoad from "Components/Modal_load/load";
import { MdDelete } from "react-icons/md";

function AddBanner() {
    const navigate = useNavigate();
    const fileTypes = ["JPG", "PNG", "GIF"];
    const [notification_image, setnotification_image] = useState("");
    const [imageLoad, setImageLoad] = useState(false);
    const [loading, setLoading] = useState(false);
    const [isModalVisible, setIsModalVisible] = useState(false);

    const [ErrorMsg, setErrorMsg] = useState<null | String>(null);
    const {
        register,
        handleSubmit,
        setValue,
        watch,
        resetField,
        formState: { errors },
    } = useForm<NotificationTypes>();
    const watchData = watch();
    const handleChange = (files: any) => {
        if (
            files.type === "image/png" ||
            files.type === "image/jpeg" ||
            files.type === "image/jpg"
        ) {
            let maxSize = 2 * 1024 * 1024;
            if (files.size > maxSize) {
                alert("File size cannot be greater than 2 MB");
                return;
            }

            const timestamp = Date.now();
            const fileName = `${timestamp}`;
            const s3 = new ReactS3Client({
                ...config,
                dirName: "notifications",
            });
            setImageLoad(true);
            s3.uploadFile(files, fileName)
                .then((res: any) => {
                    setValue(
                        "notification_image",
                        `${String(res.key).split("/")[1]}`
                    );
                    setnotification_image(res.location);
                    setImageLoad(false);
                })
                .catch((err: any) => {
                    alert(err);
                    setImageLoad(false);
                });
        } else {
            alert(`${files.name} is neither PNG nor JPEG`);
            return false;
        }
        return true;
    };

    const onSubmit: SubmitHandler<NotificationTypes> = async (data) => {
        if (!notification_image) {
            setErrorMsg("Please Upload File");
            return;
        }
        try {
            setLoading(true);
            let response = await Notifications_Add({
                ...data,
            });

            if (response?.data?.Status === 1) {
                Toast(response?.data?.Message, "success");
                navigate(-1);
            } else {
                showWarningAlert(response?.data?.Message);
            }
        } catch (err: any) {
            showErrorAlert(
                err?.response?.data?.Message ||
                    err?.response?.data?.message ||
                    "Something went wrong try again later"
            );
        } finally {
            setLoading(false);
        }
    };

    return (
        <div className={Styles["Add_form_wrapper"]}>
            {imageLoad && <ModalLoad />}
            <Imagepreview
                handleCancel={() => setIsModalVisible(false)}
                selectedImage={notification_image}
                isModalVisible={isModalVisible}
                title="Notification Image"
            />
            <h3>Add Notification</h3>
            <form onSubmit={handleSubmit(onSubmit)}>
                <div className={Styles["form"]}>
                    <h4>Notifications</h4>
                    <FormControl
                        errors={errors["notification_title"]}
                        label="Title"
                        register={register}
                        name="notification_title"
                        requiredMessage=" Title is required"
                    />
                    <FormControl
                        errors={errors["button_name"]}
                        label="Button Name"
                        register={register}
                        name="button_name"
                        requiredMessage="Button Name is required"
                    />
                    <FormControl
                        errors={errors["button_name"]}
                        label="Notification Type"
                        register={register}
                        name="content_type"
                        requiredMessage="Content Type is required"
                        options={[
                            { label: "Tej", value: 1 },
                            { label: "Devalaya", value: 2 },
                            { label: "Utsav", value: 3 },
                        ]}
                        type="select"
                    />
                    <FormControl
                        errors={errors["button_name"]}
                        label="Segment"
                        register={register}
                        name="content_type"
                        requiredMessage="Content Type is required"
                        options={[
                            { label: "In App", value: 1 },
                            { label: "Push", value: 2 },
                            { label: "Email", value: 3 },
                        ]}
                        type="select"
                    />
                    <FormControl
                        errors={errors["redirection"]}
                        label="Redirection"
                        register={register}
                        name="redirection"
                        requiredMessage="Redirection is required"
                    />{" "}
                    <FormControl
                        errors={errors["notification_message"]}
                        label="Message"
                        register={register}
                        name="notification_message"
                        type="textarea"
                        requiredMessage="Message is required"
                    />
                    <div className={Styles["form_control"]}>
                        <label htmlFor="Photo">Image</label>
                        <FileUploader
                            handleChange={handleChange}
                            name="file"
                            types={fileTypes}
                            children={Upload}
                        />
                    </div>
                    {ErrorMsg && (
                        <p className={Styles["Gen-erro_msg"]}>
                            {" "}
                            <IoIosWarning /> {ErrorMsg}
                        </p>
                    )}
                    {notification_image && (
                        <div className={Styles["remove_upload"]}>
                            <div onClick={() => setIsModalVisible(true)}>
                                <img src={notification_image} alt="banner" />
                                <p>{watchData.notification_image}</p>
                            </div>
                            <button
                                onClick={() => {
                                    resetField("notification_image");
                                    setnotification_image("");
                                }}>
                                <MdDelete />
                            </button>
                        </div>
                    )}
                </div>

                {loading ? <Spin size="default" /> : <button>Add</button>}
            </form>
        </div>
    );
}

export default AddBanner;
