import React, { useState, useEffect } from "react";
import Modal from "Components/Modal/Modal";
import Styles from "../devalaya.module.css";
import { add_Devalaya_type, update_Devalaya_type } from "Services/Requests";
import { useForm, SubmitHandler } from "react-hook-form";
import { showErrorAlert, showWarningAlert, Toast } from "Util/Alerts";
import { Spin } from "antd";
import { Inputs, ModalProps } from "./Types";
import FormControl from "Components/FormControl";

const ADDModal: React.FC<ModalProps> = ({
    setisModalOpen,
    isModalOpen,
    setIsIedit,
    Edit,
    getDevalaya,
}) => {
    const [loading, setLoading] = useState(false);

    const {
        register,
        handleSubmit,
        reset,
        formState: { errors },
    } = useForm<Inputs>();

    useEffect(() => {
        if (Edit) {
            reset({ devalaya_type: Edit.devalaya_type });
        }
        // eslint-disable-next-line
    }, [Edit, isModalOpen]);
    const CancelHandler = () => {
        setisModalOpen(false);
        setIsIedit(null);
        reset();
    };
    const onSubmit: SubmitHandler<Inputs> = async (data) => {
        try {
            setLoading(true);
            let response;
            if (Edit) {
                response = await update_Devalaya_type(
                    data,
                    Edit.devalayatype_id
                );
            } else {
                response = await add_Devalaya_type(data);
            }
            if (response?.data?.Status === 1) {
                Toast(response?.data?.Message, "success");
                CancelHandler();
                getDevalaya();
            } else {
                showWarningAlert(response?.data?.Message);
            }
        } catch (err: any) {
            showErrorAlert(
                err?.response?.data?.Message ||
                    err?.response?.data?.message ||
                    "Something went wrong try again later"
            );
        } finally {
            setLoading(false);
        }
    };
    return (
        <Modal
            isModalOpen
            setisModalOpen={setisModalOpen}
            onClose={CancelHandler}>
            <div className={Styles["Modal_Container"]}>
                <header>
                    <h3>{Edit ? "Update" : "Add"} Devalaya Type</h3>
                </header>
                <form onSubmit={handleSubmit(onSubmit)}>
                    <FormControl
                        label={"Type"}
                        name={"devalaya_type"}
                        register={register}
                        errors={errors["devalaya_type"]}
                        requiredMessage={"Devalaya type is required"}
                        type={"text"}
                    />

                    <div className={Styles["Action_btns"]}>
                        <button type="submit" disabled={loading}>
                            {loading ? <Spin /> : "Save"}
                        </button>
                        <button
                            type="button"
                            onClick={CancelHandler}
                            disabled={loading}>
                            Cancel
                        </button>
                    </div>
                </form>
            </div>
        </Modal>
    );
};

export default ADDModal;
