import React, { useState } from "react";
import Styles from "./devalaya.module.css";
import Types from "./Types/DevalayaTypes";
import Committe from "./Committee/Committee";
import TabsComponent from "Components/Tabs";
import Facilities from "Pages/Auth/MasterData/Facilities/Facilities";
import DosDont from "./DosDont/Index";
import Kainkaryam from "./kainkaryam/Index";
import Rules from "./Rules/Rules";
import Architecture from "./Architecture/Architecture";
function Index() {
    const [ActiveTab, setActiveTab] = useState("Devalaya Types");
    const Tabs = [
        { label: "Devalaya Types", value: "Devalaya Types" },
        { label: "Architecture", value: "Architecture" },
        { label: "Committe", value: "Committe" },
        { label: "Do's-Dont's", value: "Do's-Dont's" },
        { label: "kainkaryam", value: "kainkaryam" },
        { label: "Rules", value: "Rules" },
    ];

    return (
        <div className={Styles["Container"]}>
            <h3>Devalaya</h3>
            <header>
                <TabsComponent
                    Tabs={Tabs}
                    active={ActiveTab}
                    setActive={setActiveTab}
                />
            </header>
            {/* {ActiveTab === "Devalayas" && <Devalayas />} */}
            {ActiveTab === "Devalaya Types" && <Types />}
            {ActiveTab === "Committe" && <Committe />}
            {ActiveTab === "Facilities" && <Facilities />}
            {ActiveTab === "Do's-Dont's" && <DosDont />}
            {ActiveTab === "kainkaryam" && <Kainkaryam />}
            {ActiveTab === "Rules" && <Rules />}
            {ActiveTab === "Architecture" && <Architecture />}
        </div>
    );
}

export default Index;
