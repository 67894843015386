import React from "react";
import UploadClasses from "./upload.module.css";
// import { FiImage } from "react-icons/fi";
import UploadImg from "Assets/upload.png";
const Upload = (
    <div className={UploadClasses["Container"]}>
        <div className={UploadClasses["img"]}>
            <img src={UploadImg} alt="" />
        </div>
        <p>Drag and drop image here, or click add image</p>
        <button>Add Image</button>
    </div>
);

export default Upload;
