import { useEffect, useState } from "react";
import Styles from "Styles/Styles.module.css";
import { FiPlus } from "react-icons/fi";
import { MdDelete } from "react-icons/md";
import { useNavigate } from "react-router";
import { Notifications_get } from "Services/Requests";
import { showWarningAlert, showErrorAlert } from "Util/Alerts";
import { NotificationTypes } from "./Types";
import TableLoad from "Components/TableLoad";
import ImagePreview from "Components/ImagePreview";
import Modal from "Pages/Auth/Static-content/Modal";

function Banners() {
    const [Notifications, setNotifications] = useState<NotificationTypes[]>([]);
    const [Loading, setLoading] = useState(false);
    const [isModalVisible, setIsModalVisible] = useState<boolean>(false);
    const [selectedImage, setSelectedImage] = useState<string | null>(null);
    const [showText, setShowText] = useState<any>({ content: "", title: "" });

    const Thead = ["SNO", "Title", "Message", "Button", "redirection"];
    const Navigate = useNavigate();
    useEffect(() => {
        const GetNotifications = async () => {
            try {
                const response = await Notifications_get();
                if (response?.data?.Status === 1) {
                    let details = response?.data?.Notifications?.map(
                        (item: NotificationTypes) => ({
                            notification_title: item.notification_title,
                            notification_image: item.notification_image,
                            notification_message: item.notification_message,
                            button_name: item?.button_name,
                            redirection: item.redirection,
                            content_type: item?.content_type,
                            segment_type: item?.segment_type,
                        })
                    );
                    setNotifications(details);
                } else {
                    showWarningAlert(response?.data?.Message);
                }
            } catch (err: any) {
                showErrorAlert(
                    err?.response?.data?.Message ||
                        err?.response?.data?.message ||
                        "Something went wrong try again later"
                );
            } finally {
                setLoading(false);
            }
        };
        GetNotifications();
    }, []); // Function to handle image click and show the modal
    const handleImageClick = (imageUrl: string) => {
        setSelectedImage(imageUrl);
        setIsModalVisible(true);
    };

    // Function to close the modal
    const handleCancel = () => {
        setIsModalVisible(false);
        setSelectedImage(null);
    };
    return (
        <div className={Styles["Container"]}>
            <Modal
                isModalVisible={showText.content ? true : false}
                onOk={() => setShowText({ content: "", title: "" })}
                onCancel={() => setShowText({ content: "", title: "" })}
                content={showText}
            />
            <ImagePreview
                handleCancel={handleCancel}
                isModalVisible={isModalVisible}
                selectedImage={selectedImage}
                title="Banner"
            />
            <header>
                <h3>Notifications</h3>
                <button
                    className={Styles["Add_cat_btn"]}
                    onClick={() => Navigate("/auth/Notifications/add")}>
                    <FiPlus />
                    Add Notification
                </button>
            </header>
            {/* {Loading? } */}
            {Loading ? (
                <TableLoad TableHead={Thead} />
            ) : (
                <div className={Styles["tableWrapper"]}>
                    <table>
                        <thead>
                            {Thead.map((item) => (
                                <th key={item}>{item}</th>
                            ))}
                        </thead>
                        <tbody>
                            {Notifications?.length > 0 ? (
                                Notifications.map((item, index) => (
                                    <tr>
                                        <td>{index + 1}</td>
                                        <td>
                                            <div
                                                className={Styles["banner"]}
                                                onClick={() =>
                                                    handleImageClick(
                                                        item.notification_image
                                                    )
                                                }>
                                                <img
                                                    src={
                                                        item.notification_image
                                                    }
                                                    alt="banner"
                                                />
                                                <div>
                                                    <p>
                                                        {
                                                            item?.notification_title
                                                        }
                                                    </p>
                                                </div>
                                            </div>
                                        </td>
                                        <td>
                                            <button
                                                style={{
                                                    textDecoration: "underline",
                                                    border: "none",
                                                    backgroundColor: "inherit",
                                                    cursor: "pointer",
                                                }}
                                                onClick={() => {
                                                    setShowText({
                                                        title: item.notification_title,
                                                        content:
                                                            item?.notification_message,
                                                    });
                                                }}>
                                                View
                                            </button>
                                        </td>
                                        <td>{item?.button_name}</td>
                                        <td>
                                            <a
                                                rel="noreferrer"
                                                href={item?.redirection}
                                                target="_blank">
                                                Link
                                            </a>
                                        </td>
                                        {/* <td>
                                            <div
                                                className={
                                                    Styles["Action_Tabs"]
                                                }>
                                                <button>
                                                    <MdDelete size={22} />
                                                </button>
                                            </div>
                                        </td> */}
                                    </tr>
                                ))
                            ) : (
                                <tr>
                                    {" "}
                                    <td colSpan={Thead.length}>
                                        No Data Found
                                    </td>
                                </tr>
                            )}
                        </tbody>
                    </table>
                </div>
            )}
        </div>
    );
}

export default Banners;
