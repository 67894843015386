import React from "react";
import { Modal } from "antd";

interface BannerModalProps {
    isModalVisible: boolean; // Controls the visibility of the modal
    handleCancel: () => void; // Function to handle modal close
    selectedImage: string | null; // The URL of the selected image to display
    title: string; // The URL of the selected image to display
}

const BannerModal: React.FC<BannerModalProps> = ({
    isModalVisible,
    handleCancel,
    selectedImage,
    title,
}) => {
    return (
        <Modal
            open={isModalVisible}
            onCancel={handleCancel}
            title={title}
            footer={null} // No footer buttons
            centered>
            <img
                src={selectedImage || ""}
                alt="Preview"
                style={{ width: "100%" }}
            />
        </Modal>
    );
};

export default BannerModal;
