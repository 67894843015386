import Login from "Pages/Common/Login/Login";
import Dashboard from "Pages/Auth/Dashboard/Dashboard";
import Pandit from "Pages/Auth/ProfileManagement/Pandit/Pandits";
import UserManagement from "Pages/Auth/UserManagement/UserManagement";
import FAQ from "Pages/Auth/Static-content/FAQ/Faq";
import AddUpdateFAQ from "Pages/Auth/Static-content/FAQ/Add-update/faq";
import PrivacyPolicy from "Pages/Auth/Static-content/Privacy_policy/Policy";
import PrivacyPolicyAdd from "Pages/Auth/Static-content/Privacy_policy/Add";
import Terms_Condition from "Pages/Auth/Static-content/Terms_condition/Terms";
import Terms_Condition_add from "Pages/Auth/Static-content/Terms_condition/Add";
import Banners from "Pages/Auth/Banners/Banners";
import AddBanners from "Pages/Auth/Banners/AddBanner";
import Devalaya from "Pages/Auth/MasterData/Devalaya/Index";
import AddDevalaya from "Pages/Auth/Devalaya/AddDevalaya";
import Notifications from "Pages/Auth/Notifications/Notifications";
import AddNotifications from "Pages/Auth/Notifications/AddNotifications";
import AllDevalaya from "Pages/Auth/Devalaya/Devalayas";
import AddPandit from "Pages/Auth/ProfileManagement/Pandit/AddPandit";
import Utsav from "Pages/Auth/MasterData/Utsav/Index";
import Facilities from "Pages/Auth/MasterData/Facilities/Facilities";
import HowToReach from "Pages/Auth/MasterData/LocalTransport/LocalTransport";
import Deities from "Pages/Auth/MasterData/Deities/Deities";
import Location from "Pages/Auth/MasterData/Location/Index";
import Roles from "Pages/Auth/Roles/Roles";
import RoleAddUpdate from "Pages/Auth/Roles/AddRole";
export const AuthConfig = [
    {
        path: "/dashboard",
        element: Dashboard,
    },
    {
        path: "/banners",
        element: Banners,
    },
    {
        path: "/banners/:type",
        element: AddBanners,
    },
    {
        path: "/profile-management/pandit",
        element: Pandit,
    },
    {
        path: "/profile-management/pandit/:type",
        element: AddPandit,
    },
    {
        path: "/user-management",
        element: UserManagement,
    },

    {
        path: "/devalaya",
        element: AllDevalaya,
    },
    {
        path: "/devalaya/:type",
        element: AddDevalaya,
    },
    {
        path: "/static-content/faq",
        element: FAQ,
    },
    {
        path: "/static-content/faq/:type",
        element: AddUpdateFAQ,
    },
    {
        path: "/static-content/privacy-policy",
        element: PrivacyPolicy,
    },
    {
        path: "/static-content/privacy-policy/:type",
        element: PrivacyPolicyAdd,
    },
    {
        path: "/static-content/terms-conditions",
        element: Terms_Condition,
    },
    {
        path: "/static-content/terms-conditions/:type",
        element: Terms_Condition_add,
    },

    {
        path: "master-data/devalaya",
        element: Devalaya,
    },
    {
        path: "master-data/facilities",
        element: Facilities,
    },

    {
        path: "master-data/Utsav",
        element: Utsav,
    },
    {
        path: "master-data/local-transport",
        element: HowToReach,
    },
    {
        path: "master-data/deities",
        element: Deities,
    },
    {
        path: "master-data/location",
        element: Location,
    },
    {
        path: "Notifications",
        element: Notifications,
    },
    {
        path: "Notifications/add",
        element: AddNotifications,
    },
    {
        path: "Roles-management",
        element: Roles,
    },
    {
        path: "Roles-management/:type",
        element: RoleAddUpdate,
    },
];

export const Config = [
    {
        path: "/",
        element: Login,
    },
];
