import React from "react";
import { Route, Routes } from "react-router";
import { Config, AuthConfig } from "./Config";
import Layout from "Components/Layout/Layout";
import AuthWrapper from "Util/Auth";

export default function Router() {
    return (
        <>
            <Routes>
                {Config.map((route, index) => (
                    <Route
                        key={index}
                        path={route.path}
                        element={<route.element />}></Route>
                ))}
                <Route
                    path="auth/*"
                    element={
                        <Layout>
                            <AuthWrapper>
                                <Routes>
                                    {AuthConfig.map((route) => (
                                        <Route
                                            key={route.path}
                                            path={route.path}
                                            element={<route.element />}
                                        />
                                    ))}
                                </Routes>
                            </AuthWrapper>
                        </Layout>
                    }
                />
            </Routes>
        </>
    );
}
