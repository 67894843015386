import React, { useState } from "react";
import { Breadcrumb } from "antd";
import Styles from "Styles/Styles.module.css";
import { NavLink } from "react-router-dom";
import { useParams } from "react-router";
import { useForm, SubmitHandler } from "react-hook-form";
import { RolesTypes } from "./RolesType";
import {cloneDeep} from "lodash";

import FormControl from "Components/FormControl";
import RoleClasses from "./Roles.module.css";
import { transformedConfig, formInputs } from "./Config";
import { Spin } from "antd";
import { showErrorAlert, showWarningAlert, Toast } from "Util/Alerts";
import { useNavigate } from "react-router";
import { useLocation } from "react-router-dom";
import { useEffect } from "react";
import { add_Roles, update_Roles } from "Services/Requests";
function AddRole() {
    const { type } = useParams(); // Get the dynamic parameter "id"
    const [Loading, setLoading] = useState(false);
    const location = useLocation();
    const navigate = useNavigate();
    const [Permissions, setPermissions] = useState(transformedConfig);
    const [formFields, setFields] = useState(formInputs);
    const {
        register,
        handleSubmit,
        setError,
        setFocus,
        reset,
        unregister,
        formState: { errors },
    } = useForm<RolesTypes>();

    useEffect(() => {
        if (type === "edit") {
            console.log(location.state);
            unregister("confirm_password");
            unregister("password");
            setFields(
                formFields.filter(
                    (item) =>
                        item.name != "confirm_password" &&
                        item.name != "password"
                )
            );
            reset({
                email: location?.state?.email,
                designation: location?.state?.designation,
                name: location?.state?.name,
                role: location?.state?.role,
            });
            let permissionsArray = location?.state?.permissions;

            let menuArray = cloneDeep(transformedConfig)
            if (permissionsArray) {
                menuArray.forEach((menuItem) => {
                    console.log(menuItem);
                    // Compare if the Name exists in permissionsArray
                    const permissionItem = permissionsArray.find(
                        (item: any) => item.Name === menuItem.Name
                    );

                    if (permissionItem) {
                        // Update permissions if found
                        menuItem.permissions = {
                            ...permissionItem.permissions,
                        };

                        // If the menu item has subMenu, update the permissions of subMenus
                    }
                    if (menuItem.isMultiple && menuItem.subMenu) {
                        menuItem.subMenu.forEach((subMenuItem) => {
                            const subMenuPermissionItem = permissionsArray.find(
                                (item: any) => item.Name === subMenuItem.Name
                            );
                            console.log(subMenuPermissionItem);
                            if (subMenuPermissionItem) {
                                subMenuItem.permissions = {
                                    ...subMenuPermissionItem.permissions,
                                };
                            }
                        });
                    }
                });
            }
            setPermissions(menuArray);
        }
    }, [type]);
    const onSubmit: SubmitHandler<RolesTypes> = async (data) => {
        if (data.password != data.confirm_password) {
            setFocus("confirm_password");
            setError("confirm_password", {
                message: "Password mismatch",
                type: "custom",
            });
            return;
        }
        let body = {
            ...data,
            permissions: Permissions.flatMap((item: any) => {
                if (item.subMenu) {
                    // If item has a subMenu, return only the subMenu items
                    return item.subMenu.map((subItem: any) => ({
                        ...subItem,
                    }));
                }
                // If no subMenu, include the item itself
                return { Name: item.Name, permissions: item?.permissions };
            }),
        };
        try {
            setLoading(true);
            let response;
            if (type === "edit") {
                response = await update_Roles(location.state?.admin_id, body);
            } else {
                response = await add_Roles(body);
            }
            if (response?.data?.Status === 1) {
                Toast(response?.data?.Message, "success");
                navigate(-1);
            } else {
                showWarningAlert(response?.data?.Message);
            }
        } catch (err: any) {
            showErrorAlert(
                err?.response?.data?.Message ||
                    err?.response?.data?.message ||
                    "Something went wrong try again later"
            );
        } finally {
            setLoading(false);
        }
    };

    // Array of field configurations

    const inputHandler = (
        isSubmenu: boolean,
        value: number,
        name: string,
        type: "edit" | "write" | "read",
        mainMenuName?: string
    ) => {
        if (isSubmenu) {
            let NewMenu = Permissions.map((item) =>
                item.Name == mainMenuName
                    ? {
                          ...item,
                          subMenu: item.subMenu?.map((subItem) =>
                              subItem.Name == name
                                  ? {
                                        ...subItem,
                                        permissions: {
                                            ...subItem.permissions,
                                            [type]: value,
                                        },
                                    }
                                  : subItem
                          ),
                      }
                    : item
            );
            setPermissions(NewMenu);
        } else {
            setPermissions((prev) =>
                prev.map((item) =>
                    item.Name == name
                        ? {
                              ...item,
                              permissions: {
                                  read: item.permissions?.read ?? 0,
                                  write: item.permissions?.write ?? 0,
                                  edit: item.permissions?.edit ?? 0,
                                  [type]: value,
                              },
                          }
                        : item
                )
            );
        }
    };
    return (
        <div className={Styles["Add_form_wrapper"]}>
            {" "}
            <h3>
                <span style={{ textTransform: "capitalize" }}>{type}</span> Role
                Management
            </h3>
            <Breadcrumb>
                <Breadcrumb.Item>
                    <NavLink to="/auth/Roles-management">
                        Role Management
                    </NavLink>
                </Breadcrumb.Item>
                <Breadcrumb.Item>
                    <span style={{ textTransform: "capitalize" }}>
                        {type} Role
                    </span>
                </Breadcrumb.Item>
            </Breadcrumb>
            <form onSubmit={handleSubmit(onSubmit)}>
                <div className={`${Styles["form"]} `}>
                    <div className={Styles["form_trio"]}>
                        {formFields.map((field) => (
                            <FormControl
                                key={field.name}
                                label={field.label}
                                name={field.name}
                                register={register}
                                errors={errors[field.name]}
                                requiredMessage={field.required}
                                type={field.type}
                                options={field.options || []}
                                validate={field?.validate}
                            />
                        ))}
                    </div>
                    <div className={RoleClasses["roles_wrapper"]}>
                        <table>
                            <thead>
                                <tr>
                                    <th className={RoleClasses["heading"]}>
                                        Permissions
                                    </th>
                                    <th>Read</th>
                                    <th>Edit</th>
                                    <th>Write</th>
                                </tr>
                            </thead>
                            <tbody>
                                {Permissions.map((item) => {
                                    if (item.isMultiple) {
                                        return (
                                            <>
                                                <tr>
                                                    <td
                                                        colSpan={4}
                                                        style={{
                                                            border: "none",
                                                            padding: "0.6rem 0",
                                                            fontWeight: "bold",
                                                        }}>
                                                        {item.Name}
                                                    </td>
                                                </tr>
                                                {item.subMenu?.map(
                                                    (submenu, index) => (
                                                        <tr>
                                                            <td>
                                                                <span
                                                                    style={{
                                                                        fontWeight:
                                                                            "bold",
                                                                        color: "var(--PrimaryColor)",
                                                                    }}>
                                                                    ↪
                                                                </span>{" "}
                                                                {submenu.Name}
                                                            </td>
                                                            <td>
                                                                <input
                                                                    onChange={(
                                                                        e
                                                                    ) => {
                                                                        inputHandler(
                                                                            true,
                                                                            e
                                                                                .target
                                                                                .checked
                                                                                ? 1
                                                                                : 0,
                                                                            submenu.Name,
                                                                            "read",
                                                                            item.Name
                                                                        );
                                                                    }}
                                                                    type="checkbox"
                                                                    checked={
                                                                        submenu
                                                                            .permissions
                                                                            .read ==
                                                                        1
                                                                    }
                                                                />
                                                            </td>
                                                            <td>
                                                                <input
                                                                    onChange={(
                                                                        e
                                                                    ) => {
                                                                        inputHandler(
                                                                            true,
                                                                            e
                                                                                .target
                                                                                .checked
                                                                                ? 1
                                                                                : 0,
                                                                            submenu.Name,
                                                                            "edit",
                                                                            item.Name
                                                                        );
                                                                    }}
                                                                    type="checkbox"
                                                                    checked={
                                                                        submenu
                                                                            .permissions
                                                                            .edit ==
                                                                        1
                                                                    }
                                                                />
                                                            </td>
                                                            <td>
                                                                <input
                                                                    onChange={(
                                                                        e
                                                                    ) => {
                                                                        inputHandler(
                                                                            true,
                                                                            e
                                                                                .target
                                                                                .checked
                                                                                ? 1
                                                                                : 0,
                                                                            submenu.Name,
                                                                            "write",
                                                                            item.Name
                                                                        );
                                                                    }}
                                                                    type="checkbox"
                                                                    checked={
                                                                        submenu
                                                                            .permissions
                                                                            .write ==
                                                                        1
                                                                    }
                                                                />
                                                            </td>
                                                        </tr>
                                                    )
                                                )}
                                            </>
                                        );
                                    } else {
                                        return (
                                            <tr>
                                                <td>{item.Name}</td>
                                                <td>
                                                    <input
                                                        onChange={(e) => {
                                                            inputHandler(
                                                                false,
                                                                e.target.checked
                                                                    ? 1
                                                                    : 0,
                                                                item.Name,
                                                                "read"
                                                            );
                                                        }}
                                                        type="checkbox"
                                                        checked={
                                                            item.permissions
                                                                ?.read == 1
                                                        }
                                                    />
                                                </td>
                                                <td>
                                                    <input
                                                        onChange={(e) => {
                                                            inputHandler(
                                                                false,
                                                                e.target.checked
                                                                    ? 1
                                                                    : 0,
                                                                item.Name,
                                                                "edit"
                                                            );
                                                        }}
                                                        type="checkbox"
                                                        checked={
                                                            item.permissions
                                                                ?.edit == 1
                                                        }
                                                    />
                                                </td>
                                                <td>
                                                    <input
                                                        onChange={(e) => {
                                                            inputHandler(
                                                                false,
                                                                e.target.checked
                                                                    ? 1
                                                                    : 0,
                                                                item.Name,
                                                                "write"
                                                            );
                                                        }}
                                                        type="checkbox"
                                                        checked={
                                                            item.permissions
                                                                ?.write == 1
                                                        }
                                                    />
                                                </td>
                                            </tr>
                                        );
                                    }
                                })}
                            </tbody>
                        </table>
                    </div>
                </div>
                <button disabled={Loading}>
                    {Loading ? <Spin /> : "Submit"}
                </button>
            </form>
        </div>
    );
}

export default AddRole;
