import React, { useState, useEffect } from "react";
import Modal from "Components/Modal/Modal";
import Styles from "Pages/Auth/MasterData/Devalaya/devalaya.module.css";
import { State_Add, State_update, get_Coutries } from "Services/Requests";
import { useForm, SubmitHandler } from "react-hook-form";
import { showErrorAlert, showWarningAlert, Toast } from "Util/Alerts";
import { Spin } from "antd";
import { StateType, ModalProps } from "./Types";
import FormControl from "Components/FormControl";
import { CountryType } from "../Country/Types";

window.Buffer = window.Buffer || require("buffer").Buffer;

const ADDModal: React.FC<ModalProps> = ({
    setisModalOpen,
    isModalOpen,
    setIsIedit,
    Edit,
    getState,
}) => {
    const [loading, setLoading] = useState(false);
    const [Country, setCountry] = useState([]);

    const {
        register,
        handleSubmit,
        reset,
        formState: { errors },
    } = useForm<StateType>();
    const getCountry = async () => {
        try {
            const response = await get_Coutries();
            if (response?.data?.Status === 1) {
                setCountry(
                    response?.data?.countries?.map((item: CountryType) => ({
                        label: item.country_name,
                        value: item.country_id,
                    }))
                );
                if (Edit) {
                    reset({
                        state_name: Edit?.state_name,
                        country_id: Edit?.country_id,
                        state_code: Edit?.state_code,
                    });
                }
            } else {
                showWarningAlert(response?.data?.Message);
            }
        } catch (err: any) {
            showErrorAlert(
                err?.response?.data?.Message ||
                    err?.response?.data?.message ||
                    "Something went wrong try again later"
            );
        } finally {
            setLoading(false);
        }
    };
    useEffect(() => {
        getCountry();
        // eslint-disable-next-line
    }, [Edit, isModalOpen]);
    const CancelHandler = () => {
        setisModalOpen(false);
        setIsIedit(null);
        reset();
    };

    const onSubmit: SubmitHandler<StateType> = async (data) => {
        try {
            setLoading(true);
            let response;
            if (Edit) {
                response = await State_update(data, Edit.state_id);
            } else {
                response = await State_Add(data);
            }
            if (response?.data?.Status === 1) {
                Toast(response?.data?.Message, "success");
                CancelHandler();
                getState();
            } else {
                showWarningAlert(response?.data?.Message);
            }
        } catch (err: any) {
            showErrorAlert(
                err?.response?.data?.Message ||
                    err?.response?.data?.message ||
                    "Something went wrong try again later"
            );
        } finally {
            setLoading(false);
        }
    };

    return (
        <Modal
            isModalOpen
            setisModalOpen={setisModalOpen}
            onClose={CancelHandler}>
            <div className={Styles["Modal_Container"]}>
                <header>
                    <h3>{Edit ? "Update" : "Add"} State</h3>
                </header>
                <form onSubmit={handleSubmit(onSubmit)}>
                    <FormControl
                        label={"State Code"}
                        name={"state_code"}
                        register={register}
                        errors={errors["state_code"]}
                        requiredMessage={"State Code is required"}
                        type={"text"}
                    />
                    <FormControl
                        label={"State Name"}
                        name={"state_name"}
                        register={register}
                        errors={errors["state_name"]}
                        requiredMessage={"State Name is required"}
                        type={"text"}
                    />
                    <FormControl
                        label={"Country"}
                        name={"country_id"}
                        register={register}
                        options={Country}
                        errors={errors["country_id"]}
                        requiredMessage={"Country is required"}
                        type={"select"}
                    />

                    <div className={Styles["Action_btns"]}>
                        <button type="submit" disabled={loading}>
                            {loading ? <Spin /> : "Save"}
                        </button>
                        <button
                            type="button"
                            onClick={CancelHandler}
                            disabled={loading}>
                            Cancel
                        </button>
                    </div>
                </form>
            </div>
        </Modal>
    );
};

export default ADDModal;
