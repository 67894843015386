import React, { useState } from "react";
import Styles from "../Devalaya/devalaya.module.css";
import TabsComponent from "Components/Tabs";
import Country from "./Country/Country";
import State from "./State/State";
import City from "./City/City";
import ZipCode from "./zipcode/Zipcode";
function Index() {
    const [ActiveTab, setActiveTab] = useState("Country");
    const Tabs = [
        { label: "Country", value: "Country" },
        { label: "State", value: "State" },
        { label: "City", value: "City" },
        { label: "Zipcode", value: "Zipcode" },
    ];

    return (
        <div className={Styles["Container"]}>
            <h3>Location</h3>
            <header>
                <TabsComponent
                    Tabs={Tabs}
                    active={ActiveTab}
                    setActive={setActiveTab}
                />
            </header>
            {ActiveTab === "Country" && <Country />}
            {ActiveTab === "State" && <State />}
            {ActiveTab === "City" && <City />}
            {ActiveTab === "Zipcode" && <ZipCode />}
        </div>
    );
}

export default Index;
